import React, { Component } from 'react';
import axios from 'axios';

import { reactLocalStorage } from 'reactjs-localstorage';
import { toast } from 'react-toastify';

import { serverUrl , headerRequest, optCategoriesPeople } from '../../lib/funApp/general';
import { cpfMask, cnpjMask, foneMask, celMask } from '../../lib/funApp/mask';

import { MDBIcon } from 'mdbreact';
import { Button, Dropdown, Form as FormUI, Label, Icon } from 'semantic-ui-react';
import { Form, Card, FormGroup, Row, Col, Modal } from "react-bootstrap";
import { Grid, TextField, FormControl, Radio, RadioGroup, FormControlLabel, IconButton } from '@material-ui/core';
import { SpeedDial, SpeedDialIcon, SpeedDialAction } from '@material-ui/lab';
import { PersonAdd as PersonAddIcon, 
         FormatIndentDecrease as FormatIndentDecreaseIcon, 
         Clear as ClearIcon,
         Search as SearchIcon, Build, Close } from '@material-ui/icons';

import Loading from  '../../containers/loading';     
import PeopleDelete from './peopleDelete';
import PeopleEdit from './peopleEdit';
import PeopleStatus from './peopleStatus';

export default class PeopleDial extends Component {

    constructor(){
        super()        
        this.targetUrl = serverUrl + '/src/peoples';
        this.targetUrlSrc = serverUrl + '/src/peoples?name=';
        this.optError1 = false
        this.optError2 = false
        this.state ={
            show1: JSON.parse(sessionStorage.getItem('@md_People')), 
            show2: false, 
            open: false, hidden: false,
            optError1: false, optError2: false,
            loading: false,
            optCategories: optCategoriesPeople,
            name: '',
            type: 'J',
            cpf: '',
            cnpj: '',
            categories: ['Fornecedor'],
            fone: '',
            city: '',
            obs: '',
            keySrc: '',
            getResult: [],
            cel:'',
            email:''
        }
    }

    handleOpen = () => {this.setState({ open: !this.state.open });};
    handleShowAdd = () => {
        this.setState({ show1: !this.state.show1 }); 
        sessionStorage.setItem('@md_People', !this.state.show1)
    };
    handleShowSrc = () => this.setState({ show2: !this.state.show2 });

    /* Form */
    handleChange = (e, { value }) => this.setState({ categories: value })
    
    handleChangeRadio = event => {
      this.setState({type: event.target.value});
      event.target.value === 'J' ? this.setState({ Cpf:'', categories: ['Fornecedor'] }) : this.setState({ Cnpj:'', categories: ['Aluno'] })
    };

    handleAddition = (e, { value }) => {
      this.setState((prevState) => ({
        optCategories: [{ text: value, value }, ...prevState.optCategories],
      }))
    }
  

    validateSubmit = () => {
      if( this.state.name.length <= 3 ){

        this.optError1 = true; 
        this.setState({ optError1: true })

        if( this.state.name.length > 0 ){
          toast.error('Nome deve ter mais de 3 Letras!!');
        }else{
          toast.error('Preencha o Nome!!');
        }

      }else{this.optError1 = false; this.setState({ optError1: false })}

      if( this.state.categories.length === 0 ){

        this.optError2 = true;
        this.setState({ optError2: true })
        toast.error('Preencha uma Categoria!!');

      }else{this.optError2 = false; this.setState({ optError2: false })}

      if( this.optError1 === false && this.optError2 === false ){
        this.setState({ show1: false, loading: true })
        this.handleSubmit()
      }

    }

    handleSubmit = (e) => {

      let params = {}

      params.name = this.state.name;
      params.type = this.state.type;
      params.category = this.state.categories;

      if( this.state.cnpj ){ params.cnpj = this.state.cnpj };
      if( this.state.cpf  ){ params.cpf  = this.state.cpf  };
      if( this.state.city ){ params.city = this.state.city };
      if( this.state.fone ){ params.fone = this.state.fone };
      if( this.state.cel  ){ params.cel  = this.state.cel  };
      if( this.state.email){ params.email= this.state.email};
      if( this.state.obs  ){ params.obs  = this.state.obs  };

      axios.post(this.targetUrl, params, {"headers" : headerRequest})
        .then(res => {
          if(res.status === 200){
            reactLocalStorage.set('@result', 200); 
            setTimeout(() => {
              return window.location.reload(false);
            }, 2000);
          }
        }).catch(error => {
            if(error.response.data.message.includes('dup key: {')){
              this.setState({ show1: true, loading: false })
              toast.error('Pessoa já Cadastrada!!');
            }else{
              this.setState({ show1: true, loading: false })
              toast.error('Erro Inesperado!!');
              console.log('ERROR: ', error.response.status + ' [' + error.response.data.message +']');
            }
        });
    };

    searchKey(value){

      let urlSrc = this.targetUrlSrc + value;
      this.setState({ keySrc: value });
      this.setState({ getResult: [] }) 
      
      if(value.length > 0){
        axios.get( urlSrc, { "headers" : headerRequest } )
        .then(res => {
            if(res.status === 200){
                this.setState({ getResult: res.data.items })
              }
            }).catch(error => {
                toast.error('Erro Inesperado!!');
                console.log('ERROR: ', error.response.status + ' [' + error.response.data.message +']')
            }
        )
      }    
    }

    render() {
        return(
            <div>
              <div>
                <SpeedDial
                    ariaLabel="SpeedDial tooltip People"
                    hidden={this.state.hidden}
                    icon={<SpeedDialIcon icon={<FormatIndentDecreaseIcon style={{ fontSize: 25 }}/>} openIcon={<ClearIcon  style={{ fontSize: 25 }}/>}/>}
                    onClose={this.handleOpen}
                    onOpen={this.handleOpen}
                    open={this.state.open}
                    direction='left'
                >
                  <SpeedDialAction
                      key='CadastroPessoa'
                      icon={<PersonAddIcon />}
                      tooltipTitle='Adicionar'
                      onClick={this.handleShowAdd}
                  />
                  <SpeedDialAction
                      key='PesquisarPessoa'
                      icon={<SearchIcon />}
                      tooltipTitle='Pesquisar'
                      onClick={this.handleShowSrc}
                  />
                </SpeedDial>
             </div>
            <div>
            <Modal show={this.state.show1} onHide={this.handleShowAdd} centered size="lg">  
              <Modal.Header closeButton>
                <Modal.Title><PersonAddIcon style={{ fontSize: 30, marginRight: '5px' }}/>Cadastro de Pessoa</Modal.Title>
              </Modal.Header>
              <Modal.Body>          
                <Form>
                  <Card>
                    <Card.Body>
                      <Grid container spacing={3}>     
                        <Grid item xs={12} md={8}>
                          <FormGroup controlId="name" bssize="large">
                            <TextField
                              error={this.state.optError1}
                              id="nameText"
                              type="text"
                              value={this.state.name}
                              label="Nome *"
                              onChange={e => this.setState({ name : e.target.value})}
                              className="col-12"
                            />
                          </FormGroup>
                        </Grid>
                        <Grid item xs={12} md={4} style={{ textAlign: "right" }}>
                          <FormControl component="fieldset">
                            <RadioGroup aria-label="position" name="position" value={this.state.type} onChange={this.handleChangeRadio} row>
                              <FormControlLabel
                                value="J"
                                control={<Radio color="primary" />}
                                label="Jurídica"
                                labelPlacement="end"
                              />
                              <FormControlLabel
                                value="F"
                                control={<Radio color="primary" />}
                                label="Física"
                                labelPlacement="end"
                              />
                            </RadioGroup>
                          </FormControl>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          {this.state.type === 'J' ? 
                          <FormGroup controlId="cnpj" bssize="large" style={{marginBottom: "25px"}}>
                              <TextField
                                autoFocus
                                type="text"
                                value={this.state.cnpj}
                                label="CNPJ"
                                onChange={e => this.setState({ cnpj: cnpjMask(e.target.value) })}
                                className="col-12"
                              />
                          </FormGroup>
                          :
                          <FormGroup controlId="cpf" bssize="large" style={{marginBottom: "25px"}}>
                              <TextField
                                autoFocus
                                type="text"
                                value={this.state.cpf}
                                label="CPF"
                                onChange={e => this.setState({ cpf: cpfMask(e.target.value) })}
                                className="col-12"
                              />
                          </FormGroup>
                          }
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormGroup controlId="fone" bssize="large" style={{marginBottom: "25px"}}>
                            <TextField
                              type="text"
                              value={this.state.email}
                              label="Email"
                              onChange={e => this.setState({ email: e.target.value })}
                              className="col-12"
                            />
                          </FormGroup>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormGroup controlId="fone" bssize="large" style={{marginBottom: "25px"}}>
                              <TextField
                                autoFocus
                                type="text"
                                value={this.state.fone}
                                label="Telefone"
                                onChange={e => this.setState({ fone : foneMask(e.target.value) })}
                                className="col-12"
                              />
                          </FormGroup>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormGroup controlId="cel" bssize="large" style={{marginBottom: "25px"}}>
                              <TextField
                                autoFocus
                                type="text"
                                value={this.state.cel}
                                label="Celular"
                                onChange={e => this.setState({ cel : celMask(e.target.value) })}
                                className="col-12"
                              />
                          </FormGroup>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormGroup controlId="city" bssize="large" style={{marginBottom: "25px"}}>
                              <TextField
                                autoFocus
                                type="text"
                                value={this.state.city}
                                label="Cidade"
                                onChange={e => this.setState({ city: e.target.value })}
                                className="col-12"
                              />
                          </FormGroup>
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <FormGroup controlId="obs" bssize="large" style={{marginBottom: "25px"}}>
                              <TextField
                                autoFocus
                                type="text"
                                value={this.state.obs}
                                label="Observação"
                                onChange={e => this.setState({ obs: e.target.value })}
                                className="col-12"
                              />
                          </FormGroup>
                        </Grid>
                        <Grid item xs={12}>
                          <FormUI.Field inline>
                            <Label pointing='below' basic color={this.state.optError2 ? 'red' : 'blue'}>Categorias *</Label>
                            <Dropdown
                              error={this.state.optError2}
                              options={this.state.optCategories}
                              multiple
                              search
                              selection
                              fluid
                              allowAdditions
                              value={this.state.categories}
                              onAddItem={this.handleAddition}
                              onChange={this.handleChange}
                            />
                          </FormUI.Field>
                        </Grid>
                        
                      </Grid>
                    </Card.Body>
                  </Card>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button.Group>
                  <Button negative onClick={this.handleShowAdd}>
                    <Icon name='remove' /> Cancelar
                  </Button>
                  <Button.Or text='ou' />
                  <Button positive basic={this.state.loading} loading={this.state.loading} onClick={this.validateSubmit}>
                    <Icon name='checkmark' /> Salvar
                  </Button>
                </Button.Group>
              </Modal.Footer>
            </Modal>
            <Modal show={this.state.show2} onHide={this.handleShowSrc} centered size="lg">  
              <Modal.Header closeButton>
                <Modal.Title><SearchIcon style={{ fontSize: 30, marginRight: '5px' }}/>Pesquisa de Pessoas</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Row>
                  <Col>
                    <FormGroup controlId="name" bssize="large" style={{marginBottom: "25px"}}>
                        <TextField
                          id="searchText"
                          type="text"
                          value={this.state.keySrc}
                          label="Nome"
                          onChange={e => this.searchKey(e.target.value)}
                          className="col-12" 
                        />
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                    <Col>
                        <Card>
                            <Card.Body style={{ paddingTop: '5px !important' }}>
                            {this.state.getResult.length > 0 ?
                                this.state.getResult.map((people, index)=> (
                                  <PeopleOptionsSrc people={people} key={index}/>
                                ))
                            :<h4 className="text-center">Sem Resultados</h4>
                            }
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
              </Modal.Body>
              <Modal.Footer>
                <Button.Group>
                  <Button negative onClick={this.handleShowSrc}>
                    <Icon name='remove' /> Fechar
                  </Button>
                </Button.Group>
              </Modal.Footer>
            </Modal>
            </div>
            {this.state.loading ? 
              <Loading main={true} />
            : false}
          </div>
        )
    }
}

class PeopleOptionsSrc extends Component{

  constructor(props){
    super()
    this.people = props.people
    this.index = props.index
    this.state = {
      houver: false
    }
  }

  toogleHouver = () => {this.setState({ houver: !this.state.houver })};

  render(){
    return(
      <Row key={this.index} className="mb-1"style={{borderBottom: "inset", paddingBottom: "5px"}}>
        <Col sm={12} md={6}>
          <Row> 
            <Col>
              {this.people.status === true ? 
                <MDBIcon icon="user-check" size="lg" style={{ color:"green" }}/> 
              : <MDBIcon icon="user-alt-slash" size="lg"  style={{ color:"red" }}/>
              }
              <span style={{ marginLeft: "10px" }}>{this.people.name}</span>
            </Col>
          </Row>
        </Col>
        {!this.state.houver ? 
          <Col sm={12} md={6}>
            {this.people.type === 'J' ? 
              <span><strong>CNPJ2: </strong>{this.people.cnpj}</span>
            :
              <span><strong>CPF: </strong>{this.people.cpf}</span>
            }
            <IconButton onClick={this.toogleHouver} title="Abrir Opções" style={{ float: 'right', backgroundColor: '#60a4ffbd', color: '#fff' }} >
              {!this.state.houver ? <Build fontSize='medium'/> : <Close fontSize='medium'/> }
            </IconButton>
          </Col>
        :
          <Col sm={12} md={6} className="text-right align-content-center d-flex"  style={{ flexDirection: 'row-reverse'}}>
            <IconButton onClick={this.toogleHouver} title="Fechar Opções" style={{ float: 'right' }} >
              {!this.state.houver ? <Build fontSize='medium'/> : <Close fontSize='medium'/>}
            </IconButton>
            <span className='m-2'></span> 
            <PeopleDelete people={this.people}/>
            <span className='m-2'></span> 
            <PeopleEdit people={this.people} category={[...optCategoriesPeople, ...this.people.category]}/>
            <span className='m-2'></span> 
            <PeopleStatus people={this.people} />                            
          </Col>
        }

      </Row>
    )
  }
}