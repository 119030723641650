import React, { Component } from 'react';
import axios from 'axios';
import DateFnsUtils from '@date-io/date-fns';
import ptLocale from "date-fns/locale/pt-BR";
import moment from 'moment';

import { reactLocalStorage } from 'reactjs-localstorage';
import { toast } from 'react-toastify';

import { serverUrl, headerRequest } from '../../lib/funApp/general'

import { MDBIcon } from 'mdbreact';
import { Modal, Card } from "react-bootstrap";
import { Button, Icon } from 'semantic-ui-react';
import { Grid, TextField, Fab } from '@material-ui/core';

import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

import { Edit as EditIcon }from '@material-ui/icons';

import Loading from  '../../containers/loading';

export default class WorkoutEdit extends Component{

  constructor(props){
    super()
    this.workout = props.workout
    this.targetUrl = serverUrl + '/src/workouts/' + props.workout._id
    this.workoutUser = sessionStorage.getItem('@workoutUser') ? JSON.parse(sessionStorage.getItem('@workoutUser')) : ''
    this.optError1 = false
    this.optError2 = false
    this.optError3 = false
    this.optError4 = false
    this.state = {
      show: false,
      optError1: false, optError2: false,
      optError3: false, optError4: false,
      loading: false,
      selectedDate: moment(props.workout.dateStart),
      duration: props.workout.duration,
      mesocycle: props.workout.mesocycle,
      microcycle: props.workout.microcycle,
      goal: props.workout.goal,
      method:props.workout.method,
      obs: props.workout.obs,
      intervalRepetition: props.workout.intervalRepetition,
      intervalExercises: props.workout.intervalExercises,
    }
  }

  handleShow = () => this.setState({ show: !this.state.show });

  validateSubmit = () => {
    if( this.state.goal.length < 3 ){

      this.optError1 = true;
      this.setState({ optError1: true })

      if( this.state.goal.length > 0 ){
        toast.error('Objetivo deve ter mais de 3 Letras!!');
      }else{
        toast.error('Preencha o Objetivo!!');
      }

    }else{this.optError1 = false; this.setState({ optError1: false })}

    if(!moment(this.state.selectedDate, 'MM/DD/YYYY',true).isValid()){

      this.optError2 = true;
      this.setState({ optError2: true })
      toast.error('Data Inválida!!');

    }else{this.optError2 = false; this.setState({ optError2: false })}

    if( this.state.method.length < 3 ){

      this.optError3 = true;
      this.setState({ optError3: true })

      if( this.state.method.length > 0 ){
        toast.error('Método deve ter mais de 3 Letras!!');
      }else{
        toast.error('Preencha o Método!!');
      }

    }else{this.optError3 = false; this.setState({ optError3: false })}

    if( this.state.microcycle.length < 3 ){

      this.optError4 = true;
      this.setState({ optError4: true })

      if( this.state.microcycle.length > 0 ){
        toast.error('Microcilo deve ter mais de 3 Letras!!');
      }else{
        toast.error('Preencha o Microcilo!!');
      }

    }else{this.optError4 = false; this.setState({ optError4: false })}

    if( this.optError1 === false && this.optError2 === false && this.optError3 === false ){
      this.setState({ show: false, loading: true })
      this.handleSubmit()
    }

  }

  handleSubmit() {

    let params = {}

    params.dateStart = moment(this.state.selectedDate).format('YYYY-MM-DD') + 'T12:59-0500'
    params.method = this.state.method
    params.goal = this.state.goal
    params.user = this.workoutUser

    params.microcycle = this.state.microcycle
    params.duration = this.state.duration
    params.mesocycle = this.state.mesocycle
    params.intervalRepetition = this.state.intervalRepetition
    params.intervalExercises = this.state.intervalExercises
    params.obs = this.state.obs

    axios.patch(this.targetUrl, params, {"headers" : headerRequest})
      .then(res => {
        if(res.status === 200){
          reactLocalStorage.set('@result', 201);
          setTimeout(() => {
            return window.location.reload(false);
          }, 2000);
        }
      }).catch(error => {
        this.setState({ show: true, loading: false })
        toast.error('Erro Inesperado!!');
        console.log('ERROR: ', error.response.status + ' [' + error.response.data.message +']');
      });
  }

  render(){
    return(
      <>
      <Fab size="small" onClick={this.handleShow} title="Editar Microciclo" style={{ backgroundColor: ' #fb3', color: '#fff' }} >
        <EditIcon />
      </Fab>
        <Modal show={this.state.show} onHide={this.handleShow} centered size="lg">
          <Modal.Header closeButton>
            <Modal.Title><MDBIcon icon="edit" className="mr-2" />Editar Microciclo</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Card>
              <Card.Body>
                <Grid container spacing={3} direction="row" justify="space-between" alignItems="center">
                  <Grid item xs={8} md={4}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptLocale} id='dateForm'>
                      <KeyboardDatePicker
                        error={this.state.optError2}
                        allowKeyboardControl
                        disablePast={true}
                        invalidDateMessage='Data Inválida'
                        autoOk
                        variant="inline"
                        inputVariant="outlined"
                        InputAdornmentProps={{ position: "start" }}
                        id="date-picker-dialog"
                        label="Data de Inicio"
                        format="dd/MM/yyyy"
                        value={this.state.selectedDate}
                        onChange={date => this.setState({ selectedDate: date })}
                        KeyboardButtonProps={{
                            'aria-label': 'Alterar Data',
                        }}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item xs={3}>
                    <TextField
                        autoFocus
                        type="text"
                        value={this.state.duration}
                        label="Duração"
                        onChange={e => this.setState({ duration: e.target.value })}
                        className="col-12"
                      />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      error={this.state.optError4}
                      autoFocus
                      type="text"
                      value={this.state.microcycle}
                      label="Microciclo"
                      onChange={e => this.setState({ microcycle: e.target.value })}
                      className="col-12"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      autoFocus
                      type="text"
                      value={this.state.mesocycle}
                      label="Mesociclo"
                      onChange={e => this.setState({ mesocycle: e.target.value })}
                      className="col-12"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      error={this.state.optError1}
                      autoFocus
                      type="text"
                      value={this.state.goal}
                      label="Objetivo"
                      onChange={e => this.setState({ goal: e.target.value })}
                      className="col-12"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      error={this.state.optError3}
                      autoFocus
                      type="text"
                      value={this.state.method}
                      label="Metódo"
                      onChange={e => this.setState({ method: e.target.value })}
                      className="col-12"
                    />
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <TextField
                      autoFocus
                      type="text"
                      value={this.state.obs}
                      label="Observação"
                      onChange={e => this.setState({ obs: e.target.value })}
                      className="col-12"
                    />
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <TextField
                        autoFocus
                        type="text"
                        value={this.state.intervalRepetition}
                        label="Intervalo Séries"
                        onChange={e => this.setState({ intervalRepetition: e.target.value })}
                        className="col-12"
                      />
                  </Grid>
                  <Grid item xs={6} md={3}>
                    <TextField
                        autoFocus
                        type="text"
                        value={this.state.intervalExercises}
                        label="Intervalo Exercício"
                        onChange={e => this.setState({ intervalExercises: e.target.value })}
                        className="col-12"
                      />
                  </Grid>
                </Grid>
              </Card.Body>
            </Card>
          </Modal.Body>
          <Modal.Footer>
            <Button.Group>
              <Button negative onClick={this.handleShow}>
                <Icon name='remove' /> Cancelar
              </Button>
              <Button.Or text='ou' />
              <Button positive onClick={this.validateSubmit}>
                <Icon name='checkmark' /> Salvar
              </Button>
            </Button.Group>
          </Modal.Footer>
        </Modal>
        {this.state.loading ?
          <Loading main={true} />
        : false}
      </>
    )
  }
}