import React, { Component, Fragment } from 'react';
import _ from 'lodash';

import { avatar } from '../../lib/img/img';

import { RequestInfoGet, serverUrl } from '../../lib/funApp/general'

import { MDBIcon, MDBBtn } from 'mdbreact';
import { Search, Rating, Image, Card as CardUI, Button } from 'semantic-ui-react';
import { Container, Row, Col, Card } from "react-bootstrap";
import { Modal } from "react-bootstrap";

import { NotificationWorkout } from '../../containers/Notification';

import WorkoutsGet from '../../app/workouts'
import WorkoutAdd from './workoutAdd';

const initialState = { 
  isLoading: false, 
  results: [], 
  value: '', 
  userID: sessionStorage.getItem('@workoutUser') ? JSON.parse(sessionStorage.getItem('@workoutUser')) : '',
  image: ''
}

export default class UsersRenderData extends Component {

  constructor(props){
    super()
    this.usersData = props.users[0]
    this.usersLinks = props.users[1]
    this.userList = this.dataOrganize(props.users[0])
    this.state = initialState
  }

  dataOrganize = (data) => {
    let newData = []
    data.map((user) => (
      newData.push({
        "id": user._id,
        "title": user.name,
        "description": user.email,
        "image": user.gender === 'F' ? avatar.small.female[user.avatar] : avatar.small.male[user.avatar ? user.avatar : 0],
        "price": user.cpf ? user.cpf : user.cnpj ? user.cnpj : '000.000.000-00' 
      })

    ))
    return newData
  }

  handleResultSelect = (e, { result }) => {
    this.setState({ value: result.title, userID: result.id, image: result.image })
    sessionStorage.setItem('@workoutUser', JSON.stringify(result.id))
  }

  handleSearchChange = (e, { value }) => {
    this.setState({ isLoading: true, value })

    setTimeout(() => {
      if (this.state.value.length < 1) return this.setState(initialState)

      const re = new RegExp(_.escapeRegExp(this.state.value), 'i')
      const isMatch = (result) => re.test(result.title)

      this.setState({
        isLoading: false,
        results: _.filter(this.userList, isMatch),
      })
    }, 300)
  }

  render() {
    const { isLoading, value, results } = this.state

    return (
      <>
      {console.log('id', this.state.userID)}
      <Container>
        <Row className="TitleContainer">
          <Col style={{ padding: "10px"}}>
            <h4><MDBIcon icon="dumbbell" className="mr-2 ml-4" />Construtor</h4>
          </Col>
        </Row>
      </Container>
      <Container className="MainContainer">
        <NotificationWorkout />
        <Row>
          <Col>
          <Card className='ExpansionPainelData'>
            <Card.Body>
              <Search
                loading={isLoading}
                onResultSelect={this.handleResultSelect}
                onSearchChange={_.debounce(this.handleSearchChange, 500, {
                  leading: true,
                })}
                results={results}
                value={value}
                {...this.props}
                placeholder='Localizar Aluno...'
                id='nameText'
              />
            </Card.Body>
          </Card>
          </Col>
        </Row>
          {this.state.userID ?
          <Fragment key={this.state.userID}>
            <Row>
                <Col>
                  <RatingUser user={this.state.userID}/>
                </Col>
                <Col>
                  <WorkoutAdd />
                </Col>
            </Row>
            <Row>
              <Col>
                <WorkoutsGet user={this.state.userID } />
              </Col>
            </Row>
          </Fragment>
          :false}
      </Container>
      </>
    )
  }
}

class RatingUser extends Component{

  constructor(props){
    super()
    this.targetUrl = serverUrl + '/src/users/' + props.user
    this.user= {}
    this.state ={
       show: false
    }

  }

  componentDidMount() {
    fetch(this.targetUrl ,RequestInfoGet)
    .then(res => res.json())
    .then((data) => {
      this.user = data 
      console.log(data)
    })
    .catch()
  }

  handleShow = () => this.setState({ show: !this.state.show });

  render(){
    
    return(
      <>
      <MDBBtn gradient="blue" onClick={this.handleShow} style={{padding: '10px', height: '100%'}}>
        <div style={{ display: 'flex', lineHeight: 3 }}>
          <MDBIcon icon="user-check" className="mr-2" size='2x' />
          <Rating 
            maxRating={5} 
            defaultRating={3} 
            icon='star' 
            size='large' 
            disabled 
            style={{ verticalAlign: 'middle'}}
            />
        </div>
      </MDBBtn>
      <Modal size="sm" show={this.state.show} onHide={this.handleShow} centered style={{backgroundColor: 'transparent !important' }}>
        
        <CardUI style={{ width: '100%' }}>
          <Image  src={ this.user.gender === 'F' ? avatar.large.female[this.user.avatar] : avatar.large.male[this.user.avatar ? this.user.avatar : 0]} wrapped ui={false} />
          <CardUI.Content>
              <CardUI.Header className='m-2'>{this.user.name}</CardUI.Header>
              <CardUI.Meta className='m-2'>
                <span className='date'>{this.user.email}</span>
              </CardUI.Meta>
              <CardUI.Description className='m-4 text-center'>
                <Rating 
                  maxRating={5} 
                  defaultRating={3} 
                  icon='star' 
                  size='large' 
                  disabled 
                  style={{ verticalAlign: 'middle'}}
                />  
              </CardUI.Description>
            </CardUI.Content>
            <CardUI.Content extra className='text-center'>
              <Button fluid basic color='green'>Anamnese</Button> 
            </CardUI.Content>
        </CardUI>
      </Modal>
      </>
    )
  }

}
