import React, {Component} from 'react'
import axios from 'axios';
import moment from 'moment'

import { UserId } from '../../lib/funApp/general'
import { serverUrl , headerRequest} from '../../lib/funApp/general'

import { Redirect } from 'react-router-dom'
import { reactLocalStorage } from 'reactjs-localstorage';
import { toast, ToastContainer } from 'react-toastify';

import { MDBIcon, MDBBtn } from 'mdbreact';
import { Container, Row, Col, Card, Modal } from 'react-bootstrap';
import { Table, Label, Button, Divider, Message, Icon } from 'semantic-ui-react'

import Loading from '../../containers/loading';

const targetUrl = serverUrl + '/src/bodybuildingplugs/'

export default class BodybuildingplugActive extends Component {

  constructor(){
    super()
    this.muscleBody = []
    this.jsonTraining = {};
    this.state ={
      bodyBuildingPlug : JSON.parse(reactLocalStorage.get('@bodyActive')),
      dateStart : reactLocalStorage.get('@dateActive').split(" "),
      training: JSON.parse(reactLocalStorage.get('@trainingActive')),
      redirect: reactLocalStorage.get('@bodyActive') ? false : true,
      value: 0,
      repExpand: false,
      showCancel: false,
      showSave: false,
      loading: false
    }
  }

  handleShowCancel(){
    this.setState( prevState => {
      return{ showCancel: !prevState.showCancel }
      })
  };

  handleShowSave(){
    this.setState( prevState => {
      return{ showSave: !prevState.showSave }
      })
  };

  concatMuscleBody(muscle, k2){
    this.muscleBody.push( muscle)
  }

  filterMuscle(arr){
    let arrNew = arr.filter((este, i) => arr.indexOf(este) === i).sort();
    return arrNew
  }

  setaTraining(newArr){

    this.setState({ training: newArr });
    this.jsonTraining = JSON.stringify(newArr);
    reactLocalStorage.set('@trainingActive', this.jsonTraining)
  }

  check(on,index){
    let newArr = [...this.state.training];
    newArr[index][`status`] = !on
    this.setaTraining(newArr)
  }

  increase = index => e => {
    let newArr = [...this.state.training];
    let value = parseFloat(newArr[index][`weight`]) + 0.25
    if(value < 0){
      value = 0
    }
    newArr[index][`weight`] = value
    this.setaTraining(newArr)
  }

  decrease = index => e => {
    let newArr = [...this.state.training];
    let value = parseFloat(newArr[index][`weight`]) - 0.25
    if(value < 0){
      value = 0
    }
    newArr[index][`weight`] = value
    this.setaTraining(newArr)
  }

  updateFieldChanged = index => e => {
    let newArr = [...this.state.training];
    if(e.target.value >= 0){
      newArr[index][`weight`] = parseFloat(e.target.value);
      this.setaTraining(newArr)
    }
  }

  cancelBody = () => e => {
    reactLocalStorage.remove('@bodyActive')
    reactLocalStorage.remove('@dateActive')
    reactLocalStorage.remove('@trainingActive')
    this.setState({ redirect: true })
  }

  saveBodyBuildingPlug = () => e => {

    this.setState({ showSave: false, loading: true })

    const targetUrl1 = targetUrl +  this.state.bodyBuildingPlug._id

    let newList = []
    this.state.training.map((exercise, k1) =>(
      newList.push({
        order: exercise.order,
        exercise: exercise.exercise._id,
        repeat: exercise.repeat,
        series: exercise.series,
        weight: exercise.weight ? exercise.weight : 0,
        status: exercise.status ? exercise.status : false
      })))

    let oldList = newList
    oldList.forEach(function(v){ delete v.status });

    let newBody = this.state.bodyBuildingPlug;
    newBody.dateStart = this.state.dateStart[0] + 'T' + this.state.dateStart[1] + '.000Z'
    newBody.parent = this.state.bodyBuildingPlug._id
    newBody.performed = this.state.bodyBuildingPlug.performed + 1
    newBody.matrix = false
    newBody.status = false
    newBody.user = UserId
    newBody.training = newList
    delete(newBody._id)

    let params = {performed: this.state.bodyBuildingPlug.performed, training: oldList}

    axios.post(targetUrl, newBody, {"headers" : headerRequest})
    .then(res => {
      if(res.status === 200){
        axios.patch(targetUrl1, params, {"headers" : headerRequest})
        .then(res1 => {
          if(res1.status === 200){
            reactLocalStorage.remove('@bodyActive')
            reactLocalStorage.remove('@dateActive')
            reactLocalStorage.remove('@trainingActive')
            this.setState({ redirect: true })
          }else{
            this.setState({ loading: false })
            toast.error('Erro Diferente 200');
          }
        }).catch(error => {
          this.setState({ loading: false })
          toast.error('Erro Pach!! ' +  error.response.status + ' [ ' + error.response.data.message +' ]');
          }
        );
      }
    }).catch(error => {
      this.setState({ loading: false })
      toast.error('Erro Post!! ' +  error.response.status + ' [ ' + error.response.data.message +' ]');
      }
    );
  }

  render() {
    if (this.state.redirect) {
      return <Redirect exact to='/training' />
    }else{
      return (
        <>
          <ToastContainer style={{ fontWeight: "bold" }}/>
          <Container>
            <Row className="TitleContainer">
              <Col style={{ padding: "10px"}}>
                <h4><MDBIcon icon="dumbbell" className="mr-2 ml-4" />Execução</h4>
              </Col>
            </Row>
          </Container>
          <Container className="MainContainer">
              <div style={{ display: "flex", flexDirection: "column-reverse" }}>
                <Row style={{flexDirection: 'row-reverse'}}>
                  <Col xs={12} md={6} lg={4}>
                    <Button.Group attached='top'>
                      <Button negative size='large' onClick={e=> this.handleShowCancel()} >
                        <MDBIcon icon="tired" size="lg" className="mr-2"/>
                        <strong>Cancelar</strong>
                      </Button>
                      <Button.Or text='ou' size='large'/>
                      <Button positive size='large' onClick={e=> this.handleShowSave()} >
                        <MDBIcon icon="grin-stars" size="lg" className="mr-2"/>
                        <strong>Finalizar</strong>
                      </Button>
                    </Button.Group>
                  </Col>
                </Row>
                <Table compact celled definition>
                  <Table.Header className='text-center'>
                      <Table.Row>
                          <Table.HeaderCell style={{fontSize: 'revert'}} >
                            <strong>{moment(new Date(this.state.dateStart)).format('HH:mm')}</strong>
                            <MDBIcon far icon="clock" className='m-2' />
                            <strong>{moment(new Date(this.state.dateStart)).format('DD/MM/YY')}</strong>
                          </Table.HeaderCell>
                          <Table.HeaderCell style={{fontSize: 'revert'}} >
                            <Row>
                              <Col>Repetições</Col>
                              <Col>Carga</Col>
                            </Row>
                          </Table.HeaderCell>
                      </Table.Row>
                  </Table.Header>
                  <Table.Body className='ml-2'>
                    {this.state.training.map((training, index) => (
                      <Table.Row key={index}>
                        <Table.Cell onClick={() => this.check(training.status,index)}>
                          <Label color={training.status ? 'green' : 'red'} ribbon>
                            {training.status ? <Icon name='check' /> : <Icon name='hourglass one' loading />}
                          </Label>
                          <span style={{ textDecoration: training.status ? 'line-through' : 'none'  }}>{training.exercise.name}</span>
                        </Table.Cell>
                        <Table.Cell className='text-center' style={{fontSize: 'medium'}}>
                          <Row>
                            <Col style={{alignSelf: "flex-end"}}>
                              {training.series} x {training.repeat}
                            </Col>
                            <Col>
                            {training.status ?
                              <span>{training.weight} kg </span>
                              :
                              <div className="def-number-input number-input" style={{display:"inline-table"}}>
                                <MDBBtn onClick={this.decrease(index)} className="minus p-2" color="white">
                                    <MDBIcon icon="minus"/>
                                </MDBBtn>
                                <input
                                  id={`weight${index}`}
                                  className="quantity"
                                  name={`weight${index}`}
                                  value={training.weight}
                                  onChange={this.updateFieldChanged(index)}
                                  type="number"
                                  />
                                <MDBBtn onClick={this.increase(index)} className="plus p-2" color="white">
                                    <MDBIcon icon="plus"/>
                                </MDBBtn>
                              </div>
                            }
                            </Col>
                          </Row>
                        </Table.Cell>
                        {this.concatMuscleBody(training.exercise.muscleGroup)}
                      </Table.Row>
                    ))}
                  </Table.Body>
                </Table>
                <Card>
                <Card.Body>
                  <Row style={{ color: '#1a69a4' }}>
                    <Col>
                      <h4>
                        <MDBIcon icon="dumbbell" className="mr-2" />
                        <MDBIcon icon="caret-right" /> {this.state.bodyBuildingPlug.letter}
                      </h4>
                    </Col>
                    <Col className="text-right">
                      <h5>
                        { this.state.bodyBuildingPlug.performed }
                        <MDBIcon icon="chart-line" className="ml-2 mr-2" />
                        ({ parseFloat(this.state.bodyBuildingPlug.performed) + 1 })
                      </h5>
                    </Col>
                  </Row>
                  <Divider horizontal><MDBIcon icon="heartbeat"  className="red-text"  size="lg" /></Divider>
                  <Row>
                    <Col className="text-center">
                        {this.muscleBody ?
                          <strong>
                            {this.filterMuscle(this.muscleBody).map((muscle, index)=>(
                              ' ' +  muscle)) + "" }
                          </strong>
                        :false }
                    </Col>
                  </Row>
                </Card.Body>
              </Card>
            </div>
          </Container>
          <Modal show={this.state.showCancel} onHide={e=>this.handleShowCancel()} centered>
            <Modal.Header closeButton>
              <Modal.Title className='red-text'><MDBIcon icon="running" className="mr-2" />Treino</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col><div id="return" className="w-100 font-weight-bold text-wrap"></div></Col>
              </Row>
              <Row className="justify-content-center">
                <Col>
                  <Message icon>
                    <Icon name='cog' loading className='red-text' />
                    <Message.Content>
                      <Message.Header>Cancelar o Treino?</Message.Header>
                      O progresso da Ficha será perdido!
                    </Message.Content>
                  </Message>
              </Col>
            </Row>
            </Modal.Body>
            <Modal.Footer>
              <Row className="justify-content-md-center">
                <Col>
                  <Button.Group>
                    <Button negative size='large' onClick={e=> this.handleShowCancel()} >
                      <MDBIcon icon="grin-stars" size="lg" className="mr-2"/>
                      <strong>Não</strong>
                    </Button>
                    <Button.Or text='ou' size='large'/>
                    <Button positive size='large' onClick={this.cancelBody()}>
                      <MDBIcon icon="tired" size="lg" className="mr-2"/>
                      <strong>Sim</strong>
                    </Button>
                  </Button.Group>
                </Col>
              </Row>
            </Modal.Footer>
          </Modal>

          <Modal show={this.state.showSave} onHide={e=>this.handleShowSave()} centered>
            <Modal.Header closeButton>
              <Modal.Title className='green-text'><MDBIcon icon="running" className="mr-2" />Treino</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col><div id="return" className="w-100 font-weight-bold text-wrap"></div></Col>
              </Row>
              <Row className="justify-content-center">
                <Col>
                  <Message icon>
                    <Icon name='cog' loading className='green-text' />
                    <Message.Content>
                      <Message.Header>Finalizar o Treino?</Message.Header>
                      O progresso da Ficha será Salvo!
                    </Message.Content>
                  </Message>
              </Col>
            </Row>
            </Modal.Body>
            <Modal.Footer>
              <Row className="justify-content-md-center">
                <Col>
                  <Button.Group>
                    <Button negative size='large' onClick={e=> this.handleShowSave()} >
                      <MDBIcon icon="tired" size="lg" className="mr-2"/>
                      <strong>Não</strong>
                    </Button>
                    <Button.Or text='ou' size='large'/>
                    <Button positive size='large' onClick={this.saveBodyBuildingPlug()}>
                      <MDBIcon icon="grin-stars" size="lg" className="mr-2"/>
                      <strong>Sim</strong>
                    </Button>
                  </Button.Group>
                </Col>
              </Row>
            </Modal.Footer>
          </Modal>
          { this.state.loading ? 
            <Loading />
          : false }
        </>
      )
    }
  }

}