import React, { Component } from 'react';

import Moment from 'react-moment';

import { MDBIcon } from 'mdbreact';
import { Container, Row, Col } from "react-bootstrap";
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import { Table, Label } from 'semantic-ui-react'

import { Icon } from 'semantic-ui-react'

import WorkoutDelete from './workoutDelete';
import WorkoutEdit from './workoutEdit';
import BodybuildingplugAdd from '../bodybuildingplugs/bodybuildingplugsAdd';
import BodyBuildingPlugDelete from '../bodybuildingplugs/bodybuildingplugsDelete';

import ListExercises from '../exercises/exercisesList';

export class BodybuildingplugDetail extends Component {

  constructor(bodybuildingplug){
      super()
      this.bodybuildingplug = bodybuildingplug.bodybuildingplug
      this.state = {bodyActive: bodybuildingplug.bodybuildingplug}
      this.muscleBody = []
  }

  concatMuscleBody(muscle, k2){
    this.muscleBody.push( muscle)
  }

  filterMuscle(arr){
    let arrNew = arr.filter((este, i) => arr.indexOf(este) === i).sort();
    return arrNew
  }

  render(){
    return (
      <div style={{ display: "flex", flexDirection: "column-reverse" }}>
        <Table compact celled definition>
          <Table.Header className='text-center'>
              <Table.Row>
                  <Table.HeaderCell />
                  <Table.HeaderCell style={{fontSize: 'revert'}} >Repetições  <Icon fitted name='angle double right' className='p-2' /> Carga</Table.HeaderCell>
              </Table.Row>
          </Table.Header>
          <Table.Body>
            {this.bodybuildingplug.training.map((training, index) => (
              <Table.Row key={index}>
                <Table.Cell>
                    <Label color='blue' ribbon>{training.order}</Label>
                    <span>{training.exercise.name}</span>
                </Table.Cell>
                <Table.Cell className='text-center' style={{fontSize: 'medium'}}>
                  {training.series} x {training.repeat}
                  <Icon fitted name='angle double right' className='p-2' />
                  {training.weight} Kg
                </Table.Cell>
                {this.concatMuscleBody(training.exercise.muscleGroup)}
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
        <div className="text-center p-2 pb-2" style={{borderBottom: "1px solid rgba(0,0,0,.125)", backgroundColor: "#d8d8d838" }}>
          {this.muscleBody ?
            <strong>
              <MDBIcon icon="heartbeat"  className="red-text"  size="lg" />
              {this.filterMuscle(this.muscleBody).map((muscle, index)=>(
                ' ' +  muscle)) + "" }
            </strong>
          :false }
        </div>
      </div>
    )
  }
}

export default class WorkoutsRenderGet extends Component{

  constructor(props){
    super()
    this.workoutData = props.workouts[0]
    this.workoutLinks = props.workouts[1]
    this.state = {
      expanded1: sessionStorage.getItem('@accordWorkouts1') ? sessionStorage.getItem('@accordWorkouts1') : false,
      expanded2: sessionStorage.getItem('@accordWorkouts2') ? sessionStorage.getItem('@accordWorkouts2') : false,
      performed: 0,
    }
    this.performed = 0
  }

  componentDidMount(){

    if(this.workoutData.length > 0){
      this.setState({ expanded1: `panel0` })
    }
  }

  handleChange1(panel){
    if(this.state.expanded1 !== panel){
      this.setState({expanded1:  panel })
      sessionStorage.setItem('@accordWorkouts1', panel)
    }else{
      this.setState({expanded1 :  false })
      sessionStorage.removeItem('@accordWorkouts1')
    }
  };

  handleChange2(panel){
    if(this.state.expanded2 !== panel){
      this.setState({expanded2:  panel })
      sessionStorage.setItem('@accordWorkouts2', panel)
    }else{
      this.setState({expanded2 :  false })
      sessionStorage.removeItem('@accordWorkouts2')
    }
  };

  sumPerformed(performedBody, duration, k1){
    this.performed = this.performed + performedBody
    if(document.getElementById([k1])){
      document.getElementById([k1]).innerHTML = this.performed + '/' + duration
    }
  }

  resetPerformed(){
    this.performed = 0
  }

  compare(a, b) {

    const letterA = a.letter.toUpperCase();
    const letterB = b.letter.toUpperCase();

    let comparison = 0;
    if (letterA > letterB) {
      comparison = 1;
    } else if (letterA < letterB) {
      comparison = -1;
    }
    return comparison;
  }

  render(){
    return(
      <Container className="MainContainer p-0" style={{ fontWeight: '400' }} >
        <Row>
          <Col>
            {this.workoutData.map((workout, i1)=>(
              <div className="w-100" key={i1}>
                <Accordion expanded={this.state.expanded1 === `panel${i1}`} onChange={e=> this.handleChange1(`panel${i1}`)}
                  className='ExpansionPainelData'>
                  <AccordionSummary
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                  >
                    <Container className='p-0'>
                      <Row>
                        <Col>
                          <MDBIcon icon={this.state.expanded1 === `panel${i1}` ? "minus" : "plus"} size='sm' className='mr-2' />
                          <span><strong>Microciclo: </strong>{workout.microcycle}</span>
                          <span id={i1} className="float-right"></span>
                        </Col>
                      </Row>
                    </Container>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="w-100">
                      <Row>
                        <Col sm={12} md={4} className="p-2">
                          <strong><span className="Rotulo">Mesociclo: </span></strong>
                          <span className="Rotulo">{workout.mesocycle}</span>
                        </Col>
                        <Col sm={12} md={4} className="p-2 text-sm-left text-md-center">
                          <strong><span className="Rotulo">Data Inicio: </span></strong>
                          <span className="Rotulo">
                            <Moment format="DD/MM/YYYY" style={{marginLeft: "5px"}}>
                              {workout.dateStart}
                            </Moment>
                          </span>
                        </Col>
                        <Col sm={12} md={4} className="p-2 text-sm-left text-md-right">
                          <strong><span className="Rotulo">Objetivo: </span></strong>
                          <span className="Rotulo">{workout.goal}</span>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12} md={6} className="p-2">
                          <strong><span className="Rotulo">Intervalo entre Séries: </span></strong>
                          <span className="Rotulo">{workout.intervalRepetition}</span>
                        </Col>
                        <Col sm={12} md={6} className="p-2 text-sm-left text-md-right">
                          <strong><span className="Rotulo">Intervalo entre Exercícios: </span></strong>
                          <span className="Rotulo">{workout.intervalExercises}</span>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12} md={6}>
                          <Row>
                            <Col sm={12} className="p-2">
                              <strong><span className="Rotulo">Método: </span></strong>
                              <span className="exRotulo">{workout.method}</span>
                            </Col>
                            {workout.obs ?
                              <Col sm={12} className="p-2">
                                <strong><span className="Rotulo">Obs: </span></strong>
                                <span className="Rotulo">{workout.obs}</span>
                              </Col>
                            :false}
                          </Row>
                        </Col>
                        <Col sm={12} md={6} className="p-2 text-right">
                          <BodybuildingplugAdd workout={workout}/>
                          <span style={{margin: "10px"}}></span>
                          <WorkoutEdit workout={workout}/>
                          <span style={{margin: "5px"}}></span>
                          <WorkoutDelete workout={workout}/>
                        </Col>
                      </Row>
                      <Row>
                        <Col className="p-0">
                          {workout.bodyBuildingPlugs.sort(this.compare).map((bodybuildingplug, k2) => (
                            <div key={k2}>
                            <Accordion expanded={this.state.expanded2 === `panel${k2}`} onChange={e=> this.handleChange2(`panel${k2}`)}>
                              <AccordionSummary
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                                style={{backgroundColor: "#d3d3d37a" }}
                              >
                                <div  className="w-100">
                                  <h6 className="float-left">
                                      Ficha: {bodybuildingplug.letter}
                                  </h6>
                                  <h6 className="float-right">
                                    <MDBIcon icon="chart-line" className="mr-2 mt-2" />
                                    {bodybuildingplug.performed}
                                  </h6>
                                  {this.sumPerformed(bodybuildingplug.performed, workout.duration, i1)}
                                </div>
                              </AccordionSummary>
                              <AccordionDetails style={{padding: '0px'}}>
                                <div  className="w-100">
                                  <Row>
                                    <Col style={{display: 'flex'}}>
                                      <ListExercises bodyBuildingPlug={bodybuildingplug} />
                                    </Col>
                                    <Col className="text-right">
                                      <BodyBuildingPlugDelete bodyBuildingPlug={bodybuildingplug} />
                                    </Col>
                                  </Row>
                                  <BodybuildingplugDetail bodybuildingplug={bodybuildingplug} />
                                </div>
                              </AccordionDetails>
                            </Accordion>
                          </div>
                          ))}
                          {this.resetPerformed()}
                        </Col>
                      </Row>
                    </div>
                  </AccordionDetails>
                </Accordion>
              </div>
            ))}
          </Col>
        </Row>
      </Container>
    )
  }
}