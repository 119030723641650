import React, { Component } from "react";


import Chart from "react-apexcharts";


import { Card } from "react-bootstrap";

export default class IncomesExpensesLine extends Component {
  constructor(props) {
    super();
    this.lastMounths = props.lastMounths
    this.state = {
        series: props.series,
        options: {
            chart: {
                height: 350,
                type: 'area',
                toolbar: {
                    show: true,
                    offsetX: 0,
                    offsetY: 0,
                    tools: {
                        download: true,
                        selection: false,
                        zoom: false,
                        zoomin: false,
                        zoomout: false,
                        pan: false,
                        reset: false | '<img src="/static/icons/reset.png" width="20">',
                        customIcons: []
                    },
                },
            },
            dataLabels: {
                enabled: false
            },
            legend:{
                position: "top"
            },
            stroke: {
                curve: 'smooth'
            },
            xaxis: {
                type: 'text',
                categories: this.lastMounths.map((item,i)=>(
                    item.labelReduce
                ))
            },
            colors: ['#007CFF','#FF2D00'],
        },
      };
    }

    render() {
        return (
            <Card>
                <Card.Body>
                    <div className="mixed-chart">
                        <Chart
                            options={this.state.options}
                            series={this.state.series}
                            type="area"
                        />
                    </div>
                </Card.Body>
            </Card>
        );
    }
}