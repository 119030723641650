import React, { Component } from "react";
import Chart from "react-apexcharts";

import { Card } from "react-bootstrap";

export default class IncomesExpensesLine extends Component {
  constructor(props) {
    super();
    this.lastMounths = props.lastMounths
    this.state = {
        series: props.seriesProfit2,
          options: {
            chart: {
              id: 'fb',
              group: 'social',
              type: 'line',
              height: 160,
              toolbar: {
                show: true,
                offsetX: 0,
                offsetY: 0,
                tools: {
                    download: true,
                    selection: false,
                    zoom: false,
                    zoomin: false,
                    zoomout: false,
                    pan: false,
                    reset: false | '<img src="/static/icons/reset.png" width="20">',
                    customIcons: []
                },
            },
            },
            colors: ['#008FFB'],
            xaxis: {
                type: 'text',
                categories: this.lastMounths.map((item,i)=>(
                    item.labelReduce
                ))
            },
          },

          seriesLine2: props.seriesProfit,
          optionsLine2: {
            chart: {
              id: 'yt',
              group: 'social',
              type: 'area',
              height: 160,
              toolbar: {
                show: true,
                offsetX: 0,
                offsetY: 0,
                tools: {
                    download: true,
                    selection: false,
                    zoom: false,
                    zoomin: false,
                    zoomout: false,
                    pan: false,
                    reset: false | '<img src="/static/icons/reset.png" width="20">',
                    customIcons: []
                },
            },
            },
            colors: ['#00E396'],
            xaxis: {
                type: 'text',
                categories: this.lastMounths.map((item,i)=>(
                    item.labelReduce
                ))
            },
            dataLabels: {
                enabled: false
              },
          },

        };
      }

    render() {
        return (
            <Card>
                <Card.Body>
                  <div id="wrapper">
                    <div id="chart-line2">
                      <Chart options={this.state.optionsLine2} series={this.state.seriesLine2} type="area" height={150} />
                    </div>
                    <div id="chart-line">
                      <Chart options={this.state.options} series={this.state.series} type="line" height={150} />
                    </div>
                  </div>
                </Card.Body>
            </Card>
        );
    }
}