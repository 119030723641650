import React, {Component} from 'react';
import axios from 'axios';
import moment from 'moment';
import CurrencyFormat from 'react-currency-format';
import DateFnsUtils from '@date-io/date-fns';

import { Container, Row, Col, Card, Modal } from 'react-bootstrap';
import { MDBIcon } from 'mdbreact';
import { FormControl, InputLabel, Select, MenuItem, Grid, Accordion, AccordionSummary, AccordionDetails, Paper } from '@material-ui/core';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';
import { Button, Icon, Label, Table } from 'semantic-ui-react'

import { serverUrl, headerRequest}   from '../../lib/funApp/general';
import RecordAdd from './recordAdd';
import RecordDelete from './recordDelete'

export default class Statement extends Component {

    constructor(){
        super()
        this.openPainel = sessionStorage.getItem('@accordRecord')
        this.realBalance = 0
        this.categories = []
        this.changeAccount = this.changeAccount.bind(this)
        this.state = {
            dataAccount: [],
            dataRecords: [],
            account: sessionStorage.getItem('@accountStatement') ? sessionStorage.getItem('@accountStatement') : '',
            dateIni: sessionStorage.getItem('@dateIniStatement') ? sessionStorage.getItem('@dateIniStatement') : moment().startOf('month').format('YYYY-MM-DD hh:mm'),
            dateEnd: sessionStorage.getItem('@dateEndStatement') ? sessionStorage.getItem('@dateEndStatement') : moment().endOf('month').format('YYYY-MM-DD hh:mm'),
            isLoading: false,
            isDesable: sessionStorage.getItem('@accountStatement') ? false : true,
            preBalance: 0,
            expanded: sessionStorage.getItem('@accordRecord') ? sessionStorage.getItem('@accordRecord') : false,
            categoryName: '',
            showCat: false,
        }
    }

    componentDidMount() {

        // Accounts
        const targetUrlAccounts = serverUrl + '/src/accounts'
        axios.get(targetUrlAccounts, {"headers" : headerRequest})
            .then(res => {
                if(res.status === 200){
                    if(res.data.items.length > 0){

                        this.setState({dataAccount: res.data.items});

                        if(this.state.account){
                            this.getRecords(res.data.items)
                        }
                    }
                }
            }).catch(error => {
                console.log(error.response)
        });

        // Categories
        const targetUrlCategory = serverUrl + '/src/categories'
        axios.get(targetUrlCategory, {"headers" : headerRequest})
            .then(res => {
                if(res.status === 200){
                    this.categories = res.data.items
                }
            }).catch(error => {
                console.log(error.response)
        });
    }

    changeAccount(value){
        this.setState({ account: value, isDesable: false})
        sessionStorage.setItem('@accountStatement',value)
        sessionStorage.removeItem('@accordRecord')
    }
    changeDateIni(value){
        this.setState({ dateIni: value})
        sessionStorage.setItem('@dateIniStatement',value)
    }
    changeDataEnd(value){
        this.setState({ dateEnd: value})
        sessionStorage.setItem('@dateEndStatement',value)
    }

    handleChange(painel){
        if(this.state.expanded !== painel){
          this.setState({expanded :  painel })
          sessionStorage.setItem('@accordRecord', painel)
        }else{
          this.setState({expanded :  false })
          sessionStorage.removeItem('@accordRecord')
        }
      };

    handleShowCat = (modal,e) => {
        if(e !== undefined){
            e.stopPropagation();
        }
        if(this.state.showCat !== modal){
            this.setState({showCat :  modal })
        }else{
        this.setState({showCat :  false })
        }
    }

    getRecords(dataAccount = this.state.dataAccount){

        this.setState({isDesable: true, isLoading: true})

        const filtered = dataAccount.filter(this.isIdAccount);
        this.getBalance(filtered[0].openBalance);

        const targetUrl = serverUrl
                            + '/src/records?dtstart=' + moment(this.state.dateIni).format('YYYY-MM-DD')
                            + '&dtend='+ moment(this.state.dateEnd).format('YYYY-MM-DD')

        axios.get(targetUrl, {"headers" : headerRequest})
        .then(res => {
            if(res.status === 200){
                let dados = res.data.items
                let result = []
                dados.map((item, index)=>(
                    item.account._id === this.state.account ?
                    (item.date = moment(item.date).format('DD/MM/YYYY'))
                    + (delete(item.corporation))
                    + (result.push(item))
                        : false
                ))
                let reduce = this.groupBy(result,'date')
                this.setState({dataRecords: reduce, isDesable: false, isLoading: false})
            }
        })
    }

    groupBy(array,key){
        return array.reduce((result, currentValue) => {
            (result[currentValue[key]] = result[currentValue[key]] || []).push(
                currentValue
            );
            return result;
        }, {});
    };

    getBalance = (value) => {
        const targetUrlBalance = serverUrl + '/src/records-prevbal?dtstart=' + moment(this.state.dateIni).format('YYYY-MM-DD') + '&account='+ this.state.account
        axios.get(targetUrlBalance, {"headers" : headerRequest})
        .then(res => {
            if(res.status === 200){
                this.setState({ preBalance:  res.data.items.length > 0 ? (value + res.data.items[0].totalAmount) : (value + 0) })
                this.realBalance = res.data.items.length > 0 ? (value + res.data.items[0].totalAmount) : (value + 0)
            }
        }).catch(error => {
            console.log(error.response)
        });
    }
    getCategory = (id) => {
        const found = this.categories.find(element => element._id === id);
        if(found !== undefined){
            return found.name
        }
    }
    isIdAccount = (value) => {
       return value._id === this.state.account;
    }

    sumBalance = (value) => {
        this.realBalance = this.realBalance + value
        return this.realBalance;
    }
    resetBalante = () => {
        this.realBalance = this.state.preBalance
    }
    render() {
        return (
          <>
            <Container>
                <Row className="TitleContainer">
                <Col style={{ padding: "10px"}}>
                    <h4><MDBIcon icon="clipboard-list" className="mr-2 ml-4" />Extrato</h4>
                </Col>
                <Col>
                    <RecordAdd />
                </Col>
              </Row>
            </Container>
            <Container className="MainContainer" style={{ backgroundColor: 'white', padding: '5px' }} >
                <Row>
                    <Col>
                        <Card>
                            <Card.Body>
                                <Grid
                                container
                                spacing={3}
                                direction="row"
                                justify="center"
                                alignItems="center"
                                >
                                    <Grid item xs={12} sm={12} md={12} lg={6}>
                                        <FormControl className={"col-12"}>
                                            <InputLabel id="select-destiny">Conta Destino</InputLabel>
                                            <Select
                                                labelId="select-destiny"
                                                id="destiny-select"
                                                value={this.state.account}
                                                onChange={e => this.changeAccount(e.target.value)}
                                                className={"col-12"}
                                            >
                                            {this.state.dataAccount !== undefined ?
                                                this.state.dataAccount.map((item, index)=>(
                                                <MenuItem key={index} value={item._id}>
                                                    <div className="w-100">
                                                    <span>{item.name}</span>
                                                    <CurrencyFormat
                                                        value={item.balance}
                                                        displayType={'text'}
                                                        thousandSeparator="."
                                                        decimalSeparator=","
                                                        fixedDecimalScale={true}
                                                        decimalScale={2}
                                                        className="float-right"
                                                    />
                                                    </div>
                                                </MenuItem>
                                             )): false}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12} sm={9} md={6} lg={4} className="text-center">
                                    <FormControl controlid="dateStart">
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardDatePicker
                                            margin="normal"
                                            id="date-picker-Ini"
                                            label="Inicio"
                                            value={this.state.dateIni}
                                            onChange={e => this.changeDateIni(e)}
                                            format="dd/MM/yyyy"
                                            KeyboardButtonProps={{
                                                'aria-label': 'Alterar Data',
                                            }}
                                            style={{marginTop:"0px", float:"right",width: "150px"}}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </FormControl>
                                    <FormControl controlid="dateEnd" className="ml-3">
                                        <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                            <KeyboardDatePicker
                                            margin="normal"
                                            id="date-picker-End"
                                            label="Fim"
                                            value={this.state.dateEnd}
                                            onChange={e => this.changeDateEnd(e)}
                                            format="dd/MM/yyyy"
                                            KeyboardButtonProps={{
                                                'aria-label': 'Alterar Data',
                                            }}
                                            style={{marginTop:"0px", float:"right",width: "150px"}}
                                            />
                                        </MuiPickersUtilsProvider>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={12} sm={3} md={6} lg={2} className='text-right'>
                                    <Button
                                        icon labelPosition='left'
                                        color= {!this.state.isLoading ? 'blue' : 'grey'}
                                        disabled={this.state.isDesable}
                                        onClick={e => this.getRecords()}
                                    >
                                        {!this.state.isLoading ? 'Atualizar' : 'Aguarde'}
                                        <Icon name='sync' loading={this.state.isLoading} />
                                    </Button>
                                </Grid>

                                </Grid>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
                <Row className='mt-3'>
                    <Col>
                        {Object.keys(this.state.dataRecords).length > 0 ?
                            <Card>
                                {this.resetBalante()}
                                <Card.Body>
                                    <div style={{display: 'flex', flexDirection: 'column-reverse'}}>
                                    {Object.keys(this.state.dataRecords).map((item, index)=>(
                                        <div key={index}>
                                            <Label color={this.sumBalance(this.state.dataRecords[item].reduce((prev,next) => prev + next.value,0)) > 0 ? 'blue' : 'red'} basic pointing='below'>
                                                {item}
                                            </Label>
                                            <Label color={this.realBalance > 0 ? 'blue' : 'red'} basic pointing='below' className='float-right'>
                                                <CurrencyFormat
                                                    value={this.realBalance}
                                                    displayType={'text'}
                                                    thousandSeparator="."
                                                    decimalSeparator=","
                                                    fixedDecimalScale={true}
                                                    decimalScale={2}
                                                />
                                            </Label>
                                            <div className='mb-3' style={{ display: 'flex', flexDirection: 'column-reverse' }}>
                                                {this.state.dataRecords[item].map((record, k1)=>(
                                                    <Accordion key={k1} expanded={this.state.expanded === `panel${item+k1}`} onChange={e=> this.handleChange(`panel${item+k1}`,e)}
                                                        style={{backgroundColor: 'skyblue'}}
                                                    >
                                                        <AccordionSummary
                                                            aria-controls="panel1bh-content"
                                                            className={this.state.expanded === `panel${item+k1}` ? 'ml-1 mr-0 mt-1 mb-0 p-0' : 'm-0 p-0'}
                                                            style={{backgroundColor: 'white'}}
                                                        >
                                                            <Grid
                                                                container
                                                                spacing={2}
                                                                direction="row"
                                                                >
                                                                    {console.log('itemRec2',record)}
                                                                <Grid item xs={12} md={4} >
                                                                        <Icon name={this.state.expanded === `panel${item+k1}` ? "minus" : "plus"} className="mr-2 grey-text "/>

                                                                    {record.type === 'T' ? (record.value > 0 ? 'Transf. (Entrada)' : 'Transf. (Saída)' ) :
                                                                    record.person !== undefined ? record.person !== '' ? record.person.name : '' : ''}
                                                                </Grid>
                                                                <Grid item xs={6} md={4} className='text-center'>
                                                                    {record.type !== 'T' ?
                                                                        record.category.length > 1 ?
                                                                        <Button basic color={record.type === 'D' ? 'red' : 'blue'} size='small' onClick={e=> this.handleShowCat(`modal${item+k1}`,e)} style={{paddingTop: '5px', paddingBottom: '5px'}}>Diversas</Button>
                                                                        : record.category[0].category !== undefined ? this.getCategory(record.category[0].category) : ''
                                                                    : record.counterpart !== undefined ? record.counterpart.account.name : ''}
                                                                    {record.counterpart !== undefined ? record.counterpart.value > 0 ? ' (Entrada)' : ' (Saída)' : false }
                                                                    <Modal
                                                                        show={this.state.showCat === `modal${item+k1}` ? true : false}
                                                                        onHide={e=> this.handleShowCat(`modal${item+k1}`,e)}
                                                                        backdrop='static'
                                                                        centered={true}
                                                                        restoreFocus={true}>
                                                                      <Modal.Header>
                                                                        <Modal.Title>
                                                                            <div className='float-right'><span style={{fontSize: 'smaller'}}>Total:</span>
                                                                            <CurrencyFormat
                                                                                value={record.value < 0 ? (record.value*-1) : record.value}
                                                                                displayType={'text'}
                                                                                thousandSeparator="."
                                                                                decimalSeparator=","
                                                                                fixedDecimalScale={true}
                                                                                decimalScale={2}
                                                                                prefix=" R$ "
                                                                                />
                                                                            </div>
                                                                        </Modal.Title>
                                                                      </Modal.Header>
                                                                      <Modal.Body>
                                                                        <Table compact celled definition>
                                                                            <Table.Header className='text-center'>
                                                                                <Table.Row>
                                                                                    <Table.HeaderCell />
                                                                                    <Table.HeaderCell>Historico</Table.HeaderCell>
                                                                                    <Table.HeaderCell>Valor</Table.HeaderCell>
                                                                                </Table.Row>
                                                                            </Table.Header>
                                                                            <Table.Body>
                                                                                {record.category.length > 0 ?
                                                                                    record.category.map((item, index)=>(
                                                                                        <Table.Row key={index}>
                                                                                            <Table.Cell>
                                                                                                <Label color={record.type === 'D' ? 'red' : 'blue'} ribbon>{index+1}</Label>
                                                                                                <span>{this.getCategory(item.category)}</span>
                                                                                            </Table.Cell>
                                                                                            <Table.Cell className='text-center' style={{minWidth: "100px"}}>
                                                                                                {item.history}
                                                                                            </Table.Cell>
                                                                                            <Table.Cell className='text-center' style={{minWidth: "100px"}}>
                                                                                                <CurrencyFormat
                                                                                                    value={item.value}
                                                                                                    displayType={'text'}
                                                                                                    thousandSeparator="."
                                                                                                    decimalSeparator=","
                                                                                                    fixedDecimalScale={true}
                                                                                                    decimalScale={2}
                                                                                                    className="float-right"
                                                                                                    prefix="R$ "
                                                                                                    />
                                                                                            </Table.Cell>
                                                                                        </Table.Row>
                                                                                    ))
                                                                                : false}
                                                                            </Table.Body>
                                                                        </Table>
                                                                      </Modal.Body>
                                                                      <Modal.Footer>
                                                                        <Button negative onClick={e=> this.handleShowCat(`modal${item+k1}`,e)}><Icon name='remove'/> Fechar</Button>
                                                                      </Modal.Footer>
                                                                    </Modal>

                                                                </Grid>
                                                                <Grid item xs={6} md={4} className='text-right float-right'>
                                                                    <CurrencyFormat
                                                                        value={record.value > 0 ? record.value : record.value * -1}
                                                                        displayType={'text'}
                                                                        thousandSeparator="."
                                                                        decimalSeparator=","
                                                                        fixedDecimalScale={true}
                                                                        decimalScale={2}
                                                                    />
                                                                    <MDBIcon
                                                                        size="lg"
                                                                        icon={record.type === "D"? "minus-square" : record.type === "R"? "plus-square" : record.type === "T"? "random" : "plane" }
                                                                        className={"ml-2 text-" + (record.type === "D"? "danger" : record.type === "R"? "primary" : record.type === "T"? ( record.value > 0 ?  "primary" : 'danger') : "default") } />
                                                                </Grid>
                                                            </Grid>
                                                        </AccordionSummary>
                                                        <AccordionDetails style={{paddingLeft: '5px', paddingRight: '0px', paddingTop: '1px', paddingBottom: '5px'}}>
                                                            <Paper elevation={3} className='w-100 p-3'>
                                                                <Grid
                                                                    container
                                                                    spacing={2}
                                                                    direction="row"
                                                                    className='p-0'
                                                                >
                                                                    <Grid item xs={12} md={4} >
                                                                        <strong>Hist:</strong> {record.history}
                                                                    </Grid>
                                                                    <Grid item xs={6} md={4} className="text-center">
                                                                        <strong>Num:</strong> {record.type !== "T" ? record.number : ''}
                                                                    </Grid>
                                                                    <Grid item xs={6} md={4} className="text-right">
                                                                        <RecordDelete item={record} />
                                                                    </Grid>
                                                                </Grid>
                                                            </Paper>
                                                        </AccordionDetails>
                                                    </Accordion>
                                                ))}
                                            </div>
                                        </div>
                                    ))}
                                    </div>
                                    <div className='w-100 text-right'>
                                        <strong className='mr-2'>
                                            {moment(this.state.dateIni).subtract(1, "days").format('DD/MM/YYYY')}
                                        </strong>
                                        <Label as='a' color={this.state.preBalance > 0 ? 'blue' : 'red'} tag size='medium'>
                                            <CurrencyFormat
                                                id={this.state.account}
                                                value={this.state.preBalance}
                                                displayType={'text'}
                                                thousandSeparator="."
                                                decimalSeparator=","
                                                fixedDecimalScale={true}
                                                decimalScale={2}
                                            />
                                        </Label>
                                    </div>
                                </Card.Body>
                            </Card>
                        : false }
                    </Col>
                </Row>
            </Container>
        </>
        )
    }
}