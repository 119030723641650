import React, {Component} from 'react';
import { Container, Row, Col, Card } from 'react-bootstrap';
import { MDBIcon, MDBCarousel, MDBCarouselInner, MDBCarouselItem, MDBView, MDBContainer } from 'mdbreact';

class Home extends Component { 

    constructor(){
        super()
        this.state = {
            index: 0
        }
    }

    handleSelect = (selectedIndex, e) => {
        this.setState({index: selectedIndex});
    };

    render() {
        return (
            <>
            <Container>
                <Row className="TitleContainer">
                <Col style={{ padding: "10px"}}>
                    <h4><MDBIcon icon="home" className="mr-2 ml-4" />Home</h4>
                </Col>
                <Col></Col>
                </Row>
            </Container>
            <Container className="MainContainer" style={{ backgroundColor: 'white', padding: '5px' }} >
                <Row>
                    <Col>
                        <Card style={{ margin: "20px 20px 40px 20px", padding: "10px" }}>
                            <Card.Body style={{ margin: "0px", padding: "0px" }}>
                                <MDBContainer style={{ margin: "0px", padding: "0px" }}>
                                <MDBCarousel
                                    activeItem={Math.floor(Math.random()*(6-1+1)+1)}
                                    length={6}
                                    showControls={true}
                                    showIndicators={true}
                                    className="z-depth-1"
                                >
                                    <MDBCarouselInner>
                                    <MDBCarouselItem itemId="1">
                                        <MDBView>
                                        <img
                                            className="d-block w-100"
                                            src={require('../lib/img/Carrousel/car-1.jpg')}
                                            alt="First slide"
                                            style={{ flex: 1,
                                                width: null,
                                                height: null,
                                                resizeMode: 'contain'}}
                                        />
                                        </MDBView>
                                    </MDBCarouselItem>
                                    <MDBCarouselItem itemId="2">
                                        <MDBView>
                                        <img
                                            className="d-block w-100"
                                            src={require('../lib/img/Carrousel/car-2.jpg')}
                                            alt="Second slide"
                                            style={{ flex: 1,
                                                width: null,
                                                height: null,
                                                resizeMode: 'contain'}}
                                        />
                                        </MDBView>
                                    </MDBCarouselItem>
                                    <MDBCarouselItem itemId="3">
                                        <MDBView>
                                        <img
                                            className="d-block w-100"
                                            src={require('../lib/img/Carrousel/car-3.jpg')}
                                            alt="Third slide"
                                            style={{ flex: 1,
                                                width: null,
                                                height: null,
                                                resizeMode: 'contain'}}
                                        />
                                        </MDBView>
                                    </MDBCarouselItem>
                                    <MDBCarouselItem itemId="4">
                                        <MDBView>
                                        <img
                                            className="d-block w-100"
                                            src={require('../lib/img/Carrousel/car-4.jpg')}
                                            alt="Third slide"
                                            style={{ flex: 1,
                                                width: null,
                                                height: null,
                                                resizeMode: 'contain'}}
                                        />
                                        </MDBView>
                                    </MDBCarouselItem>
                                    <MDBCarouselItem itemId="5">
                                        <MDBView>
                                        <img
                                            className="d-block w-100"
                                            src={require('../lib/img/Carrousel/car-5.jpg')}
                                            alt="Third slide"
                                            style={{ flex: 1,
                                                width: null,
                                                height: null,
                                                resizeMode: 'contain'}}
                                        />
                                        </MDBView>
                                    </MDBCarouselItem>
                                    <MDBCarouselItem itemId="6">
                                        <MDBView>
                                        <img
                                            className="d-block w-100"
                                            src={require('../lib/img/Carrousel/car-6.jpg')}
                                            alt="Third slide"
                                            style={{ flex: 1,
                                                width: null,
                                                height: null,
                                                resizeMode: 'contain'}}
                                        />
                                        </MDBView>
                                    </MDBCarouselItem>
                                    </MDBCarouselInner>
                                </MDBCarousel>
                                </MDBContainer>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
            </>
          );
      }
    
}

export default Home;