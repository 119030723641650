import React, { Component } from 'react'
import axios from 'axios';

import { reactLocalStorage } from 'reactjs-localstorage';
import { toast } from 'react-toastify';

import { serverUrl , headerRequest, optProfileUser } from '../../lib/funApp/general';
import { avatar } from '../../lib/img/img';

import { Fab, Grid, TextField } from '@material-ui/core';
import { Edit as EditIcon } from '@material-ui/icons';
import { Form, Card, FormGroup, Modal } from "react-bootstrap";
import { Button, Dropdown, Form as FormUI, Label, Icon } from 'semantic-ui-react';

import { FormControl, Radio, RadioGroup, FormControlLabel  } from '@material-ui/core';


import Loading from  '../../containers/loading';

export default class UserEdit extends Component{

  constructor(props){
    super()
    this.targetUrl = serverUrl + '/src/users/' + props.user._id
    this.optProfileUser = optProfileUser
    this.avatarMaleList = this.dataOrganize(avatar.small.male)
    this.avatarFemaleList = this.dataOrganize(avatar.small.female)
    this.optError1 = false
    this.optError2 = false
    this.optError3 = false
    this.state ={
        show: false,
        optError1: false, optError2: false,
        optError3: false,
        loading: false,
        name: props.user.name,
        email: props.user.email,
        profiles: props.user.profiles,
        gender: props.user.gender ? props.user.gender : 'M',
        avatar: props.user.avatar ? props.user.avatar :  0,

    }
  }

  dataOrganize = (data) => {
    let newData = []
    data.map((data, index) => (
      newData.push({
          key: index,
          value: index,
          image: { avatar: true, src: data },
      })
    ))
    return newData
  }

  handleShow = () => this.setState((prevState) => ({ show: !prevState.show }));

  handleChange = (e, { value }) => this.setState({ profiles: value })

  handleChangeGender = event => {
    this.setState({gender: event.target.value});
  };

  handleAvatar = (e, { value }) => this.setState({ avatar: value })

  validateSubmit = () => {
    if( this.state.name.length <= 3 ){

      this.optError1 = true; 
      this.setState({ optError1: true })

      if( this.state.name.length > 0 ){
        toast.error('Nome deve ter mais de 3 Letras!!');
      }else{
        toast.error('Preencha o Nome!!');
      }

    }else{this.optError1 = false; this.setState({ optError1: false })}

    if( this.state.email.length === 0 ){
      this.optError2 = true; 
      this.setState({ optError2: true })
      toast.error('Preencha o Email!!');

    }else{
      if(!this.state.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)){
        this.optError2 = true; 
        this.setState({ optError2: true })
        toast.error('Email Inválidoil!!');
      }else{this.optError2 = false; this.setState({ optError2: false })}
    } 

    if( this.state.profiles.length === 0 ){
      this.optError3 = true;
      this.setState({ optError3: true })
      toast.error('Preencha o Perfil!!');
    }else{this.optError2 = false; this.setState({ optError3: false })}

    if( this.optError1 === false && this.optError2 === false && this.optError3 === false ){
      this.setState({ show: false, loading: true })
      this.handleSubmit()
    }

  }

  handleSubmit() {

    let params = {}

    params.name = this.state.name;
    params.email = this.state.email; 
    params.profiles = this.state.profiles;
    params.avatar = this.state.avatar;
    params.gender = this.state.gender;

    axios.patch(this.targetUrl, params, {"headers" : headerRequest})
      .then(res => {
        if(res.status === 200){
          reactLocalStorage.set('@result', 201); 
          setTimeout(() => {
            return window.location.reload(false);
          }, 2000);
        }
      }).catch(error => {
        if(error.response.data.message.includes('dup key: {')){
          this.setState({ show1: true, loading: false })
          toast.error('Usuário já Cadastrado!!');
        }else{
          this.setState({ show1: true, loading: false })
          toast.error('Erro Inesperado!!');
          console.log('ERROR: ', error.response.status + ' [' + error.response.data.message +']');
        }
        }
      );
    }

  render(){
    return(
      <>
      {console.log(this.avatarMaleList)}
      <Fab className='m-1' size="small" onClick={this.handleShow} title="Editar Usuário" style={{ backgroundColor: ' #fb3', color: '#fff' }} >
        <EditIcon />
      </Fab>
      <Modal show={this.state.show} onHide={this.handleShow} centered size="lg"> 
      <Modal.Header closeButton>
          <Modal.Title><EditIcon style={{ fontSize: 30, marginRight: '5px' }}/>Editar Usuário</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
          <Card>
            <Card.Body>
              <Grid container spacing={3}>   
                <Grid item xs={6}>
                  <div>
                    <img id='imgMain' name='imgMain' src={ this.state.gender === 'M' ? this.avatarMaleList[this.state.avatar].image.src : this.avatarFemaleList[this.state.avatar].image.src } className='m-2' alt='avatar'/>
                    <Dropdown
                      simple
                      options={ this.state.gender === 'M' ? this.avatarMaleList : this.avatarFemaleList }
                      onChange={this.handleAvatar}
                    />
                  </div>
                </Grid> 
                <Grid item xs={6} style={{ textAlign: "right", marginTop: '15px' }}>
                  <FormControl component="fieldset">
                    <RadioGroup aria-label="position" name="position" value={this.state.gender} onChange={this.handleChangeGender} row>
                      <FormControlLabel
                        value="M"
                        control={<Radio color="primary" />}
                        label="Masculino"
                        labelPlacement="end"
                      />
                      <FormControlLabel
                        value="F"
                        control={<Radio color="primary" />}
                        label="Feminino"
                        labelPlacement="end"
                      />
                    </RadioGroup>
                  </FormControl>
                </Grid>  
                <Grid item xs={12}>
                  <FormGroup controlId="name" bssize="large">
                    <TextField
                      error={this.state.optError1}
                      autoFocus
                      type="text"
                      value={this.state.name}
                      label="Nome *"
                      onChange={e => this.setState({ name: e.target.value })}
                      className="col-12"
                    />
                  </FormGroup>
                </Grid>
                <Grid item xs={12}>
                  <Form.Group controlId="email" bssize="large">                  
                    <TextField
                      error={this.state.optError2}
                      autoFocus
                      type="text"
                      value={this.state.email}
                      label="Email *"
                      onChange={e => this.setState({ email: e.target.value })}
                      className="col-12"
                    />
                  </Form.Group>
                </Grid>
                <Grid item xs={12}>
                  <FormUI.Field inline>
                      <Label pointing='below' basic color={this.state.optError3 ? 'red' : 'blue'}>Perfil *</Label>
                      <Dropdown
                        error={this.state.optError3}
                        options={this.optProfileUser}
                        multiple
                        search
                        selection
                        fluid
                        value={this.state.profiles}
                        onChange={this.handleChange}
                      />
                  </FormUI.Field>
                </Grid>
              </Grid>
            </Card.Body>
          </Card>
        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button.Group>
          <Button negative onClick={this.handleShow}>
            <Icon name='remove' /> Cancelar
          </Button>
          <Button.Or text='ou' />
          <Button positive basic={this.state.loading} loading={this.state.loading} onClick={this.validateSubmit}>
            <Icon name='checkmark' /> Salvar
          </Button>
        </Button.Group>
      </Modal.Footer>
      </Modal>
      {this.state.loading ? 
        <Loading main={true} />
      : false}
      </>
    )
  }
}
