// Importar Avatares
import adeSmall from './avatar/small/ade.jpg';
import chrisSmall from './avatar/small/chris.jpg';
import christianSmall from './avatar/small/christian.jpg';
import danielSmall from './avatar/small/daniel.jpg';
import elliotSmall from './avatar/small/elliot.jpg';
import helenSmall from './avatar/small/helen.jpg';
import jennySmall from './avatar/small/jenny.jpg';
import joeSmall from './avatar/small/joe.jpg';
import justenSmall from './avatar/small/justen.jpg';
import mattSmall from './avatar/small/matt.jpg';
import nanSmall from './avatar/small/nan.jpg';
import steveSmall from './avatar/small/steve.jpg';
import stevieSmall from './avatar/small/stevie.jpg';
import tomSmall from './avatar/small/tom.jpg';
import veronikaSmall from './avatar/small/veronika.jpg';
import zoeSmall from './avatar/small/zoe.jpg';

import adeLarge from './avatar/large/ade.jpg';
import chrisLarge from './avatar/large/chris.jpg';
import christianLarge from './avatar/large/christian.jpg';
import danielLarge from './avatar/large/daniel.jpg';
import elliotLarge from './avatar/large/elliot.jpg';
import helenLarge from './avatar/large/helen.jpg';
import jennyLarge from './avatar/large/jenny.jpg';
import joeLarge from './avatar/large/joe.jpg';
import justenLarge from './avatar/large/justen.jpg';
import mattLarge from './avatar/large/matt.jpg';
import nanLarge from './avatar/large/nan.jpg';
import steveLarge from './avatar/large/steve.jpg';
import stevieLarge from './avatar/large/stevie.jpg';
import tomLarge from './avatar/large/tom.jpg';
import veronikaLarge from './avatar/large/veronika.jpg';
import zoeLarge from './avatar/large/zoe.jpg';

// Importar Imagens Musculos
import Abdomem from './img-Muscles/Abdomem.png';
import Aeróbico from './img-Muscles/Aquecimento.png';
import Alongamento from './img-Muscles/Alongamento.png';
import Aquecimento from './img-Muscles/Aquecimento.png';
import Antebraço from './img-Muscles/Antebraço.png';
import Bíceps from './img-Muscles/Bíceps.png';
import Costas from './img-Muscles/Costas.png';
import Glúteos from './img-Muscles/Glúteos.png';
import Ombro from './img-Muscles/Ombro.png';
import Peito from './img-Muscles/Peito.png';
import Pernas from './img-Muscles/Pernas.png';
import Trapézio from './img-Muscles/Trapézio.png';
import Tríceps from './img-Muscles/Tríceps.png';

const avatar = {
        small:  {
            male: [ chrisSmall, christianSmall, danielSmall, elliotSmall, joeSmall, justenSmall, mattSmall, steveSmall, tomSmall ],
            female: [ adeSmall, helenSmall, jennySmall, nanSmall, stevieSmall, veronikaSmall, zoeSmall ]
        },
        large:  {
            male: [ chrisLarge, christianLarge, danielLarge, elliotLarge, joeLarge, justenLarge, mattLarge, steveLarge, tomLarge ],
            female: [ adeLarge, helenLarge, jennyLarge, nanLarge, stevieLarge, veronikaLarge, zoeLarge ]
        }
    };

const muscles = [];
    muscles['Abdomem'] = Abdomem;
    muscles['Aeróbico'] = Aeróbico;
    muscles['Alongamento'] = Alongamento;
    muscles['Aquecimento'] = Aquecimento;
    muscles['Antebraço'] = Antebraço;
    muscles['Bíceps'] = Bíceps;
    muscles['Costas'] = Costas;
    muscles['Glúteos'] = Glúteos;
    muscles['Ombro'] = Ombro;
    muscles['Peito'] = Peito;
    muscles['Pernas'] = Pernas;
    muscles['Trapézio'] = Trapézio;
    muscles['Tríceps'] = Tríceps;

export { avatar, muscles }