import React, { Component } from 'react';
import axios from 'axios';

import { reactLocalStorage } from 'reactjs-localstorage';
import { toast } from 'react-toastify';

import { serverUrl , headerRequest, optMuscleGroup } from '../../lib/funApp/general';

import { Modal, Card, FormGroup } from "react-bootstrap";
import { Button, Dropdown, Form as FormUI, Label, Icon } from 'semantic-ui-react';
import { Fab, Grid, TextField } from '@material-ui/core';
import { Edit as EditIcon }from '@material-ui/icons';

import Loading from '../../containers/loading';

export default class ExerciseEdit extends Component{

  constructor(props){
    super()
    this.exercise = props.exercise
    this.targetUrl = serverUrl + '/src/exercises/' + props.exercise._id
    this.optError1 = false
    this.optError2 = false
    this.optMuscleGroup = optMuscleGroup
    this.state ={
        show: false,
        optError1: false, optError2: false,
        loading: false,
        openDialog: false,
        name: props.exercise.name,
        muscleGroup: props.exercise.muscleGroup
    }
  }

  handleShow = () => this.setState({ show: !this.state.show });

  handleChange = (e, { value }) => this.setState({ muscleGroup: value })

  validateSubmit = () => {
      if( this.state.name.length <= 3 ){

        this.optError1 = true;
        this.setState({ optError1: true });

        if( this.state.name.length > 0 ){
          toast.error('Nome deve ter mais de 3 Letras!!');
        }else{
          toast.error('Preencha o Nome!!');
        }
      }else{
        this.optError1 = false; this.setState({ optError1: false });
        this.setState({ show: false, loading: true });
        this.handleSubmit();
      }
  }

  handleSubmit = (e) => {

    let params = {};

    params.name = this.state.name;
    params.muscleGroup = this.state.muscleGroup;

    axios.patch(this.targetUrl, params, {"headers" : headerRequest})
      .then(res => {
        if(res.status === 200){
          reactLocalStorage.set('@result', 201);
          setTimeout(() => {
            return window.location.reload(false);
          }, 2000);
        }
      }).catch(error => {
        if(error.response.data.message.includes('dup key: {')){
          this.setState({ show1: true, loading: false })
          toast.error('Exercício já Cadastrado!!');
        }else{
          this.setState({ show1: true, loading: false })
          toast.error('Erro Inesperado!!');
          console.log('ERROR: ', error.response.status + ' [' + error.response.data.message +']');
        }
    });
}

  render(){
    return(
      <>
      <Fab className='m-2' size="small" onClick={this.handleShow} title="Editar Exercício" style={{ backgroundColor: ' #fb3', color: '#fff' }} >
        <EditIcon />
      </Fab>
      <Modal show={this.state.show} onHide={this.handleShow} centered>
        <Modal.Header closeButton>
          <Modal.Title><EditIcon fontSize="large" className="mr-2" />Editar Exercício</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Card>
            <Card.Body>
              <Grid container spacing={3}>
                  <Grid item xs={12}>
                      <FormGroup controlId="name" bssize="large">
                          <TextField
                          error={this.state.optError1}
                          autoFocus
                          type="text"
                          value={this.state.name}
                          label="Nome *"
                          onChange={e => this.setState({ name: e.target.value})}
                          className="col-12"
                          />
                      </FormGroup>
                  </Grid>
                  <Grid item xs={12} >
                      <FormUI.Field inline>
                          <Label pointing='below' basic color={this.state.optError2 ? 'red' : 'blue'}>Grupo Muscular</Label>
                          <Dropdown
                              error={this.state.optError2}
                              options={this.optMuscleGroup}
                              search
                              fluid
                              selection
                              value={this.state.muscleGroup}
                              onChange={this.handleChange}
                          />
                      </FormUI.Field>
                  </Grid>
              </Grid>
            </Card.Body>
          </Card>
        </Modal.Body>
        <Modal.Footer>
          <Button.Group>
            <Button negative onClick={this.handleShow}>
              <Icon name='remove' /> Cancelar
            </Button>
            <Button.Or text='ou' />
            <Button positive basic={this.state.loading} loading={this.state.loading} onClick={this.validateSubmit} >
              <Icon name='checkmark' /> Salvar
            </Button>
          </Button.Group>
        </Modal.Footer>
      </Modal>
      { this.state.loading ?
        <Loading />
      : false }
      </ >
    )
  }
}
