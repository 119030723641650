import React, {Component } from 'react'
import axios from 'axios';

import { reactLocalStorage } from 'reactjs-localstorage';
import { toast } from 'react-toastify';

import { serverUrl , headerRequest } from '../../lib/funApp/general';

import { MDBIcon } from 'mdbreact';
import { Modal,} from "react-bootstrap";
import { Fab } from '@material-ui/core';
import { Button, Card, Icon } from 'semantic-ui-react';

import { DeleteForever, Block }from '@material-ui/icons';

import Loading from '../../containers/loading';

export default class CategoryDelete extends Component{

  constructor(props){
    super()
    this.categories = props.categories
    this.childs = []
    this.state = {
      show: false,
      loading: false,
      item: {},
      parent: {}
    }
  }

  handleShow = (e) => {
    let radVal = document.mainForm.rads2.value
    if(radVal.length > 0){
      this.childs = []
      this.categories.map((item, index)=>(
          radVal === item._id ?
            this.setState({ item: item, show: !this.state.show }) +
            this.parentName( item ) + 
            this.validateChild( item )
          : false
      ))
    }else{toast.info('Selecione uma Categoria!!')}
  }

  parentName = (currentItem) => {
    if(currentItem.parent !== undefined){
        this.categories.map((item, index)=>(
            currentItem.parent === item._id ?
                this.setState({ parent: item })
            : false
        ))
    }
  }  

  validateChild = (currentItem) => {
    this.categories.map((item, index)=>(
      currentItem._id === item.parent ?
          this.childs.push(item)
      : false
    ))
  }

  handleSubmit = (e) => {

    this.setState({ show: false, loading: true })

    let targetUrl = serverUrl + '/src/categories/' + this.state.item._id;

    axios.delete(targetUrl, {"headers" : headerRequest})
      .then(res => {
        if(res.status === 204){
          reactLocalStorage.set('@result', 204); 
          setTimeout(() => {
            return window.location.reload(false);
          }, 2000);
        }
      }).catch(error => {
        if(error.response.data.message.includes('dup key: {')){
            this.setState({ show: true, loading: false })
            toast.error('Categoria já Cadastrada!!');
        }else{
          this.setState({ show: true, loading: false })
          toast.error('Erro Inesperado!!');
          console.log('ERROR: ', error.response.status + ' [' + error.response.data.message +']');
        }  
      });
  }

  render(){
    return(
      <>
      <Fab className='m-2' size="small" onClick={this.handleShow} title="Deletar Categoria" style={{ float: 'right', backgroundColor: ' #ff3547', color: '#fff' }}>
        <DeleteForever />
      </Fab>
      <Modal size="sm" show={this.state.show} onHide={this.handleShow} centered style={{backgroundColor: 'transparent !important' }}>        
        <Card style={{ width: '100%' }}>
          <div className='m-3 text-center text-danger'>
            {this.childs.length > 0 ?
              <><Block fontSize="large" className="mr-2" />Não pode ser Excluído!!!</>
              :
              <><DeleteForever fontSize="large" className="mr-2" />Deseja Excluir a Categoria?</>
            }
          </div>
          <Card.Content>
            <Card.Header className='m-2'>
              {this.state.item.name}
            </Card.Header>
            <Card.Description className='m-4'>
              {this.childs.length > 0 ?
                <div>
                  <hr />
                  <p>Existem Categorias que estão ligadas a esta como Sub Categoria.</p> 
                  <p>Para excluir realoque as categorias vinculadas!!!</p>
                  <ul> 
                  {this.childs.map((child, index)=>(
                    <li key={index}>
                          <MDBIcon icon="angle-double-right" /> {child.name}
                    </li>
                  ))}
                  </ul>
                </div>
              :false}
            </Card.Description>
          </Card.Content>
          <Card.Content extra className='text-center'>
            <Button.Group>
              <Button negative onClick={this.handleShow}>
                <Icon name='remove' />Cancelar
              </Button>
              <Button.Or text='ou' />
              <Button positive basic={this.state.loading} loading={this.state.loading} onClick={this.handleSubmit} >
                <Icon name='checkmark' />Excluir
              </Button>
            </Button.Group>
          </Card.Content>
        </Card>
      </Modal>
      { this.state.loading ? 
        <Loading />
      : false }
     </ > 
    )
  }
}