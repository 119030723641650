import React, { Component } from 'react';
import UsersRenderData from '../components/workout/workoutUsers';
import { RequestInfoGet, pageSrc, serverUrl } from '../lib/funApp/general'

let targetUrl = '/src/users'

if(pageSrc.includes(targetUrl)){
  targetUrl = serverUrl + pageSrc
}else{
  targetUrl = serverUrl + targetUrl
}

class UserGet extends Component {

  state = {
    users: [],
    links: {}
  }

  componentDidMount() {
    fetch(targetUrl ,RequestInfoGet)
    .then(res => res.json())
    .then((data) => {
      this.setState({ links: data._links })
      this.setState({ users: data.items })
    })
    .catch()
  }
 
  render() {
    return (
      <>
      {this.state.users[0] !== undefined ?
        <UsersRenderData users={[this.state.users,this.state.links]} />
        :false
      }
      </>
    )
  }

}

export default UserGet 