
import React, { Component } from 'react';
import axios from 'axios';

import { toast } from 'react-toastify';
import { reactLocalStorage } from 'reactjs-localstorage';
import Toggle from 'react-toggle'

import { serverUrl , headerRequest} from '../../lib/funApp/general'
import '../../lib/components/css/switch.css';

import Loading from '../../containers/loading';

export default class UserStatus extends Component{

  constructor(props){
    super()
    this.user = props.user
    this.targetUrl = serverUrl + '/src/users/' + props.user._id
    this.newStatus = { status: !props.user.status }
    this.state ={
        check: props.user.status,
        loading: false
    }
    
    this.handleCheck = (prevState) => {
      
      setTimeout(() => {
        this.setState({ loading: true })
      }, 1000);

      axios.patch(this.targetUrl, this.newStatus, {"headers" : headerRequest})
      .then(res => {
        if(res.status === 200){
          reactLocalStorage.set('@result', 202); 
          setTimeout(() => {
            return window.location.reload(false);
          }, 2000);
        }
      }).catch(error => {
          this.setState({ loading: false })
          toast.error('Erro Inesperado!!');
          console.log(error.response.status + ' [' + error.response.data.message +']')
        }
      );
    
    }

  }

  render(){
    return(
        <span style={{ height: '30px', alignSelf: 'center' }}>
          <label>
            <Toggle
              defaultChecked={this.state.check}
              onChange={this.handleCheck} />
          </label>
          {this.state.loading ? 
            <Loading main={true} />
          : false}
        </span>
    )
  }
}