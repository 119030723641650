import React, {Component} from 'react'
import * as moment from 'moment'

import CurrencyFormat from 'react-currency-format';

import { MDBIcon } from 'mdbreact';
import { Container, Row , Col } from "react-bootstrap";
import { NotificationAccount } from '../../containers/Notification';

import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';

import AccountStatus from './accountsStatus'
import AccountDelete from './accountDelete'
import AccountEdit from './accountEdit'
import AccontDial from './accountDial';

export default class AccountsRender extends Component {

  constructor(props){ 
      super()
      this.accountsData = props.accounts[0]
      this.accountsLinks = props.accounts[1]
      this.state ={
        expanded: sessionStorage.getItem('@accordPeoples') ? sessionStorage.getItem('@accordPeoples') : false
      }
  }

  handleChange (panel){
    if(this.state.expanded !== panel){
      this.setState({expanded :  panel })
      sessionStorage.setItem('@accordPeoples', panel)
    }else{
      this.setState({expanded :  false })
      sessionStorage.removeItem('@accordPeoples')
    }
  };

  render(){
    return(
      <>
      <Container>
        <Row className="TitleContainer">
          <Col style={{ padding: "10px"}}>
            <h4><MDBIcon icon="cash-register" className="mr-2 ml-4" />Contas</h4>
          </Col>
          <Col>
            <AccontDial />
          </Col>
        </Row>
      </Container>
      <Container className="MainContainer">
        <NotificationAccount/>
        <Row>
          <Col>
          {this.accountsData.map((account, k1)=>(
            <div className="w-100" key={k1}>
              <Accordion expanded={this.state.expanded === `panel${k1}`} onChange={e=> this.handleChange(`panel${k1}`)} className='ExpansionPainelData'>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Container>
                    <Row>
                      <Col xs={12} md={6}>
                        {account.status === true ? 
                          <MDBIcon icon="money-bill-wave" size="lg" style={{ color:"green" }}/> 
                        : <MDBIcon icon="money-bill-wave" size="lg"  style={{ color:"red" }}/>
                        }
                        <span style={{ marginLeft: "10px" }}>{account.name}</span>
                      </Col>
                      <Col xs={12} md={6} style={{ textAlign: 'right' }}>
                        <strong>R$</strong> {account.balance.toFixed(2)}
                      </Col>
                    </Row>
                  </Container>
                </AccordionSummary>
                <AccordionDetails>
                  <Container>
                    <Row className="mb-1" style={{ lineHeight: "30px"}}>                    
                      <Col xs={12}  md={4} className="mb-1">
                        <span className="Rotulo"><strong>Tipo: </strong></span><span className="Rotulo">{account.type}</span>
                      </Col>
                      <Col xs={6}  md={4} className="mb-1">
                        <span className="Rotulo"><strong>Número: </strong></span><span className="Rotulo">{account.number}</span>
                      </Col>
                      <Col xs={6}  md={4} className="mb-1">
                        <span className="Rotulo"><strong>Limite: </strong></span><span className="Rotulo">
                          <CurrencyFormat 
                            value={account.limit} 
                            displayType={'text'} 
                            thousandSeparator='.' 
                            decimalSeparator=','
                            decimalScale={2}
                            fixedDecimalScale={true}
                          />
                       </span>
                      </Col>
                      </Row>
                      <Row className="mb-1" style={{ lineHeight: "30px"}}>     
                        <Col xs={12}  md={6} className="mb-1">
                          <span className="Rotulo"><strong>Banco: </strong></span><span className="Rotulo">{account.bank}</span>
                        </Col>
                        <Col xs={12}  md={6} className="mb-1">
                          <span className="Rotulo"><strong>Abertura: </strong></span>
                          <span className="Rotulo">{moment(account.openDate).format('DD/MM/YYYY')} 
                          ->
                          <CurrencyFormat 
                            value={account.openBalance} 
                            displayType={'text'} 
                            thousandSeparator='.' 
                            decimalSeparator=','
                            decimalScale={2}
                            fixedDecimalScale={true}
                          /></span>
                        </Col>
                    </Row>
                    <Row className="mb-1" style={{ lineHeight: "30px"}}>                    
                        <Col style={{ height: "25px !important", marginTop: "5px" }}>
                          <strong style={{ fontSize: "16px", fontWeight: "600", verticalAlign: "super" }}>Ativo: </strong>
                          <AccountStatus account={account}/>
                        </Col>
                        <Col className="text-right">
                          <AccountEdit account={account}/>
                          <span className='m-2'></span>
                          <AccountDelete  account={account}/>
                        </Col>
                    </Row>
                  </Container>
                </AccordionDetails>
              </Accordion>
            </div>
          ))}
          </Col>
        </Row>
      </Container>
      </>  
    )
  }
}



