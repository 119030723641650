import React, {Component} from 'react'
import {  Form, Container, Row , Col } from "react-bootstrap";
import { MDBIcon } from 'mdbreact';
import parse from 'html-react-parser';
import {categoriesFilter } from '../../lib/funApp/general'

import Accordion from '@material-ui/core/Accordion';
import {AccordionDetails, AccordionSummary } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import './categories.css'

import { NotificationCategory } from '../../containers/Notification';

import CategoriesDial from './categoriesDial';
import CategotyEdit from './categoryEdit'
import CategoryDelete from './categoryDelete'

class CategoriesRenderGet extends Component {

    constructor(categories){
        super()
        this.state ={
            expanded:  sessionStorage.getItem('@accordCategories') ? sessionStorage.getItem('@accordCategories') : false,
            param: 'e',
            param1: false,
            select: {},
            parent: {},
            requirementKey1: Math.random()
          }
        this.categoryData = categories.categories[0]
        this.categoryLinks = categories.categories[1]
        this.expenses = categoriesFilter({categories: categories.categories[0]},'D')
        this.incomes = categoriesFilter({categories: categories.categories[0]},'R')
        this.expensesString = JSON.stringify(this.expenses)
                                    .replace(/\[]/g, "")
                                    .replace(/\[/g, "<ul class='tree'>")
                                    .replace(/\]/g, "</ul>")
                                    .replace(/\{/g, "")
                                    .replace(/\}/g, "")
                                    .replace(/"/g, "") 
                                    .replace(/id:/g, '<li><label><input type="radio" class="option-input radio" name="rads2" value="')
                                    .replace(/,child:/g, '</span></label></li>')
                                    .replace(/,/g, "")
                                    .replace(/name:/g, '"><span style="background-color: white; padding: 2px;">')
        this.incomesString = JSON.stringify(this.incomes)
                                    .replace(/\[]/g, "")
                                    .replace(/\[/g, "<ul class='tree'>")
                                    .replace(/\]/g, "</ul>")
                                    .replace(/\{/g, "")
                                    .replace(/\}/g, "")
                                    .replace(/"/g, "") 
                                    .replace(/id:/g, '<li><label><input type="radio" class="option-input radio" name="rads2" value="')
                                    .replace(/,child:/g, '</span></label></li>')
                                    .replace(/,/g, "")
                                    .replace(/name:/g, '"><span style="background-color: white; padding: 2px;">')                                   
    }

    handleChange (panel){
        if(this.state.expanded !== panel){
          this.setState({expanded :  panel })
          sessionStorage.setItem('@accordCategories', panel)
        }else{
          this.setState({expanded :  false })
          sessionStorage.removeItem('@accordCategories')
        }
    };

    handleSubmit = (event) => {
        event.preventDefault();
        let radVal = document.mainForm.rads2.value;
        if(radVal.length > 0){
            this.categoryData.map((item, index)=>(
              radVal === item._id ?
               this.setState({select: item}) 
              : false
          ))
          this.setState({param1: true})
          this.setState({requirementKey1: Math.random()})
        }
        
    }
    
    parentName(item1){
        if(item1.parent !== undefined){
            this.categoryData.map((item, index)=>(
                item1.parent === item._id ?
                    this.setState({parent: item})
                : false
            ))
        }
      }

    printRecursivo = (array,classif) => {
        classif++;
        array.map((item, index)=>(
            console.log(classif,' - ',item.name)+
            item.child ? 
            this.printRecursivo(item.child,classif)
            :false
        ))
      }
    
    render(){
      return(
        <>
        <Container>
            {this.printRecursivo(this.expenses, 1)}
          <Row className="TitleContainer">
            <Col style={{ padding: "10px"}}>
                <h4><MDBIcon icon="donate" className="mr-2 ml-4" />Categorias</h4>
            </Col>
            <Col>
                <CategoriesDial categories={this.categoryData}/>
            </Col>
          </Row>
        </Container>
        <Container className="MainContainerB"> 
            <NotificationCategory/>           
            <Form onSubmit={this.handleSubmit} method="get" name="mainForm" id="mainForm">
                <Row>
                    <Col>
                        <CategoryDelete categories={this.categoryData} />
                        <CategotyEdit categories={this.categoryData} />
                    </Col>
                </Row>                
                <Row>
                    <Col>
                        <Accordion expanded={this.state.expanded === `panelR`} onChange={e=> this.handleChange(`panelR`)} className='ExpansionPainelData'>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                            >
                                <Container>
                                    <Row>
                                        <Col>
                                            <div className="mb-2 text-primary">
                                                <MDBIcon icon="plane-departure" size="lg"/> <strong>Receitas</strong>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Container>
                                    <Row>
                                        <Col>
                                        { parse(this.incomesString) }
                                        </Col>
                                    </Row>
                                </Container>
                            </AccordionDetails>
                        </Accordion>
                    </Col>
                </Row>    
                <Row>
                    <Col>
                        <Accordion expanded={this.state.expanded === `panelD`} onChange={e=> this.handleChange(`panelD`)} className='ExpansionPainelData'>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                            >
                                <Container>
                                    <Row>
                                        <Col>
                                            <div className="mb-2 text-danger">
                                                <MDBIcon icon="plane-arrival" size="lg"/> <strong>Despesas</strong>
                                            </div>
                                        </Col>
                                    </Row>
                                </Container>
                            </AccordionSummary>
                            <AccordionDetails>
                                <Container>
                                    <Row>
                                        <Col>
                                        { parse(this.expensesString) }
                                        </Col>
                                    </Row>
                                </Container>
                            </AccordionDetails>
                        </Accordion>
                    </Col>
                </Row>    
            </Form>
        </Container>
        </>
      )
    }

}

export default CategoriesRenderGet