import React, { Component } from 'react';
import CategoriesRenderGet from '../components/categories/categories';
import { RequestInfoGet, pageSrc, serverUrl } from '../lib/funApp/general'

let targetUrl = '/src/categories'

if(pageSrc.includes(targetUrl)){
  targetUrl = serverUrl + pageSrc
}else{
  targetUrl = serverUrl + targetUrl
}

class CategoriesGet extends Component {

  state = {
    categories: [],
    links: {}
  }

  componentDidMount() {
    fetch(targetUrl ,RequestInfoGet)
    .then(res => res.json())
    .then((data) => {
      this.setState({ links: data._links })
      this.setState({ categories: data.items })
    })
    .catch()
  }

  render() {
    return (
      <>
      {this.state.categories[0] !== undefined ?
        <CategoriesRenderGet categories={[this.state.categories,this.state.links]} /> 
      :false
      }
      </>
    )
  }

}

export default CategoriesGet;