import React, {Component} from 'react'
import axios from 'axios';

import Moment from 'react-moment';
import * as moment from 'moment'
import 'moment-timezone';

import { Redirect } from 'react-router-dom'
import { reactLocalStorage } from 'reactjs-localstorage';

import { serverUrl , headerRequest, UserId} from '../../lib/funApp/general'
import { muscles } from '../../lib/img/img';

import { MDBIcon } from 'mdbreact';
import { Container, Row, Col, Modal } from "react-bootstrap";
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import { Button, Icon, Label, Card, Image, Grid } from 'semantic-ui-react'

import Loading from '../../containers/loading';
import { BodybuildingplugDetail } from './workouts';

export default class WorkoutsRenderActive extends Component {

  constructor(workouts){
    super()
    this.dateNow = moment().format('YYYY-MM-DD');
    this.workoutsData = []
      workouts.workouts[0].map((ficha, index) => (
        moment(ficha.dateStart).format('YYYY-MM-DD') <= this.dateNow ?
          this.workoutsData.push(ficha)
        : false
      ))
    this.workout = this.workoutsData[this.workoutsData.length - 1]
    this.targetUrl = serverUrl + '/src/bodybuildingplugs?user=' + UserId
    this.state ={
      expanded: sessionStorage.getItem('@workActiveMicro') ? sessionStorage.getItem('@workActiveMicro') : 'panelM1',
      expanded2: sessionStorage.getItem('@workActive') ? sessionStorage.getItem('@workActive') : false,
      expanded3: false,
      redirect: reactLocalStorage.get('@bodyActive') ? true : false,
      performed: 0,
      lastTraining: [],
    }
    this.performed = 0
  }

  componentDidMount(){

    this.setState({ performed: this.performed})

    axios.get(this.targetUrl, {"headers" : headerRequest})
    .then(res => {
      if(res.status === 200){
        if(res.data.items.length > 0){
          let dataTemp = res.data.items.filter(function (el) {
            return el.matrix === false
          });
          if(dataTemp.length > 0){
            let dataGet = dataTemp.slice(Math.max(dataTemp.length - 5, 1))
            dataGet = dataGet.reverse()
            this.setState({lastTraining: dataGet})
          }
        }
      }
    }).catch(error => {
        console.log(error.response.status + ' [' + error.response.data.message +']')
      }
    );
  }

  handleChange(panel){
    if(this.state.expanded !== panel){
      this.setState({expanded :  panel })
      sessionStorage.setItem('@workActiveMicro', panel)
    }else{
      this.setState({expanded :  false })
      sessionStorage.removeItem('@workActiveMicro')
    }
  };

  handleChange2(panel){
    if(this.state.expanded2 !== panel){
      this.setState({expanded2:  panel })
      sessionStorage.setItem('@workActive', panel)
    }else{
      this.setState({expanded2 :  false })
      sessionStorage.removeItem('@workActive')
    }
  };

  handleChange3(panel){
    if(this.state.expanded3 !== panel){
      this.setState({expanded3:  panel })
    }else{
      this.setState({expanded3 :  false })
    }
  };

  resetMuscleBody(){
    this.muscleBody = []
  }

  sumPerformed(performed){
    this.performed = this.performed + performed
    this.key = this.performed
  }

  resetPerformed(){
    this.performed = 0
  }

  compare(a, b) {

    const letterA = a.letter.toUpperCase();
    const letterB = b.letter.toUpperCase();

    let comparison = 0;
    if (letterA > letterB) {
      comparison = 1;
    } else if (letterA < letterB) {
      comparison = -1;
    }
    return comparison;
  }

  render(){
    if (this.state.redirect) {
      return <Redirect exact to='/active' />
    }else{
      this.resetPerformed()
      return(
        <>
          <Container>
            <Row className="TitleContainer">
              <Col style={{ padding: "10px"}}>
                <h4><MDBIcon icon="dumbbell" className="mr-2 ml-4" />Treino Ativo</h4>
              </Col>
            </Row>
          </Container>
          <Container className="MainContainer">
            <Row>
              <Col>
                <Accordion expanded={this.state.expanded === `panelM1`} onChange={e=> this.handleChange(`panelM1`)} className='ExpansionPainelData'>
                  <AccordionSummary
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    >
                    <Container className='p-0'>
                      <Row>
                        <Col>
                          <MDBIcon icon={this.state.expanded === `panelM1` ? "sort-amount-up-alt" : "sort-amount-down"} size='lg' className='mr-2' />
                          <span><strong>Microciclo: </strong>{this.workout.microcycle}</span>
                          <span className="float-right" key={this.performed}>
                            {this.state.performed}/{this.workout.duration}
                          </span>
                        </Col>
                      </Row>
                    </Container>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className="w-100">
                      <Row>
                        <Col sm={12} md={4} className="p-2">
                          <strong><span className="Rotulo">Mesociclo: </span></strong>
                          <span className="Rotulo">{this.workout.mesocycle}</span>
                        </Col>
                        <Col sm={12} md={4} className="p-2 text-sm-left text-md-center">
                          <strong><span className="Rotulo">Data Inicio: </span></strong>
                          <span className="Rotulo">
                            <Moment format="DD/MM/YYYY" style={{marginLeft: "5px"}}>
                              {this.workout.dateStart}
                            </Moment>
                          </span>
                        </Col>
                        <Col sm={12} md={4} className="p-2 text-sm-left text-md-right">
                          <strong><span className="Rotulo">Objetivo: </span></strong>
                          <span className="Rotulo">{this.workout.goal}</span>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12} md={6} className="p-2">
                          <strong><span className="Rotulo">Intervalo entre Séries: </span></strong>
                          <span className="Rotulo">{this.workout.intervalRepetition}</span>
                        </Col>
                        <Col sm={12} md={6} className="p-2 text-sm-left text-md-right">
                          <strong><span className="Rotulo">Intervalo entre Exercícios: </span></strong>
                          <span className="Rotulo">{this.workout.intervalExercises}</span>
                        </Col>
                      </Row>
                      <Row>
                        <Col sm={12} md={6}>
                          <Row>
                            <Col sm={12} className="p-2">
                              <strong><span className="Rotulo">Método: </span></strong>
                              <span className="exRotulo">{this.workout.method}</span>
                            </Col>
                            {this.workout.obs ?
                              <Col sm={12} className="p-2">
                                <strong><span className="Rotulo">Obs: </span></strong>
                                <span className="Rotulo">{this.workout.obs}</span>
                              </Col>
                            :false}
                          </Row>
                        </Col>
                      </Row>

                      <Row>
                        <Col>
                          {this.state.lastTraining.length > 0 ?
                            <div>
                              <Accordion expanded={this.state.expanded3 === `panelLt`} onClick={e=> this.handleChange3(`panelLt`)}>
                                <AccordionSummary
                                  aria-controls="panelLt-content"
                                  id="panelLt-header"
                                  style={{backgroundColor: "#d3d3d37a" }}
                                  className='NoMargin'
                                  >
                                  <div className="w-100 text-center">
                                    <MDBIcon icon={this.state.expanded3 === `panelLt` ? "minus" : "plus"} size='sm' className='mr-2' />
                                    <span>Ultimos Treinos:</span>
                                  </div>
                                </AccordionSummary>
                                <AccordionDetails style={{padding: '15px'}}>
                                  <div className="w-100">
                                    <Card.Group centered>
                                      {this.state.lastTraining.map((item, index)=>(
                                      <Card key={index}>
                                        <Card.Content description= {`Ficha: ${item.letter}`} />
                                        <Card.Content extra>
                                          {moment.tz(item.dateStart, 'Europe/London').subtract(1,'hour').format('DD/MM/YYYY-HH:mm')}
                                        </Card.Content>
                                      </Card>
                                      ))}
                                    </Card.Group>
                                  </div>
                                </AccordionDetails>
                              </Accordion>
                            </div>
                          :false
                        }
                        </Col>
                      </Row>
                    </div>
                  </AccordionDetails>
                </Accordion>
              </Col>
            </Row>

            <Row>
              <Col>
                {this.workout.bodyBuildingPlugs.sort(this.compare).map((bodybuildingplug, k2) => (
                  <div key={k2}>
                  <Accordion expanded={this.state.expanded2 === `panel${k2}`} onClick={e=> this.handleChange2(`panel${k2}`)} className='ExpansionPainelData'>
                    <AccordionSummary
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                      style={{backgroundColor: "#d3d3d37a" }}
                      className='NoMargin'
                    >
                      <div  className="w-100">
                        <MDBIcon icon={this.state.expanded2 === `panel${k2}` ? "minus" : "plus"} size='sm' className='mr-2' />
                        <span>Ficha: {bodybuildingplug.letter} </span>
                        <StartTraining bodybuildingplug={bodybuildingplug}/>
                        {this.sumPerformed(bodybuildingplug.performed)}
                      </div>
                    </AccordionSummary>
                    <AccordionDetails style={{padding: '15px'}}>
                      <div className="w-100">
                        <BodybuildingplugDetail bodybuildingplug={bodybuildingplug} />
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </div>
                ))}
              </Col>
            </Row>
          </Container>
      </>
      )
    }
  }
}

class StartTraining extends Component {

  constructor(props){
    super()
    this.bodybuildingplug = props.bodybuildingplug
    this.muscleBody = []
    this.state={
      show: false,
      loading: false
    }
  }

  handleShow = (event) => {
    event.stopPropagation()
    this.setState({ show: !this.state.show })
  };

  handleShow2 = () => this.setState({ show: !this.state.show });

  concatMuscleBody(muscle, k2){
    this.muscleBody.push( muscle)
  }

  filterMuscle(arr){
    let arrNew = arr.filter((este, i) => arr.indexOf(este) === i).sort();
    return arrNew
  }

  handleSubmit = () => {

    this.setState({ show: false, loading: true })
    const jsonBody = JSON.stringify(this.bodybuildingplug)
    const jsonTraining = JSON.stringify(this.bodybuildingplug.training)
    const dateStart = moment().format('YYYY-MM-DD HH:mm:ss')

    reactLocalStorage.set('@bodyActive', jsonBody)
    reactLocalStorage.set('@trainingActive', jsonTraining)
    reactLocalStorage.set('@dateActive', dateStart)
    setTimeout(() => {
      return window.location.reload(false);
    }, 2000);
  }

  render(){
    return(
      <>
        <Button as='div' labelPosition='right' className='float-right'
            onClick={this.handleShow} disabled={!this.bodybuildingplug.training.length > 0}>
          <Button color={this.bodybuildingplug.training.length > 0 ? 'red' : 'grey'}>
            <Icon name='heartbeat' size='large'/>
            Treinar
          </Button>
          <Label as='a' basic color='red' pointing='left'>
            {this.bodybuildingplug.performed}
          </Label>
        </Button>
        <Modal show={this.state.show} onHide={this.handleShow2} centered style={{backgroundColor: 'transparent !important' }}>
          <Card style={{ width: '100%' }}>
            <Card.Content>
              <Card.Header className='m-2 text-center'>
                <MDBIcon icon="heartbeat" className="red-text mr-2"  size="lg" />
                Ficha: {this.bodybuildingplug.letter}
              </Card.Header>
            </Card.Content>
            <Card.Content>
              <Card.Description className='m-4'>
                {this.bodybuildingplug.training.map((training, index) => (
                  this.concatMuscleBody(training.exercise.muscleGroup)
                ))}
                {this.muscleBody ?
                  <Row>
                    <Col>
                      <Grid columns={2} centered>
                        {this.filterMuscle(this.muscleBody).map((muscle, i)=>(
                          <Grid.Column className='text-center' key={i}>
                              <Label as='a' color='orange' ribbon>
                                {muscle}
                              </Label>
                              <Image circular size='small' src={muscles[muscle]} verticalAlign='middle' />
                          </Grid.Column>
                        ))}
                      </Grid>
                    </Col>
                  </Row>
                :false }
              </Card.Description>
            </Card.Content>
            <Card.Content extra className='text-center'>
              <Button.Group>
                <Button negative onClick={this.handleShow}>
                  <MDBIcon icon="tired" size="lg" className="mr-2"/>Cancelar
                </Button>
                <Button.Or text='ou' />
                <Button positive onClick={this.handleSubmit} >
                  Executar<MDBIcon icon="grin-hearts" size="lg" className="ml-2"/>
                </Button>
              </Button.Group>
            </Card.Content>
          </Card>
        </Modal>
        { this.state.loading ?
        <Loading />
      : false }
      </>
    )
  }
}