import React, { Component } from "react";
import axios from 'axios';
import moment from 'moment';

import Chart from "react-apexcharts";
import { serverUrl, headerRequest, noStatistic} from '../../../lib/funApp/general'

import { Card, Container } from "react-bootstrap";


export default class CategoryPie extends Component {
  constructor(props) {
    super(props);
    this.categories = []
    this.state = {
        series: [],
        options: {},
        expenses: [],
        incomes: []
      }
    }

    async componentDidMount() {

      const reqCategories = axios.get((serverUrl + '/src/categories'), {"headers" : headerRequest})

      await axios.all([reqCategories])
        .then(axios.spread((...responses) => {
          this.categories = responses[0].data.items
        })).catch(errors => {
          console.log(...errors)
      });

      const targetUrl = serverUrl
      + '/src/records-cat?dtstart=' + moment().startOf('month').format('YYYY-MM-DD')
      + '&dtend=' + moment().endOf('month').format('YYYY-MM-DD')
      + '&noStatistic=' + noStatistic
      + '&tp=cat'

      await axios.get(targetUrl, {"headers" : headerRequest})
      .then(res => {
          if(res.status === 200){

            let resultD = [[],[]]
            let resultR = [[],[]]

            res.data.items.forEach(element => {

              element.category = this.getCategory(element._id.category)

              if(element.category !== undefined){

                if(element.category[1] === "R"){
                  resultR[0].push(element.category[0])
                  resultR[1].push(element.total)
                } else if(element.category[1] === "D"){
                  resultD[0].push(element.category[0])
                  resultD[1].push(element.total)
                }
              }
            });
            this.setState({
              expenses: resultD,
              incomes: resultR,
              series: resultD[1],
              options: {
                chart: {
                  width: 250,
                  height: '100%',
                  type: 'pie',
                  toolbar: {
                    show: true,
                    offsetX: 0,
                    offsetY: 0,
                    tools: {
                        download: true,
                        selection: false,
                        zoom: false,
                        zoomin: false,
                        zoomout: false,
                        pan: false,
                        reset: false | '<img src="/static/icons/reset.png" width="20">',
                        customIcons: []
                    },
                  },
                },
                labels: resultD[0],
                title: {
                  text: "Categorias",
                  style: {
                    fontSize:  '14px',
                    fontWeight:  'bold',
                    color:  '#263238'
                  },

                },
                tooltip: {
                  enabled: true,
                  fillSeriesColor: false,
                  onDatasetHover: {
                    highlightDataSeries: true,
                  },
                  y: {
                    formatter: function(val) {
                      return Number(val).toFixed(2)
                    },
                  },
                },
                dataLabels: {
                  dropShadow: false,
                  x: 25,
                  align: 'center',
                  verticalAlign: 'middle',
                  formatter: function (val, opt) {
                    return opt.w.globals.labels[opt.seriesIndex] + ":  " + Number(opt.w.globals.seriesPercent[opt.seriesIndex]).toFixed(0) + "%"
                  },
                  style: {
                    fontSize: '12px',
                    fontcolor: 'black',
                  },
                  background: {
                    enabled: true,
                    foreColor: '#000',
                    borderRadius: 2,
                    padding: 4,
                    opacity: 0.8,
                    borderWidth: 1,
                    borderColor: '#fff'
                  },

                },
                legend: false,
              },
            })
          }
      })
    }

    getCategory = (id) => {
      const found = this.categories.find(element => element._id === id);
      if(found !== undefined){
          return [found.name,found.type]
      }
    }

    render() {
        return (
          <Container>
            <Card>
                <Card.Body>
                    <div className="mixed-chart">
                        <Chart
                            options={this.state.options}
                            series={this.state.series}
                            type="pie"
                        />
                    </div>
                </Card.Body>
            </Card>
          </Container>
        );
    }
}