import React, {Component} from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import moment from 'moment';

import { lastMounths, LastDaysMonths, serverUrl, headerRequest, noStatistic} from '../../lib/funApp/general'

import { Container, Row, Col, Card, CardDeck} from 'react-bootstrap';
import { MDBIcon } from 'mdbreact';
import { Paper, Tabs, Tab, Typography, Box, Grid} from '@material-ui/core';
import { Statistic } from 'semantic-ui-react'

import RecordAdd from './recordAdd'
import BankBalance from './bankBalance'

import IncomesExpensesLine from '../charts/line/incomesExpenses'
import ProfitLine from '../charts/line/profit'
import CategoryPie from '../charts/pie/category'
import AvailableColumn from '../charts/column/available'

export default class Financial extends Component {

    constructor(){
        super()
        this.lastMounths = lastMounths(7).reverse()
        this.Months = LastDaysMonths(5)
        this.state={
            dataAccount: [],
            value: 0,
            seriesIncExp: [],
            seriesAvailable: [],
            seriesProfit: [],
            seriesProfitTemp:[],
            seriesProfit2: [],
            count: 0,
            profit: 0,
            incomes: 0,
            expenses: 0,
            avaiable: 0
        }
    }

    async componentDidMount() {

      const reqIncExp = axios.get((serverUrl
        + '/src/records-recdesp?dtstart=' + moment().startOf('month').subtract(6, "month").format('YYYY-MM-DD')
        + '&dtend='+ moment().endOf('month').format('YYYY-MM-DD')
        + '&noStatistic=' + noStatistic
        + '&tp=recdesp'), {"headers" : headerRequest})


      axios.all([reqIncExp])
        .then(axios.spread((...responses) => {

          this.processDataIncExp(responses[0].data.items)

        })).catch(errors => {
          console.log(...errors)
        });

      await this.getAccounts()
      await this.getDataBalance()
    }

    getAccounts = async ()=>{
      // Accounts
      const targetUrlAccounts = serverUrl + '/src/accounts'
      await axios.get(targetUrlAccounts, {"headers" : headerRequest})
           .then(res => {
               if(res.status === 200){
                   if(res.data.items.length > 0){
                       this.setState({dataAccount: res.data.items});
                   }
               }
           }).catch(error => {
               console.log(error.response)
       });
     }

    getDataBalance = async ()=>{

      if(this.state.dataAccount.length > 0){
          this.state.dataAccount.forEach(account => {

              const targetUrlBalance = []
                  this.Months.map((item,i)=>(
                      targetUrlBalance.push(axios.get((serverUrl + '/src/records-prevbal?dtstart=' + item + '&account='+ account._id), {"headers" : headerRequest}))
                  ))

                  axios.all(targetUrlBalance)
                  .then(axios.spread( async (...responses) => {

                      let dataGraph = []

                      await responses.forEach(element => {

                          if(element.data.items[0] !== undefined){
                              dataGraph.push(Number(element.data.items[0].totalAmount + account.openBalance).toFixed(0))
                          }else{
                              dataGraph.push(Number(account.openBalance).toFixed(0))
                          }
                      });

                      await this.setState((prevState)=>({seriesAvailable: [...prevState.seriesAvailable,{name: account.name,data: dataGraph}]}))
                      this.processAvailable({name: account.name,data: dataGraph})

                  })).catch(errors => {
                  console.log(...errors)
              });
          })
      }
    }

    processDataIncExp = (dataItems) => {

      let testeR = []
      let testeD = []

      this.lastMounths.map((item,i)=>(
        dataItems.map((item2,i2)=>(
          item.number === item2._id.month
          && item.year === item2._id.year ?
              item2._id.type === 'R' ?
                  testeR.push({month: item2._id.month, year: item2._id.year, total: item2.total})
              :   testeD.push({month: item2._id.month, year: item2._id.year, total: (item2.total*-1)})
          : false
        ))

      ))

      let serieR = []
      let newSerieR = []
      this.lastMounths.map((item,i)=>(
          serieR.push(testeR.filter(obj => {
              return item.number === obj.month
                      && item.year === obj.year
            }))
      ))
      serieR.map((item,i)=>(
          item[0] !== undefined ?
          newSerieR.push(item[0].total.toFixed(0))
          :newSerieR.push(0)
      ))

      let serieD = []
      let newSerieD = []
      this.lastMounths.map((item,i)=>(
          serieD.push(testeD.filter(obj => {
              return item.number === obj.month
                      && item.year === obj.year
            }))
      ))
      serieD.map((item,i)=>(
          item[0] !== undefined ?
          newSerieD.push((item[0].total*-1).toFixed(0))
          :newSerieD.push(0)
      ))

      let profit = []

      newSerieR.map((item,i)=>(
        profit.push((item - newSerieD[i]).toFixed(0))
      ))

      this.setState({
          seriesIncExp: [{
              name: 'Receitas',
              data: newSerieR
          }, {
              name: 'Despesas',
              data: newSerieD
          }],
          seriesProfit: [{
            name: 'Lucro',
            data: profit
          }],
          profit:  parseFloat(profit[profit.length -1]).toFixed(0),
          incomes:  parseFloat(newSerieR[newSerieR.length -1] - newSerieR[newSerieR.length -2]).toFixed(0),
          expenses:  parseFloat(newSerieD[newSerieD.length -2] - newSerieD[newSerieD.length -1]).toFixed(0),
      })

    }

    processAvailable = (itemAvailable) => {

      let newData = this.state.seriesProfitTemp
      let count = this.state.count +1

      if(newData !== undefined && itemAvailable !== undefined){

        itemAvailable.data.map((num, i)=>(
          newData[i] = newData[i] > 0 ? (newData[i] + parseFloat(num)): parseFloat(num)
          ))
          this.setState({seriesProfit2: [{
            name: "Disponivél",
            data: newData
          }],
          seriesProfitTemp: newData,
          count: count,
          avaiable: ((newData[newData.length -1]/newData[0] - 1) * 100).toFixed(0) + '%'
        })
      }
    }

    handleChange = (event, newValue) => {
        this.setState({value: newValue});
    };

    render() {
        return (
          <>
            <Container>
                <Row className="TitleContainer">
                <Col style={{ padding: "10px"}}>
                    <h4><MDBIcon icon="donate" className="mr-2 ml-4" />Finanças</h4>
                </Col>
                <Col>
                    <RecordAdd />
                </Col>
              </Row>
            </Container>
            <Container className="MainContainer" style={{ backgroundColor: 'white', padding: '5px' }} >
                <Row>
                    <Col>
                        <Paper position="static" square>
                          <Tabs
                            value={this.state.value}
                            onChange={this.handleChange}
                            aria-label="simple tabs example"
                            variant="scrollable"
                          >
                            <Tab label="DashBoard" {...a11yProps(0)} />
                            <Tab label="Saldos" {...a11yProps(1)} />
                            <Tab label="Provisões" {...a11yProps(2)} />
                          </Tabs>
                        </Paper>
                        <TabPanel value={this.state.value} index={0}>
                            <Grid
                              container
                              spacing={3}
                              direction="row"
                              justify="center"
                              alignItems="center"
                            >
                              <Grid item xs={12}  md={6}>

                                <CardDeck>
                                  <Card >
                                    <Card.Body style={{textAlign: "center"}}>
                                      <Statistic  size='small'>
                                        <Statistic.Label>Var. Receita</Statistic.Label>
                                        <Statistic.Value>{this.state.incomes}<MDBIcon style={{ color: this.state.incomes < 0 ? "red" : "#68e496", marginLeft: "15px" }} icon={this.state.incomes < 0 ? "level-down-alt":"level-up-alt" }/></Statistic.Value>
                                      </Statistic>
                                    </Card.Body>
                                    <Card.Footer style={{ backgroundColor: this.state.incomes < 0 ? "red" : "#68e496", padding:"2px" }} />
                                  </Card>

                                  <Card >
                                    <Card.Body style={{textAlign: "center"}}>
                                      <Statistic  size='small'>
                                        <Statistic.Label>Var. Despesas</Statistic.Label>
                                        <Statistic.Value>{this.state.expenses}<MDBIcon style={{ color: this.state.expenses < 0 ? "red" : "#68e496", marginLeft: "15px" }} icon={this.state.expenses < 0 ? "level-down-alt":"level-up-alt" }/></Statistic.Value>
                                      </Statistic>
                                    </Card.Body>
                                    <Card.Footer style={{ backgroundColor: this.state.expenses < 0 ? "red" : "#68e496", padding:"2px" }} />
                                  </Card>
                                </CardDeck>

                              </Grid>
                              <Grid item xs={12}  md={6}>

                                <CardDeck>
                                  <Card>
                                    <Card.Body style={{textAlign: "center"}}>
                                      <Statistic  size='small'>
                                        <Statistic.Label><strong>Lucro</strong></Statistic.Label>
                                        <Statistic.Value>{this.state.profit}<MDBIcon style={{ color: this.state.profit < 0 ? "red" : "#68e496", marginLeft: "15px" }} icon={this.state.profit < 0 ? "level-down-alt":"level-up-alt" }/></Statistic.Value>
                                      </Statistic>
                                    </Card.Body>
                                    <Card.Footer style={{ backgroundColor: this.state.profit < 0 ? "red" : "#68e496", padding:"2px" }} />
                                  </Card>
                                  <Card>
                                    <Card.Body style={{textAlign: "center"}}>
                                      <Statistic  size='small'>
                                        <Statistic.Label>Var. Disponivél</Statistic.Label>
                                        <Statistic.Value>{this.state.avaiable}<MDBIcon style={{ color: this.state.avaiable < 0 ? "red" : "#68e496", marginLeft: "15px" }} icon={this.state.avaiable < 0 ? "level-down-alt":"level-up-alt" }/></Statistic.Value>
                                      </Statistic>
                                    </Card.Body>
                                    <Card.Footer style={{ backgroundColor: this.state.avaiable < 0 ? "red" : "#68e496", padding:"2px" }} />
                                  </Card>
                                </CardDeck>

                              </Grid>
                              <Grid item xs={12} md={6}>
                                {this.state.seriesIncExp.length >0 ?
                                 <IncomesExpensesLine
                                    series={this.state.seriesIncExp}
                                    lastMounths = {this.lastMounths}
                                 /> : false}
                              </Grid>
                              <Grid item xs={12} md={6}>
                                {this.state.seriesProfit.length >0
                                && this.state.count === this.state.dataAccount.length ?
                                <ProfitLine
                                  seriesProfit={this.state.seriesProfit}
                                  seriesProfit2={this.state.seriesProfit2}
                                  lastMounths = {this.lastMounths}
                                /> : false}
                              </Grid>
                              <Grid item xs={12} md={6}>
                                {this.state.seriesAvailable.length === this.state.dataAccount.length ?
                                  <AvailableColumn
                                    series={this.state.seriesAvailable}
                                    lastMounths = {this.lastMounths}
                                    /> : false}
                              </Grid>
                              <Grid item xs={12} md={6}>
                                <CategoryPie />
                              </Grid>
                            </Grid>

                        </TabPanel>
                        <TabPanel value={this.state.value} index={1}>
                            <BankBalance />
                        </TabPanel>
                        <TabPanel value={this.state.value} index={2}>
                            Provisões
                        </TabPanel>
                    </Col>
                </Row>
            </Container>
          </>
        );
      }
  }

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={3}>{children}</Box>}
    </Typography>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}
