import React, {Component} from 'react';
import axios from 'axios';
import CurrencyFormat from 'react-currency-format';

import { serverUrl, headerRequest } from '../../lib/funApp/general';

import { Container, Row, Col, Card } from 'react-bootstrap';
import { MDBIcon } from 'mdbreact';

const targetUrl = serverUrl + '/src/accounts';

export default class BankBalance extends Component {

  constructor(){
    super()
    this.state ={
      accounts: [],
      sunTotal: 0
    }
    this.dataAccounts =
      axios.get(targetUrl, {"headers" : headerRequest})
      .then(res => {
        if(res.status === 200){
          this.setState({ accounts:
            res.data.items.reduce(function (r, a) {
              r[a.type] = r[a.type] || [];
              r[a.type].push(a);
              return r;
            }, Object.create(null))
          })
        }
      }).catch(error => {
          console.log(error.response)
      });
    this.sumType = 0
    this.sumTotal = 0
    this.statusColor = 'red'
  }

  resetSumType(){
    this.sumType = 0
  }
  plusSumType(value){
    this.sumType = this.sumType + value
    this.sumTotal = this.sumTotal + value
  }


  render() {
    return (
        <Container  style={{ backgroundColor: 'white', minHeight: '75%',  padding: '12px' }} >
          <Row>
            <Col>
              <Card  style={{flexDirection: 'column', display:'flex'}}>
                {Object.keys(this.state.accounts).sort().map((key, k1) => (

                  <Container key={k1} className="mb-3" style={{ order:'1'}}>

                    {this.resetSumType()}
                      <Card style={{flexDirection: 'column', display:'flex' }}>
                        <Row style={{order:'2', padding: '7px'}}>
                          <Col>
                            <Container>
                              {this.state.accounts[key].map((account, k2) => (
                                <Row key={k2}>
                                  <Col className="text-center">
                                    {account.name}
                                  </Col>
                                  <Col className={account.balance < 0 ? 'text-right text-danger':'text-right'}>
                                    <CurrencyFormat
                                      value={account.balance}
                                      displayType={'text'}
                                      thousandSeparator='.'
                                      decimalSeparator=','
                                      decimalScale={2}
                                      fixedDecimalScale={true}
                                    />
                                    {this.plusSumType(account.balance)}
                                  </Col>
                                </Row>
                              ))}
                            </Container>
                          </Col>
                        </Row>

                        <Row style={{
                            borderLeft: `3px solid ${this.sumType < 0 ? 'red':'green'}`,
                            borderBottom: `3px solid ${this.sumType < 0 ? 'red':'green'}`,
                            backgroundColor: "#fff",
                            padding: '5px',
                            color:`${this.sumType < 0 ?'red':'green'}`,
                            order:'1'}}>
                            <Col><span><strong>{key}</strong></span>
                            <span className={this.sumType < 0 ? 'float-right mr-3 text-danger':'float-right mr-3'}>
                              <MDBIcon icon="chevron-left" className='mr-2' />
                              <CurrencyFormat
                                value={this.sumType}
                                displayType={'text'}
                                thousandSeparator='.'
                                decimalSeparator=','
                                decimalScale={2}
                                fixedDecimalScale={true}
                              />
                              <MDBIcon icon="chevron-right" className='ml-2' />
                              </span>
                            </Col>
                        </Row>
                      </Card>

                  </Container>
                ))}
                <Container style={{order:'2'}}>
                  <Row>
                    <Col className="mt-2 mb-2">
                      <Card>
                        <Card.Body>
                          <span className={this.sumType < 0 ? 'float-right text-danger':'float-right'}>
                            <span className='mr-3'><strong>{'Total ---->'} </strong></span>
                            <CurrencyFormat
                              value={this.sumTotal}
                              displayType={'text'}
                              thousandSeparator='.'
                              decimalSeparator=','
                              decimalScale={2}
                              fixedDecimalScale={true}
                            />
                            </span>
                        </Card.Body>
                      </Card>
                    </Col>
                  </Row>
                </Container>
              </Card>
            </Col>
          </Row>
        </Container>
    )
  }
}