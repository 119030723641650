import React, { Component } from 'react'
import axios from 'axios';

import { reactLocalStorage } from 'reactjs-localstorage';
import { toast } from 'react-toastify';

import { serverUrl , headerRequest, optTypeAccount } from '../../lib/funApp/general';

import { FormGroup, Modal, Card } from "react-bootstrap"
import { Button, Dropdown, Form as FormUI, Label, Icon } from 'semantic-ui-react';
import { Fab, TextField, Grid, } from '@material-ui/core';

import Loading from '../../containers/loading';
import { Edit as EditIcon } from '@material-ui/icons';

export default class AccountEdit extends Component {

  constructor(props){
    super()
    this.account = props.account
    this.targetUrl = serverUrl + '/src/accounts/' + props.account._id
    this.optError1 = false
    this.optError2 = false
    this.optTypeAccount = optTypeAccount  
    this.state ={
      show: false,
      loading: false,
      optCategories: props.category,
      optError1: false, optError2: false,
      name: props.account.name,
      type: props.account.type,
      bank: props.account.bank ? props.account.bank : '',
      number: props.account.number ? props.account.number : '',
      limit: props.account.limit ? props.account.limit : 0
    }
  }

  handleShow = () => this.setState((prevState) => ({ show: !prevState.show }));

  handleChange = (e, { value }) => this.setState({ type: value })

  validateSubmit = () => {
    if( this.state.name.length < 3 ){

      this.optError1 = true; 
      this.setState({ optError1: true })

      if( this.state.name.length > 0 ){
        toast.error('Nome deve ter mais de 3 Letras!!');
      }else{
        toast.error('Preencha o Nome!!');
      }

    }else{
      this.optError1 = false; 
      this.setState({ optError1: false, show: false, loading: true })
      this.handleSubmit()
    }
  }

  handleSubmit = (e) => {
    
    let params = {}

    params.name = this.state.name;
    params.type = this.state.type;
            
    if(this.state.bank){ params.bank = this.state.bank }
    if(this.state.number){ params.number = this.state.number }
    if(this.state.limit){ params.limit = this.state.limit }
    
    axios.patch(this.targetUrl, params, {"headers" : headerRequest})
      .then(res => {
        if(res.status === 200){
          reactLocalStorage.set('@result', 201); 
          setTimeout(() => {
            return window.location.reload(false);
          }, 2000);
        }
      }).catch(error => {
        if(error.response.data.message.includes('dup key: {')){
          this.setState({ show1: true, loading: false })
          toast.error('Conta já Cadastrada!!');
        }else{
          this.setState({ show1: true, loading: false })
          toast.error('Erro Inesperado!!');
          console.log('ERROR: ', error.response.status + ' [' + error.response.data.message +']');
        }
    });
}

  render(){
    return(
      <>
      <Fab className='m-1' size="small" onClick={this.handleShow} title="Editar Categoria" style={{ backgroundColor: ' #fb3', color: '#fff' }} >
        <EditIcon />
      </Fab>
      <Modal show={this.state.show} onHide={this.handleShow} centered size="lg"> 
        <Modal.Header closeButton>
          <Modal.Title><EditIcon fontSize="large" className="mr-2" />Editar Conta</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Card>
            <Card.Body>
                <Grid container spacing={3}>   
                    <Grid item xs={12} style={{ textAlign: 'right' }} >
                        <FormUI.Field inline>
                            <Label pointing='right' basic color='blue'>Tipo</Label>
                            <Dropdown
                                options={this.optTypeAccount}
                                search
                                selection
                                value={this.state.type}
                                onChange={this.handleChange}
                            />
                        </FormUI.Field>
                    </Grid>
                    <Grid item xs={12}>
                        <FormGroup controlId="name" bssize="large">
                            <TextField
                            error={this.state.optError1}
                            autoFocus
                            type="text"
                            value={this.state.name}
                            label="Nome *"
                            onChange={e => this.setState({ name: e.target.value})}
                            className="col-12"
                            />
                        </FormGroup>
                    </Grid>
                    <Grid item xs={6}>
                        <FormGroup controlId="limit" bssize="large">
                            <TextField
                            autoFocus
                            type="number"
                            value={this.state.limit}
                            label="Limite"
                            onChange={e => this.setState({ limit: e.target.value })}
                            className="col-12"
                            />
                        </FormGroup>
                    </Grid>
                    <Grid item xs={6}>
                        <FormGroup controlId="number" bssize="large">
                            <TextField
                            autoFocus
                            type="text"
                            value={this.state.number}
                            label="Número"
                            onChange={e => this.setState({ number: e.target.value })}
                            className="col-12"
                            />
                        </FormGroup>
                    </Grid>
                    <Grid item xs={12}>
                        <FormGroup controlId="bank" bssize="large">
                            <TextField
                            autoFocus
                            type="text"
                            value={this.state.bank}
                            label="Banco"
                            onChange={e => this.setState({ bank: e.target.value })}
                            className="col-12"
                            />
                        </FormGroup>
                    </Grid>
                </Grid>
            </Card.Body>
          </Card>
        </Modal.Body>
        <Modal.Footer>
          <Button.Group>
            <Button negative onClick={this.handleShow}>
              <Icon name='remove' /> Cancelar
            </Button>
            <Button.Or text='ou' />
            <Button positive basic={this.state.loading} loading={this.state.loading} onClick={this.validateSubmit} >
              <Icon name='checkmark' /> Salvar
            </Button>
          </Button.Group>
        </Modal.Footer>
      </Modal>
      { this.state.loading ? 
        <Loading />
      : false }
     </ > 
    )
  }
}
