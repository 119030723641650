import React, { Component } from 'react';
import axios from 'axios';
import parse from 'html-react-parser';

import { toast } from 'react-toastify';
import { reactLocalStorage } from 'reactjs-localstorage';

import { serverUrl, categoriesFilter, headerRequest } from '../../lib/funApp/general';

import { MDBIcon } from 'mdbreact';
import { Form, Card, FormGroup, Row, Col, Modal, Container } from "react-bootstrap";
import { Button, Label, Icon } from 'semantic-ui-react';
import { Grid, TextField, Dialog, DialogActions, DialogContent, Slide, Fab } from '@material-ui/core';
import { Edit as EditIcon } from '@material-ui/icons';
    
import Loading from  '../../containers/loading'; 

export default class CagororyEdit extends Component {

    constructor(props){
        super()        
        this.optError1 = false
        this.optError2 = false
        this.categories = props.categories
        this.expenses = JSON.stringify(categoriesFilter(props,'D')).replace(/\[]/g, "")
                          .replace(/\[/g, "<ul class='tree'>")
                          .replace(/\]/g, "</ul>")
                          .replace(/\{/g, "")
                          .replace(/\}/g, "")
                          .replace(/"/g, "") 
                          .replace(/id:/g, '<li><label><input type="radio" class="option-input radio" name="rads" value="')
                          .replace(/,child:/g, '</label></li>')
                          .replace(/,/g, "")
                          .replace(/name:/g, '">')    
        this.incomes = JSON.stringify(categoriesFilter(props,'R')).replace(/\[]/g, "")
                          .replace(/\[/g, "<ul class='tree'>")
                          .replace(/\]/g, "</ul>")
                          .replace(/\{/g, "")
                          .replace(/\}/g, "")
                          .replace(/"/g, "") 
                          .replace(/id:/g, '<li><label><input type="radio" class="option-input radio" name="rads" value="')
                          .replace(/,child:/g, '</label></li>')
                          .replace(/,/g, "")
                          .replace(/name:/g, '">')  
        this.Transition = React.forwardRef(function Transition(props, ref) {
                            return <Slide direction="up" ref={ref} {...props} />
                          })
        this.state ={
            show: false, 
            openDialog: false,
            optError1: false, optError2: false,
            loading: false,
            item: {},
            parent: {}
        }
    }

    handleDialog = () => {this.setState({ openDialog: !this.state.openDialog });};

    handleShow = (e) => {
      let radVal = document.mainForm.rads2.value
      if(radVal.length > 0){
        this.categories.map((item, index)=>(
            radVal === item._id ?
              this.setState({ item: item, show: !this.state.show }) +
              this.parentName( item )
            : false
        ))
      }else{toast.info('Selecione uma Categoria!!')}
    }

    parentName = (currentItem) => {
      if(currentItem.parent !== undefined){
          this.categories.map((item, index)=>(
              currentItem.parent === item._id ?
                  this.setState({ parent: item })
              : false
          ))
      }
    }  

    validateSubmit = () => {
        if( this.state.item.name.length <= 3 ){
  
          this.optError1 = true; 
          this.setState({ optError1: true })
  
          if( this.state.name.length > 0 ){
            toast.error('Nome deve ter mais de 3 Letras!!');
          }else{
            toast.error('Preencha o Nome!!');
          }
  
        }else{

          if( this.state.item._id !== this.state.parent._id ){

            this.optError1 = false; this.setState({ optError1: false })
            this.setState({ show: false, loading: true })
            this.handleSubmit()
          }else{
            toast.error('A Categoria não pode ser Sub da mesma!!');
          }
        }
    }
    handleSubCat = (e) => {

      let radVal = document.subEditForm.rads.value;

      if(radVal.length > 0){
          this.categories.map((item, index)=>(
            radVal === item._id ?
             this.setState({ parent: item })
            : false
        ))
      }
      this.handleDialog()
    }

    handleSubmit = (e) => {

      let targetUrl = serverUrl + '/src/categories/' + this.state.item._id;
      let params = {}

      params.name = this.state.item.name

      if(this.state.parent._id !== undefined ){ 
        params.parent = this.state.parent._id 
      }else{
        params.parent = null
      }
      
      axios.patch(targetUrl, params, {"headers" : headerRequest})
        .then(res => {
          if(res.status === 200){
            reactLocalStorage.set('@result', 201); 
            setTimeout(() => {
              return window.location.reload(false);
            }, 2000);
          }
        }).catch(error => {
          if(error.response.data.message.includes('dup key: {')){
              this.setState({ show: true, loading: false })
              toast.error('Categoria já Cadastrada!!');
          }else{
            this.setState({ show: true, loading: false })
            toast.error('Erro Inesperado!!');
            console.log('ERROR: ', error.response.status + ' [' + error.response.data.message +']');
          }  
        });
    }

    render() {
        return(
            <>
            <Fab className='m-2' size="small" onClick={this.handleShow} title="Editar Categoria" style={{ float: 'right', backgroundColor: ' #fb3', color: '#fff' }} >
              <EditIcon />
            </Fab>
            <Modal show={this.state.show} onHide={this.handleShow} centered size="lg">  
              <Modal.Header closeButton>
                <Modal.Title><EditIcon style={{ fontSize: 30, marginRight: '5px' }}/>Editar Categoria</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Card>
                  <Card.Body>
                    <Grid container spacing={3}>     
                      <Grid item xs={12}>
                          <FormGroup controlId="name" bssize="large" style={{marginBottom: "25px"}}>
                              <TextField
                                  error={this.state.optError1}
                                  autoFocus
                                  type="text"
                                  value={this.state.item.name}
                                  label="Nome *"
                                  onChange={e => {
                                    let newItem = this.state.item
                                    newItem.name = e.target.value
                                    this.setState({ item: newItem })
                                  }}
                                  className="col-12"
                              />
                          </FormGroup>
                      </Grid>
                      <Grid item xs={12}>
                        <Button as='div' labelPosition='right' onClick={this.handleDialog}>
                          <Button color={this.state.item.type === 'D' ? 'red' : 'blue'} primary>
                            <Icon name='sitemap' />
                            Sub-Categoria de 
                          </Button>
                          <Label as='a' basic color={this.state.item.type === 'D' ? 'red' : 'blue'} pointing='left'>
                            {this.state.parent.name !== undefined ?  this.state.parent.name : '...'}
                          </Label>
                        </Button>
                        {this.state.parent.name !== undefined ?
                          <Label as='a' tag onClick={e => this.setState({ parent: {} })} color={this.state.item.type === 'D' ? 'red' : 'blue'}>
                            <Icon name='trash alternate outline' className='m-0'/>
                          </Label>
                        : false }
                      </Grid>
                    </Grid>
                  </Card.Body>
                </Card>

                <Dialog
                  open={this.state.openDialog}
                  TransitionComponent={this.Transition}
                  keepMounted
                  onClose={this.UNSAFE_componentWillReceivePropshandleDialog}
                  aria-labelledby="alert-dialog-slide-title"
                  aria-describedby="alert-dialog-slide-description"
                >
                  <DialogContent>
                    
                  <Form onSubmit={this.handleSubCat} method="get" id="subEditForm" name="subEditForm">
                    <Container>
                      <Row>
                        <Col>   
                        {this.state.item.type === 'D' ?
                          <>  
                            <div className="mb-2 text-danger">
                              <MDBIcon icon="plane-arrival" size="lg"/> <strong>Despesas</strong>
                            </div>
                            <div className="clt">
                              { parse(this.expenses)}
                            </div>
                          </>
                        :
                          <>
                            <div className="mb-2 text-primary">
                              <MDBIcon icon="plane-departure" size="lg"/> <strong>Receitas</strong>
                            </div>
                            <div className="clt">
                            { parse(this.incomes) }
                            </div>
                          </>
                        }
                        </Col>
                      </Row>
                    </Container>
                  </Form>
                    
                  </DialogContent>
                  <DialogActions  id='ModalActionFooter'>
                    <Button.Group>
                      <Button negative onClick={this.handleDialog}>
                        <Icon name='remove' /> Cancelar
                      </Button>
                      <Button.Or text='ou' />
                      <Button positive onClick={this.handleSubCat}>
                        <Icon name='checkmark' /> Escolher
                      </Button>
                    </Button.Group>
                  </DialogActions>
                </Dialog>        

              </Modal.Body>
              <Modal.Footer>
                <Button.Group>
                  <Button negative onClick={this.handleShow}>
                    <Icon name='remove' /> Cancelar
                  </Button>
                  <Button.Or text='ou' />
                  <Button positive basic={this.state.loading} loading={this.state.loading} onClick={this.validateSubmit}>
                    <Icon name='checkmark' /> Salvar
                  </Button>
                </Button.Group>
              </Modal.Footer>
            </Modal>
            { this.state.loading ? 
              <Loading main={true} />
            : false}
            </>
        )
    }
}