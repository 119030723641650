import React, {Component} from 'react'
import axios from 'axios';  
import * as moment from 'moment'

import { toast } from 'react-toastify';
import CurrencyFormat from 'react-currency-format';
import { reactLocalStorage } from 'reactjs-localstorage';

import { serverUrl , headerRequest, optTypeAccount } from '../../lib/funApp/general'

import { Form, Card, FormGroup, Modal, Row, Col } from "react-bootstrap";
import { SpeedDial, SpeedDialIcon, SpeedDialAction } from '@material-ui/lab';
import { Button, Dropdown, Form as FormUI, Label, Icon } from 'semantic-ui-react';
import { Grid, TextField, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide, IconButton } from '@material-ui/core';
import { AddToPhotos, 
    FormatIndentDecrease as FormatIndentDecreaseIcon, 
    Clear as ClearIcon, Search as SearchIcon, Build, Close } from '@material-ui/icons';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider,  KeyboardDatePicker } from '@material-ui/pickers';    

import Loading from  '../../containers/loading';  
import AccountEdit from './accountEdit';
import AccountDelete from './accountDelete';


const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

export default class AccountDial extends Component{

    constructor(){
        super()      
        this.targetUrl = serverUrl + '/src/accounts'
        this.targetUrlSrc = serverUrl + '/src/accounts?name='
        this.optError1 = false
        this.optError2 = false
        this.optTypeAccount = optTypeAccount  
        this.state ={
            show1: JSON.parse(sessionStorage.getItem('@md_Account')), 
            show2: false, 
            open: false, hidden: false,
            optError1: false, optError2: false,
            loading: false,
            openDialog: false,
            name: "",
            type: "Conta Corrente",
            bank: "",
            number: "",
            openDate: new Date(),
            limit: 0,
            openBalance: 0,
            keySrc: '',
            getResult: []
        }
    }

    handleOpen = () => this.setState({ open: !this.state.open });
    handleShowAdd = () => {
      this.setState({ show1: !this.state.show1 }); 
      sessionStorage.setItem('@md_Account', !this.state.show1)
    };
    handleShowSrc = () => this.setState({ show2: !this.state.show2 });

    handleCloseDialog = () => this.setState({ openDialog: false });

    /* Form */
    handleChange = (e, { value }) => this.setState({ type: value })

    handleDateChange = date => {
        this.setState({ openDate: date });
    };

    validateSubmit = () => {
        if( this.state.name.length <= 3 ){
  
          this.optError1 = true; 
          this.setState({ optError1: true })
  
          if( this.state.name.length > 0 ){
            toast.error('Nome deve ter mais de 3 Letras!!');
          }else{
            toast.error('Preencha o Nome!!');
          }
        }else{this.optError1 = false; this.setState({ optError1: false })}

        if( !moment(this.state.openDate).isValid() ){
  
            this.optError2 = true; 
            this.setState({ optError2: true })

            if( this.state.openDate !== null ){
                toast.error('Data Inválida!!');
            }else{
                toast.error('Informe a Data!!');
            }
        }else{this.optError2 = false; this.setState({ optError2: false })}

        if( this.optError1 === false && this.optError2 === false ){
            this.setState({ openDialog: true })
        }
    }

    handleSubmit = (e) => {
        
        this.setState({ show1: false, loading: true, openDialog: false })
        
        let params = {}
  
        params.name = this.state.name;
        params.type = this.state.type;
        params.openDate = moment(this.state.openDate).format('YYYY-MM-DD') + 'T12:59-0500'
                
        if(this.state.bank){ params.bank = this.state.bank }
        if(this.state.number){ params.number = this.state.number }
        if(this.state.limit){ params.limit = this.state.limit }
        if(this.state.openBalance){ 
            params.openBalance = this.state.openBalance; 
            params.balance = this.state.openBalance 
        };

        axios.post(this.targetUrl, params, {"headers" : headerRequest})
          .then(res => {
            if(res.status === 200){
              reactLocalStorage.set('@result', 200); 
              setTimeout(() => {
                return window.location.reload(false);
              }, 2000);
            }
          }).catch(error => {
            if(error.response.data.message.includes('dup key: {')){
              this.setState({ show1: true, loading: false })
              toast.error('Conta já Cadastrada!!');
            }else{
              this.setState({ show1: true, loading: false })
              toast.error('Erro Inesperado!!');
              console.log('ERROR: ', error.response.status + ' [' + error.response.data.message +']');
            }
        });
    }

    searchKey(value){

        let urlSrc = this.targetUrlSrc + value;
        this.setState({ keySrc: value });
        this.setState({ getResult: [] }) 
  
        if(value.length > 0){
          axios.get( urlSrc, { "headers" : headerRequest })
          .then(res => {
              if(res.status === 200){
                  this.setState({ getResult: res.data.items })
                }
              }).catch(error => {
                  toast.error('Erro Inesperado!!');
                  console.log('ERROR: ', error.response.status + ' [' + error.response.data.message +']')
              }
          ) 
        }
      }

    render(){
        return(
            <div>
              <div>
                <SpeedDial
                    ariaLabel="SpeedDial tooltip People"
                    hidden={this.state.hidden}
                    icon={<SpeedDialIcon icon={<FormatIndentDecreaseIcon style={{ fontSize: 25 }}/>} openIcon={<ClearIcon  style={{ fontSize: 25 }}/>}/>}
                    onClose={this.handleOpen}
                    onOpen={this.handleOpen}
                    open={this.state.open}
                    direction='left'
                >
                  <SpeedDialAction
                      key='CadastroPessoa'
                      icon={<AddToPhotos />}
                      tooltipTitle='Adicionar'
                      onClick={this.handleShowAdd}
                  />
                  <SpeedDialAction
                      key='PesquisarExercicio'
                      icon={<SearchIcon />}
                      tooltipTitle='Pesquisar'
                      onClick={this.handleShowSrc} 
                  />
                </SpeedDial>
            </div>
            <div>
                <Modal show={this.state.show1} onHide={this.handleShowAdd} centered size="lg">  
                    <Modal.Header closeButton>
                        <Modal.Title><AddToPhotos style={{ fontSize: 30, marginRight: '5px' }}/>Cadastro de Contas</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div className="d-none">
                      { this.state.show1 ? 
                          setTimeout(
                              function() {
                                  document.getElementById("nameText").focus();
                              },
                              500
                              )
                          :false
                      }</div>
                        <Form>
                            <Card>
                                <Card.Body>
                                    <Grid container spacing={3}>   
                                        <Grid item xs={12} style={{ textAlign: 'right' }} >
                                            <FormUI.Field inline>
                                                <Label pointing='right' basic color='blue'>Tipo</Label>
                                                <Dropdown
                                                    options={this.optTypeAccount}
                                                    search
                                                    selection
                                                    value={this.state.type}
                                                    onChange={this.handleChange}
                                                />
                                            </FormUI.Field>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FormGroup controlId="name" bssize="large">
                                                <TextField
                                                error={this.state.optError1}
                                                id="nameText"
                                                type="text"
                                                value={this.state.name}
                                                label="Nome *"
                                                onChange={e => this.setState({ name: e.target.value})}
                                                className="col-12"
                                                />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item xs={6} md={3}>
                                            <Form.Group controlId="dateStart">
                                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                                    <KeyboardDatePicker
                                                    error={this.state.optError2}
                                                    id="date-picker-dialog"
                                                    label="Data Abertura *"
                                                    format="dd/MM/yyyy"
                                                    value={this.state.openDate}
                                                    onChange={this.handleDateChange}
                                                    helperText=''
                                                    KeyboardButtonProps={{
                                                        'aria-label': 'Alterar Data',
                                                    }}
                                                    />
                                                </MuiPickersUtilsProvider>
                                            </Form.Group>
                                            </Grid>
                                        <Grid item xs={6} md={3}>
                                            <FormGroup controlId="openBalance" bssize="large">
                                                <TextField
                                                type="number"
                                                value={this.state.openBalance}
                                                label="Saldo Abertura"
                                                onChange={e => this.setState({ openBalance: e.target.value })}
                                                className="col-12"
                                                />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item xs={6} md={3}>
                                            <FormGroup controlId="limit" bssize="large">
                                                <TextField
                                                type="number"
                                                value={this.state.limit}
                                                label="Limite"
                                                onChange={e => this.setState({ limit: e.target.value })}
                                                className="col-12"
                                                />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item xs={6} md={3}>
                                            <FormGroup controlId="number" bssize="large">
                                                <TextField
                                                type="text"
                                                value={this.state.number}
                                                label="Número"
                                                onChange={e => this.setState({ number: e.target.value })}
                                                className="col-12"
                                                />
                                            </FormGroup>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <FormGroup controlId="bank" bssize="large">
                                                <TextField
                                                type="text"
                                                value={this.state.bank}
                                                label="Banco"
                                                onChange={e => this.setState({ bank: e.target.value })}
                                                className="col-12"
                                                />
                                            </FormGroup>
                                        </Grid>
                                    </Grid>
                                </Card.Body>
                            </Card>
                        </Form>
                        <Dialog
                            open={this.state.openDialog}
                            TransitionComponent={Transition}
                            keepMounted
                            onClose={this.handleCloseDialog}
                            aria-labelledby="alert-dialog-slide-title"
                            aria-describedby="alert-dialog-slide-description"
                        >
                            <DialogTitle id="alert-dialog-slide-title">{"ATENÇÃO!!!"}</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-slide-description">
                                    <p>Ao Gravar a conta os campos <strong>Data de Abertura</strong> e <strong>Saldo de Abertura</strong> não poderão ser editados!!!
                                    <br />
                                    Tenha certeza que as informações estão corretas antes de movimentar a Conta!!</p>
                                    <p>Deseja Salvar as informações?</p>
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button basic color='red' onClick={this.handleCloseDialog}>
                                    <Icon name='remove' /> Cancelar
                                </Button>
                                <Button color='green' onClick={this.handleSubmit}>
                                    <Icon name='checkmark' /> Continuar
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button.Group>
                            <Button negative onClick={this.handleShowAdd}>
                                <Icon name='remove' /> Cancelar
                            </Button>
                            <Button.Or text='ou' />
                            <Button positive onClick={this.validateSubmit}>
                                <Icon name='checkmark' /> Salvar
                            </Button>
                        </Button.Group>
                    </Modal.Footer>
                </Modal>
                <Modal show={this.state.show2} onHide={this.handleShowSrc} centered size="lg">  
                <Modal.Header closeButton>
                  <Modal.Title><SearchIcon style={{ fontSize: 30, marginRight: '5px' }}/>Pesquisa de Contas</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="d-none">
                    { this.state.show2 ? 
                        setTimeout(
                            function() {
                                document.getElementById("myText").focus();
                            },
                            500
                            )
                        :false
                    }</div>
                  <Row>
                    <Col>
                      <FormGroup controlId="name" bssize="large" style={{marginBottom: "25px"}}>
                          <TextField
                            id="myText"
                            type="text"
                            value={this.state.keySrc}
                            label="Nome"
                            onChange={e => this.searchKey(e.target.value)}
                            className="col-12" 
                          />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                      <Col>
                          <Card>
                              <Card.Body style={{ paddingTop: '5px !important' }}>
                              {this.state.getResult.length > 0 ?
                                  this.state.getResult.map((account, index)=> (
                                    <AccountOptionsSrc account={account} key={index}/>
                                  ))
                              :<h4 className="text-center">Sem Resultados</h4>
                              }
                              </Card.Body>
                          </Card>
                      </Col>
                  </Row>
                </Modal.Body>
                <Modal.Footer>
                  <Button.Group>
                    <Button negative onClick={this.handleShowSrc}>
                      <Icon name='remove' /> Fechar
                    </Button>
                  </Button.Group>
                </Modal.Footer>
              </Modal>
            </div>
            {this.state.loading ? 
              <Loading main={true} />
            : false}
          </div>
        )
    }
}

class AccountOptionsSrc extends Component{

  constructor(props){
    super()
    this.account = props.account
    this.index = props.index
    this.state = {
      houver: false
    }
  }

  toogleHouver = () => {this.setState({ houver: !this.state.houver })};

  render(){
    return(
      <Row key={this.index} className="mb-1"style={{borderBottom: "inset", paddingBottom: "5px"}}>
        <Col sm={12} md={6}>
          <Row> 
            <Col>
              <span style={{ marginLeft: "10px" }}>{this.account.name}</span>
            </Col>
          </Row>
        </Col>
        {!this.state.houver ? 
          <Col sm={12} md={6}>
            <CurrencyFormat 
                value={this.account.balance} 
                displayType={'text'} 
                thousandSeparator='.' 
                decimalSeparator=','
                decimalScale={2}
                fixedDecimalScale={true}
                prefix='R$ '
            />
            <IconButton onClick={this.toogleHouver} title="Abrir Opções" style={{ float: 'right', backgroundColor: '#60a4ffbd', color: '#fff' }} >
              {!this.state.houver ? <Build fontSize='medium'/> : <Close fontSize='medium'/> }
            </IconButton>
          </Col>
        :
          <Col sm={12} md={6} className="text-right align-content-center d-flex"  style={{ flexDirection: 'row-reverse'}}>
            <IconButton onClick={this.toogleHouver} title="Fechar Opções" style={{ float: 'right' }} >
              {!this.state.houver ? <Build fontSize='medium'/> : <Close fontSize='medium'/>}
            </IconButton>
            <span className='m-2'></span> 
            <AccountDelete account={this.account} />                            
            <span className='m-2'></span> 
            <AccountEdit account={this.account}/>
          </Col>
        }

      </Row>
    )
  }
}