import React, {Component} from 'react'
import axios from 'axios';  

import { reactLocalStorage } from 'reactjs-localstorage';
import { toast } from 'react-toastify';

import { serverUrl , headerRequest, optMuscleGroup } from '../../lib/funApp/general'

import { Form, Card, FormGroup, Modal, Row, Col } from "react-bootstrap";
import { Button, Dropdown, Form as FormUI, Label, Icon } from 'semantic-ui-react';
import { SpeedDial, SpeedDialIcon, SpeedDialAction } from '@material-ui/lab';
import { Grid, TextField, IconButton } from '@material-ui/core';
import { AddToPhotos, FormatIndentDecrease as FormatIndentDecreaseIcon, 
        Clear as ClearIcon, Search as SearchIcon, Build, Close } from '@material-ui/icons';

import Loading from  '../../containers/loading';
import ExerciseEdit from './exerciseEdit';
import ExerciseDelete from './exerciseDelete';

export default class ExerciseDial extends Component{

    constructor(){
        super()      
        this.targetUrl = serverUrl + '/src/exercises'
        this.targetUrlSrc = serverUrl + '/src/exercises?name='
        this.optError1 = false
        this.optError2 = false
        this.optMuscleGroup = optMuscleGroup  
        this.state ={
            show1: JSON.parse(sessionStorage.getItem('@md_Exercise')), 
            show2: false, 
            open: false, hidden: false,
            optError1: false, optError2: false,
            loading: false,
            openDialog: false,
            name: "",
            muscleGroup: "",
            keySrc: '',
            getResult: []
        }
    }

    handleOpen = () => this.setState({ open: !this.state.open });
    handleShowAdd = () => {
      this.setState({ show1: !this.state.show1 }); 
      sessionStorage.setItem('@md_Exercise', !this.state.show1)
    };
    handleShowSrc = () => this.setState({ show2: !this.state.show2 });

    /* Form */
    handleChange = (e, { value }) => this.setState({ muscleGroup: value })

    validateSubmit = () => {
        if( this.state.name.length <= 3 ){
  
          this.optError1 = true; 
          this.setState({ optError1: true });
  
          if( this.state.name.length > 0 ){
            toast.error('Nome deve ter mais de 3 Letras!!');
          }else{
            toast.error('Preencha o Nome!!');
          }
        }else{this.optError1 = false; this.setState({ optError1: false })}

        if( this.state.muscleGroup.length < 1 ){
  
            this.optError2 = true; 
            this.setState({ optError2: true });
            toast.error('Informe o Grupo Muscular!!');            

          }else{this.optError2 = false; this.setState({ optError2: false })}

        if( this.optError1 === false && this.optError2 === false ){
            
            this.setState({ show1: false, loading: true });
            this.handleSubmit();
        }
    }

    handleSubmit = (e) => {
        
        let params = {};
  
        params.name = this.state.name;
        params.muscleGroup = this.state.muscleGroup;

        axios.post(this.targetUrl, params, {"headers" : headerRequest})
          .then(res => {
            if(res.status === 200){
              reactLocalStorage.set('@result', 200); 
              setTimeout(() => {
                return window.location.reload(false);
              }, 2000);
            }
          }).catch(error => {
            if(error.response.data.message.includes('dup key: {')){
              this.setState({ show1: true, loading: false })
              toast.error('Exercício já Cadastrado!!');
            }else{
              this.setState({ show1: true, loading: false })
              toast.error('Erro Inesperado!!');
              console.log('ERROR: ', error.response.status + ' [' + error.response.data.message +']');
            }
        });
    }

    searchKey(value){

      let urlSrc = this.targetUrlSrc + value;
      this.setState({ keySrc: value });
      this.setState({ getResult: [] }) 

      if(value.length > 0){
        axios.get( urlSrc, { "headers" : headerRequest })
        .then(res => {
            if(res.status === 200){
                this.setState({ getResult: res.data.items })
              }
            }).catch(error => {
                toast.error('Erro Inesperado!!');
                console.log('ERROR: ', error.response.status + ' [' + error.response.data.message +']')
            }
        ) 
      }
    }

    render(){
        return(
            <div>
              <div>
                <SpeedDial
                    ariaLabel="SpeedDial tooltip Exercise"
                    hidden={this.state.hidden}
                    icon={<SpeedDialIcon icon={<FormatIndentDecreaseIcon style={{ fontSize: 25 }}/>} openIcon={<ClearIcon  style={{ fontSize: 25 }}/>}/>}
                    onClose={this.handleOpen}
                    onOpen={this.handleOpen}
                    open={this.state.open}
                    direction='left'
                >
                  <SpeedDialAction
                      key='CadastroExercicio'
                      icon={<AddToPhotos />}
                      tooltipTitle='Adicionar'
                      onClick={this.handleShowAdd}
                  />
                  <SpeedDialAction
                      key='PesquisarExercicio'
                      icon={<SearchIcon />}
                      tooltipTitle='Pesquisar'
                      onClick={this.handleShowSrc}
                  />
                </SpeedDial>
            </div>
            <div>
                <Modal show={this.state.show1} onHide={this.handleShowAdd} centered>  
                    <Modal.Header closeButton>
                        <Modal.Title><AddToPhotos style={{ fontSize: 30, marginRight: '5px' }}/>Cadastro de Exercicios</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div className="d-none">
                      { this.state.show1 ? 
                          setTimeout(
                              function() {
                                  document.getElementById("nameText").focus();
                              },
                              500
                              )
                          :false
                      }</div>
                      <Form>
                        <Card>
                          <Card.Body>
                            <Grid container spacing={3}>   
                              <Grid item xs={12}>
                                <FormGroup controlId="name" bssize="large">
                                  <TextField
                                    error={this.state.optError1}
                                    id="nameText"
                                    type="text"
                                    value={this.state.name}
                                    label="Nome *"
                                    onChange={e => this.setState({ name: e.target.value})}
                                    className="col-12"
                                  />
                                </FormGroup>
                              </Grid>
                              <Grid item xs={12} >
                                <FormUI.Field inline>
                                  <Label pointing='below' basic color={this.state.optError2 ? 'red' : 'blue'}>Grupo Muscular</Label>
                                  <Dropdown
                                    error={this.state.optError2}
                                    options={this.optMuscleGroup}
                                    search
                                    fluid
                                    selection
                                    value={this.state.muscleGroup}
                                    onChange={this.handleChange}
                                  />
                                </FormUI.Field>
                              </Grid>
                            </Grid>
                          </Card.Body>
                        </Card>
                      </Form>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button.Group>
                        <Button negative onClick={this.handleShowAdd}>
                          <Icon name='remove' /> Cancelar
                        </Button>
                        <Button.Or text='ou' />
                        <Button positive onClick={this.validateSubmit}>
                          <Icon name='checkmark' /> Salvar
                        </Button>
                      </Button.Group>
                    </Modal.Footer>
                </Modal>
                <Modal show={this.state.show2} onHide={this.handleShowSrc} centered size="lg">  
                <Modal.Header closeButton>
                  <Modal.Title><SearchIcon style={{ fontSize: 30, marginRight: '5px' }}/>Pesquisa de Exercicios</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <div className="d-none">
                    { this.state.show2 ? 
                        setTimeout(
                            function() {
                                document.getElementById("myText").focus();
                            },
                            500
                            )
                        :false
                    }</div>
                  <Row>
                    <Col>
                      <FormGroup controlId="name" bssize="large" style={{marginBottom: "25px"}}>
                          <TextField
                            autoFocus={true} 
                            id="myText"
                            type="text"
                            value={this.state.keySrc}
                            label="Nome"
                            onChange={e => this.searchKey(e.target.value)}
                            className="col-12" 
                          />
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                      <Col>
                          <Card>
                              <Card.Body style={{ paddingTop: '5px !important' }}>
                              {this.state.getResult.length > 0 ?
                                  this.state.getResult.map((exercise, index)=> (
                                    <ExerciseOptionsSrc exercise={exercise} key={index}/>
                                  ))
                              :<h4 className="text-center">Sem Resultados</h4>
                              }
                              </Card.Body>
                          </Card>
                      </Col>
                  </Row>
                </Modal.Body>
                <Modal.Footer>
                  <Button.Group>
                    <Button negative onClick={this.handleShowSrc}>
                      <Icon name='remove' /> Fechar
                    </Button>
                  </Button.Group>
                </Modal.Footer>
              </Modal>
            </div>
            {this.state.loading ? 
              <Loading main={true} />
            : false}
          </div>
        )
    }
}

class ExerciseOptionsSrc extends Component{

  constructor(props){
    super()
    this.exercise = props.exercise
    this.index = props.index
    this.state = {
      houver: false
    }
  }

  toogleHouver = () => {this.setState({ houver: !this.state.houver })};

  render(){
    return(
      <Row key={this.index} className="mb-1"style={{borderBottom: "inset", paddingBottom: "5px"}}>
        <Col sm={12} md={6}>
          <Row> 
            <Col>
              <span style={{ marginLeft: "10px" }}>{this.exercise.name}</span>
            </Col>
          </Row>
        </Col>
        {!this.state.houver ? 
          <Col sm={12} md={6}>
            {this.exercise.muscleGroup}
            <IconButton onClick={this.toogleHouver} title="Abrir Opções" style={{ float: 'right', backgroundColor: '#60a4ffbd', color: '#fff' }} >
              {!this.state.houver ? <Build fontSize='medium'/> : <Close fontSize='medium'/> }
            </IconButton>
          </Col>
        :
          <Col sm={12} md={6} className="text-right align-content-center d-flex"  style={{ flexDirection: 'row-reverse'}}>
            <IconButton onClick={this.toogleHouver} title="Fechar Opções" style={{ float: 'right' }} >
              {!this.state.houver ? <Build fontSize='medium'/> : <Close fontSize='medium'/>}
            </IconButton>
            <span className='m-2'></span> 
            <ExerciseDelete exercise={this.exercise} />                            
            <span className='m-2'></span> 
            <ExerciseEdit exercise={this.exercise}/>
          </Col>
        }

      </Row>
    )
  }
}