import React, { Component } from 'react';
import AccountsRender from '../components/accounts/accounts';
import { RequestInfoGet, pageSrc, serverUrl } from '../lib/funApp/general'

let targetUrl = '/src/accounts'

if(pageSrc.includes(targetUrl)){
  targetUrl = serverUrl + pageSrc
}else{
  targetUrl = serverUrl + targetUrl
}

class AccountsGet extends Component {

  state = {
    accounts: [],
    links: {}
  }

  componentDidMount() {
    fetch(targetUrl ,RequestInfoGet)
    .then(res => res.json())
    .then((data) => {
      this.setState({ links: data._links })
      this.setState({ accounts: data.items })
    })
    .catch()
  }

  render() {
    return (
      <>
      {this.state.accounts[0] !== undefined ?
        <AccountsRender accounts={[this.state.accounts,this.state.links]} /> 
      :false
      }
      </>
    )
  }

}

export default AccountsGet;

/**/