import React, {Component } from 'react'
import axios from 'axios';

import Moment from 'react-moment';
import { reactLocalStorage } from 'reactjs-localstorage';
import { toast } from 'react-toastify';

import { serverUrl , headerRequest } from '../../lib/funApp/general';

import { Modal,} from "react-bootstrap";
import { Button, Card, Icon } from 'semantic-ui-react';
import { Fab } from '@material-ui/core';
import { DeleteForever } from '@material-ui/icons';

import Loading from '../../containers/loading';

export default class WorkoutDelete extends Component{

  constructor(props){
    super()
    this.workout = props.workout
    this.targetUrl = serverUrl + '/src/workouts/' + props.workout._id
    this.state ={
      show: false,
      loading: false
    }
  }

  handleShow = () => this.setState({ show: !this.state.show });

  handleSubmit = () => {

    this.setState({ show: false, loading: true })

    axios.delete(this.targetUrl, {"headers" : headerRequest})
      .then(res => {
        if(res.status === 204){
          reactLocalStorage.set('@result', 206);
          setTimeout(() => {
            return window.location.reload(false);
          }, 2000);
        }
      }).catch(error => {
          this.setState({ show: true, loading: false })
          toast.error('Erro Inesperado!!');
          console.log('ERROR: ', error.response.status + ' [' + error.response.data.message +']');
        }
      );
  }

  render(){
    return(
      <>
      <Fab color="secondary" size="small" onClick={this.handleShow} title="Deletar Microciclo">
        <DeleteForever />
      </Fab>
      <Modal size="sm" show={this.state.show} onHide={this.handleShow} centered style={{backgroundColor: 'transparent !important' }}>

          <Card style={{ width: '100%' }}>
            <div className='m-3 text-center text-danger'>
              <h5><DeleteForever fontSize="large" className="mr-2" />Deseja Excluir o Microciclo?</h5>
            </div>
            <Card.Content>
              <Card.Meta className='m-2 text-center'>
                <span className='date'>
                  <strong>Data de Inicio: </strong>
                  <Moment format="DD/MM/YYYY" style={{marginLeft: "5px"}}>
                    <span className="Rotulo">{this.workout.dateStart}</span>
                  </Moment>
                </span>
              </Card.Meta>
              <Card.Description className='m-4'>
                <p><strong>Microciclo: </strong>{this.workout.microcycle}</p>
                <p><strong>Mesociclo: </strong>{this.workout.mesocycle}</p>
                <p><strong>Método: </strong>{this.workout.method}</p>
              </Card.Description>
            </Card.Content>
            <Card.Content extra className='text-center'>
              <Button.Group>
                <Button negative onClick={this.handleShow}>
                  <Icon name='remove' />Cancelar
                </Button>
                <Button.Or text='ou' />
                <Button positive basic={this.state.loading} loading={this.state.loading} onClick={this.handleSubmit} >
                  <Icon name='checkmark' />Excluir
                </Button>
              </Button.Group>
            </Card.Content>
          </Card>
      </Modal>
      { this.state.loading ?
        <Loading />
      : false }
     </ >
    )
  }
}