import React, { Component } from 'react'
import axios from 'axios';

import { reactLocalStorage } from 'reactjs-localstorage';
import { toast, ToastContainer } from 'react-toastify';

import { serverUrl , headerRequest } from '../../lib/funApp/general';
import { cpfMask, cnpjMask, foneMask } from '../../lib/funApp/mask';
import Loading from '../../containers/loading';

import { Form, FormGroup, Modal, Card } from "react-bootstrap"
import { Button, Dropdown, Form as FormUI, Label, Icon } from 'semantic-ui-react';
import { Fab, TextField, FormControl, Grid, Radio, RadioGroup, FormControlLabel } from '@material-ui/core';
import { Edit as EditIcon } from '@material-ui/icons';

export default class PeopleEdit extends Component {

  constructor(props){
    super()
    this.targetUrl = serverUrl + '/src/peoples/' + props.people._id
    this.optError1 = false
    this.optError2 = false
    this.state ={
      show: false,
      loading: false,
      optCategories: props.category,
      optError1: false, optError2: false,
      name: props.people.name,
      type: props.people.type,
      categories: props.people.category,
      cpf: props.people.cpf ? props.people.cpf : '',
      cnpj: props.people.cnpj ? props.people.cnpj : '',
      fone: props.people.fone ? props.people.fone : '',
      city: props.people.city ? props.people.city : '',
      obs: props.people.obs ? props.people.obs : '',
    }
  }

  handleShow = () => this.setState((prevState) => ({ show: !prevState.show }));

  /* Form */
  handleChangeRadio = event => {
    this.setState({type: event.target.value});
    event.target.value === 'J' ? this.setState({ Cpf:'' }) : this.setState({ Cnpj:'' })
  };

  handleAddition = (e, { value }) => {
    this.setState((prevState) => ({
      optCategories: [{ text: value, value }, ...prevState.optCategories],
    }))
  }

  handleChange = (e, { value }) => this.setState({ categories: value })

  validateSubmit = () => {
    if( this.state.name.length < 3 ){

      this.optError1 = true; 
      this.setState({ optError1: true })

      if( this.state.name.length > 0 ){
        toast.error('Nome deve ter mais de 3 Letras!!');
      }else{
        toast.error('Preencha o Nome!!');
      }

    }else{this.optError1 = false; this.setState({ optError1: false })}

    if( this.state.categories.length === 0 ){

      this.optError2 = true;
      this.setState({ optError2: true })
      toast.error('Preencha uma Categoria!!');

    }else{this.optError2 = false; this.setState({ optError2: false })}

    if( this.optError1 === false && this.optError2 === false ){
      this.setState({ show: false, loading: true })
      this.handleSubmit()
    }

  }

  handleSubmit = (e) => {

    let params = {}

    params.name = this.state.name;
    params.type = this.state.type;
    params.category = this.state.categories;

    if( this.state.cnpj ){ params.cnpj = this.state.cnpj };
    if( this.state.cpf  ){ params.cpf  = this.state.cpf  };
    if( this.state.city ){ params.city = this.state.city };
    if( this.state.fone ){ params.fone = this.state.fone };
    if( this.state.obs  ){ params.obs  = this.state.obs  };

    axios.patch(this.targetUrl, params, {"headers" : headerRequest})
      .then(res => {
        if(res.status === 200){
          reactLocalStorage.set('@result', 201); 
          setTimeout(() => {
            return window.location.reload(false);
          }, 2000);
        }
      }).catch(error => {
        if(error.response.data.message.includes('dup key: {')){
          this.setState({ show1: true, loading: false })
          toast.error('Pessoa já Cadastrada!!');
        }else{
          this.setState({ show1: true, loading: false })
          toast.error('Erro Inesperado!!');
          console.log('ERROR: ', error.response.status + ' [' + error.response.data.message +']');
        }
    });
  };

  render(){
    return(
      <>
      <Fab className='m-1' size="small" onClick={this.handleShow} title="Editar Pessoa" style={{ backgroundColor: ' #fb3', color: '#fff' }} >
       <EditIcon />
      </Fab>
      <Modal show={this.state.show} onHide={this.handleShow} centered size="lg">  
        <Modal.Header closeButton>
          <Modal.Title><EditIcon style={{ fontSize: 30, marginRight: '5px' }}/>Editar Pessoa</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ToastContainer style={{ fontWeight: "bold" }}/>
          <Form>
            <Card>
              <Card.Body>
                <Grid container spacing={3}>     
                  <Grid item xs={12} md={8}>
                    <FormGroup controlId="name" bssize="large">
                      <TextField
                        error={this.state.optError1}
                        autoFocus
                        type="text"
                        value={this.state.name}
                        label="Nome *"
                        onChange={e => this.setState({ name : e.target.value})}
                        className="col-12"
                      />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12} md={4} style={{ textAlign: "right" }}>
                    <FormControl component="fieldset">
                      <RadioGroup aria-label="position" name="position" value={this.state.type} onChange={this.handleChangeRadio} row>
                        <FormControlLabel
                          value="J"
                          control={<Radio color="primary" />}
                          label="Jurídica"
                          labelPlacement="end"
                        />
                        <FormControlLabel
                          value="F"
                          control={<Radio color="primary" />}
                          label="Física"
                          labelPlacement="end"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    {this.state.type === 'J' ? 
                    <FormGroup controlId="cnpj" bssize="large" style={{marginBottom: "25px"}}>
                        <TextField
                        autoFocus
                        type="text"
                        value={this.state.cnpj}
                        label="CNPJ"
                        onChange={e => this.setState({ cnpj: cnpjMask(e.target.value) })}
                        className="col-12"
                        />
                    </FormGroup>
                    :
                    <FormGroup controlId="cpf" bssize="large" style={{marginBottom: "25px"}}>
                        <TextField
                        autoFocus
                        type="text"
                        value={this.state.cpf}
                        label="CPF"
                        onChange={e => this.setState({ cpf: cpfMask(e.target.value) })}
                        className="col-12"
                        />
                    </FormGroup>
                    }
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormGroup controlId="fone" bssize="large" style={{marginBottom: "25px"}}>
                        <TextField
                        autoFocus
                        type="text"
                        value={this.state.fone}
                        label="Telefone"
                        onChange={e => this.setState({ fone : foneMask(e.target.value) })}
                        className="col-12"
                        />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormGroup controlId="city" bssize="large" style={{marginBottom: "25px"}}>
                        <TextField
                        autoFocus
                        type="text"
                        value={this.state.city}
                        label="Cidade"
                        onChange={e => this.setState({ city: e.target.value })}
                        className="col-12"
                        />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormGroup controlId="obs" bssize="large" style={{marginBottom: "25px"}}>
                        <TextField
                        autoFocus
                        type="text"
                        value={this.state.obs}
                        label="Observação"
                        onChange={e => this.setState({ obs: e.target.value })}
                        className="col-12"
                        />
                    </FormGroup>
                  </Grid>
                  <Grid item xs={12}>
                    <FormUI.Field inline>
                      <Label pointing='below' basic color={this.state.optError2 ? 'red' : 'blue'}>Categorias *</Label>
                      <Dropdown
                        error={this.state.optError2}
                        options={this.state.optCategories}
                        multiple
                        search
                        selection
                        fluid
                        allowAdditions
                        value={this.state.categories}
                        onAddItem={this.handleAddition}
                        onChange={this.handleChange}
                      />
                    </FormUI.Field>
                  </Grid>
                </Grid>
              </Card.Body>
            </Card>
          </Form>
      </Modal.Body>  
      <Modal.Footer>
        <Button.Group>
          <Button negative onClick={this.handleShow}>
            <Icon name='remove' /> Cancelar
          </Button>
          <Button.Or text='ou' />
          <Button positive basic={this.state.loading} loading={this.state.loading} onClick={this.validateSubmit}>
            <Icon name='checkmark' />Salvar
          </Button>
        </Button.Group>
      </Modal.Footer>
    </Modal>
    {this.state.loading ? 
      <Loading />
    : false}
    </> 
    )
  }
}