import React, { Component } from 'react';
import axios from 'axios';
import parse from 'html-react-parser';

import { toast } from 'react-toastify';
import { reactLocalStorage } from 'reactjs-localstorage';

import { serverUrl, categoriesFilter, headerRequest } from '../../lib/funApp/general';

import { MDBIcon } from 'mdbreact';
import { SpeedDial, SpeedDialIcon, SpeedDialAction } from '@material-ui/lab';
import { Form, Card, FormGroup, Row, Col, Modal, Container } from "react-bootstrap";
import { Button, Label, Icon } from 'semantic-ui-react';

import { Radio, RadioGroup, FormControlLabel, FormControl, Grid, 
         TextField, Dialog, DialogActions, DialogContent, Slide } from '@material-ui/core'

import { FormatIndentDecrease as FormatIndentDecreaseIcon, 
    Clear as ClearIcon, AddToPhotos } from '@material-ui/icons';
    
import Loading from  '../../containers/loading'; 

export default class CategoriesDial extends Component {

    constructor(props){
        super()        
        this.targetUrl = serverUrl + '/src/categories';
        this.targetUrlSrc = serverUrl + '/src/categories?name=';
        this.optError = false
        this.categories = props.categories
        this.expenses = JSON.stringify(categoriesFilter(props,'D')).replace(/\[]/g, "")
                          .replace(/\[/g, "<ul class='tree'>")
                          .replace(/\]/g, "</ul>")
                          .replace(/\{/g, "")
                          .replace(/\}/g, "")
                          .replace(/"/g, "") 
                          .replace(/id:/g, '<li><label><input type="radio" class="option-input radio" name="rads" value="')
                          .replace(/,child:/g, '</label></li>')
                          .replace(/,/g, "")
                          .replace(/name:/g, '">')    
        this.incomes = JSON.stringify(categoriesFilter(props,'R')).replace(/\[]/g, "")
                          .replace(/\[/g, "<ul class='tree'>")
                          .replace(/\]/g, "</ul>")
                          .replace(/\{/g, "")
                          .replace(/\}/g, "")
                          .replace(/"/g, "") 
                          .replace(/id:/g, '<li><label><input type="radio" class="option-input radio" name="rads" value="')
                          .replace(/,child:/g, '</label></li>')
                          .replace(/,/g, "")
                          .replace(/name:/g, '">')  
        this.Transition = React.forwardRef(function Transition(props, ref) {
                            return <Slide direction="up" ref={ref} {...props} />
                          })
        this.state ={
            show1: JSON.parse(sessionStorage.getItem('@md_Category')), 
            show2: false, 
            open: false, hidden: false,
            openDialog: false,
            optError: false,
            loading: false,
            name: '',
            type: 'D',
            parent: {},
        }
    }

    handleOpen = () => {this.setState({ open: !this.state.open });};
    handleDialog = () => {this.setState({ openDialog: !this.state.openDialog });};
    handleShowAdd = () => {
        this.setState({ show1: !this.state.show1 }); 
        sessionStorage.setItem('@md_Category', !this.state.show1)
    };

    handleChangeRadio = (e) => this.setState({ type: e.target.value});

    handleSubCat = (e) => {

      let radVal = document.subAddForm.rads.value;

      if(radVal.length > 0){
          this.categories.map((item, index)=>(
            radVal === item._id ?
              this.setState({ parent: item })
            : false
        ))
      }
      this.handleDialog()
    }

    validateSubmit = () => {
      if( this.state.name.length <= 3 ){

        this.optError = true; 
        this.setState({ optError: true })

        if( this.state.name.length > 0 ){
          toast.error('Nome deve ter mais de 3 Letras!!');
        }else{
          toast.error('Preencha o Nome!!');
        }

      }else{
        this.optError = false; this.setState({ optError: false })
        this.setState({ show1: false, loading: true })
        this.handleSubmit()
      }


    }

    handleSubmit = (e) => {

      let params = {}

      params.name = this.state.name
      params.type = this.state.type
      if(this.state.parent){ params.parent = this.state.parent._id }
      
      axios.post(this.targetUrl, params, {"headers" : headerRequest})
        .then(res => {
          if(res.status === 200){
            reactLocalStorage.set('@result', 200); 
            setTimeout(() => {
              return window.location.reload(false);
            }, 2000);
          }
        }).catch(error => {
          if(error.response.data.message.includes('dup key: {')){
              this.setState({ show1: true, loading: false })
              toast.error('Categoria já Cadastrada!!');
          }else{
            this.setState({ show1: true, loading: false })
            toast.error('Erro Inesperado!!');
            console.log('ERROR: ', error.response.status + ' [' + error.response.data.message +']');
          }  
        });
    }

    render() {
        return(
            <>
              <div>
                <SpeedDial
                    ariaLabel="SpeedDial tooltip Categories"
                    hidden={this.state.hidden}
                    icon={<SpeedDialIcon icon={<FormatIndentDecreaseIcon style={{ fontSize: 25 }}/>} openIcon={<ClearIcon  style={{ fontSize: 25 }}/>}/>}
                    onClose={this.handleOpen}
                    onOpen={this.handleOpen}
                    open={this.state.open}
                    direction='left'
                >
                  <SpeedDialAction
                      key='CadastroCategoria'
                      icon={<AddToPhotos />}
                      tooltipTitle='Adicionar'
                      onClick={this.handleShowAdd}
                  />
                </SpeedDial> 
             </div>
             <div>
            <Modal show={this.state.show1} onHide={this.handleShowAdd} centered size="lg">  
              <Modal.Header closeButton>
                <Modal.Title><AddToPhotos style={{ fontSize: 30, marginRight: '5px' }}/>Cadastro de Categorias</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <div className="d-none">
                { this.state.show1 ? 
                    setTimeout(
                        function() {
                            document.getElementById("nameText").focus();
                        },
                        500
                        )
                    :false
                }</div>
                <Card>
                  <Card.Body>
                    <Grid container spacing={3}>     
                      <Grid item xs={12} md={8}>
                        <FormGroup controlId="name" bssize="large" style={{marginBottom: "25px"}}>
                            <TextField
                                error={this.state.optError}
                                id="nameText"
                                type="text"
                                value={this.state.name}
                                label="Nome *"
                                onChange={e => this.setState({ name: e.target.value})}
                                className="col-12"
                            />
                        </FormGroup>
                      </Grid>
                      <Grid item xs={12} md={4}>
                          <FormControl component="fieldset">
                              <RadioGroup aria-label="position" name="position" value={this.state.type} onChange={this.handleChangeRadio} row>
                                  <FormControlLabel
                                  value="D"
                                  control={<Radio color="primary" />}
                                  label="Despesa"
                                  labelPlacement="end"
                                  />
                                  <FormControlLabel
                                  value="R"
                                  control={<Radio color="primary" />}
                                  label="Receita"
                                  labelPlacement="end"
                                  />
                              </RadioGroup>
                          </FormControl>
                      </Grid>
                      <Grid item xs={12}>
                        <Button as='div' labelPosition='right'>
                          <Button primary onClick={this.handleDialog} color={this.state.type === 'D' ? 'red' : 'blue'}>
                            <Icon name='sitemap' />
                            Sub-Categoria de 
                          </Button>
                          <Label as='a' basic pointing='left' color={this.state.type === 'D' ? 'red' : 'blue'}>
                            {this.state.parent.name !== undefined ? this.state.parent.name : '...' }
                          </Label>
                        </Button>
                        {this.state.parent.name !== undefined ?
                          <Label as='a' tag onClick={e=> this.setState({ parent: {} })} color={this.state.type === 'D' ? 'red' : 'blue'}>
                            <Icon name='trash alternate outline' className='m-0'/>
                          </Label>
                        : false }
                      </Grid>
                    </Grid>
                  </Card.Body>
                </Card>

                <Dialog
                  open={this.state.openDialog}
                  TransitionComponent={this.Transition}
                  keepMounted
                  onClose={this.UNSAFE_componentWillReceivePropshandleDialog}
                  aria-labelledby="alert-dialog-slide-title"
                  aria-describedby="alert-dialog-slide-description"
                >
                  <DialogContent>   
                    <Form onSubmit={this.handleSubCat} method="get" name="subAddForm">
                      <Container>
                        <Row>
                          <Col>   
                          {this.state.type === 'D' ?
                            <>  
                              <div className="mb-2 text-danger">
                                <MDBIcon icon="plane-arrival" size="lg"/> <strong>Despesas</strong>
                              </div>
                              <div className="clt">
                                { parse(this.expenses)}
                              </div>
                            </>
                          :
                            <>
                              <div className="mb-2 text-primary">
                                <MDBIcon icon="plane-departure" size="lg"/> <strong>Receitas</strong>
                              </div>
                              <div className="clt">
                              { parse(this.incomes) }
                              </div>
                            </>
                          }
                          </Col>
                        </Row>
                      </Container>
                    </Form>
                  </DialogContent>
                  <DialogActions id='ModalActionFooter' >
                    <Button.Group>
                      <Button negative onClick={this.handleDialog}>
                        <Icon name='remove' /> Cancelar
                      </Button>
                      <Button.Or text='ou' />
                      <Button positive onClick={this.handleSubCat}>
                        <Icon name='checkmark' /> Escolher
                      </Button>
                    </Button.Group>
                  </DialogActions>
                </Dialog>        

              </Modal.Body>
              <Modal.Footer>
                <Button.Group>
                  <Button negative onClick={this.handleShowAdd}>
                    <Icon name='remove' /> Cancelar
                  </Button>
                  <Button.Or text='ou' />
                  <Button positive basic={this.state.loading} loading={this.state.loading} onClick={this.validateSubmit}>
                    <Icon name='checkmark' /> Salvar
                  </Button>
                </Button.Group>
              </Modal.Footer>
            </Modal>
            </div>
            {this.state.loading ? 
              <Loading main={true} />
            : false}
            </>
        )
    }
}