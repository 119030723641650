import React, { Component } from 'react';

import { MDBIcon } from 'mdbreact';
import { Container, Row , Col } from "react-bootstrap";
import { Accordion, AccordionDetails, AccordionSummary, Typography } from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon } from '@material-ui/icons';

import { NotificationExercise } from '../../containers/Notification';
import ExerciseDial from './exerciseDial';
import ExerciseEdit from './exerciseEdit';
import ExerciseDelete from './exerciseDelete';

export default class ExerciseRenderGet extends Component{

  constructor(exercises){
    super()
    this.exercisesData = exercises.exercises[0]
    this.exercisesLinks = exercises.exercises[1]
    this.exerciseGroup = exercises.exercises[0].reduce(function (r, a) {
                            r[a.muscleGroup] = r[a.muscleGroup] || [];
                            r[a.muscleGroup].push(a);
                            return r;
                          }, Object.create(null));
    this.state = {
      expanded: sessionStorage.getItem('@accordExercises') ? sessionStorage.getItem('@accordExercises') : false,
    }
  }

  handleChange(panel){
    if(this.state.expanded !== panel){
      this.setState({expanded :  panel })
      sessionStorage.setItem('@accordExercises', panel)
    }else{
      this.setState({expanded :  false })
      sessionStorage.removeItem('@accordExercises')
    }
  };

  render(){
    return(
      <>
        <Container>
          <Row className="TitleContainer">
            <Col style={{ padding: "10px"}}>
              <h4><MDBIcon icon="running" className="mr-2 ml-4" />Exercícios</h4>
            </Col>
            <Col>
              <ExerciseDial />
            </Col>
          </Row>
        </Container>
        <Container className="MainContainer">
          <NotificationExercise />
          <Row>
            <Col>
              {Object.keys(this.exerciseGroup).sort().map((key, k1) => (
                <div className="w-100" key={k1}>
                  <Accordion expanded={this.state.expanded === `panel${k1}`} onChange={e=> this.handleChange(`panel${k1}`)} className='ExpansionPainelData'>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                    >
                      <Typography>  Grupo Muscular: {key}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Container>
                        {this.exerciseGroup[key].map((exercise, k2) => (
                          <Row key={k2} className="mb-2" style={{ lineHeight: "30px"}}>
                            <Col>
                              <MDBIcon icon="running" size="lg" className="ml-2 text-danger"/><span className="Rotulo">{exercise.name}</span>
                            </Col>
                            <Col className="text-right">
                              <ExerciseEdit exercise={exercise} />
                              <span className='m-2'></span>
                              <ExerciseDelete exercise={exercise} />
                            </Col>
                          </Row>
                          ))}
                      </Container>
                    </AccordionDetails>
                  </Accordion>
                </div>
              ))}
            </Col>
          </Row>
        </Container>
      </>
    )
  }
}