import React, { Component } from 'react';
import axios from 'axios';

import { reactLocalStorage } from 'reactjs-localstorage';
import { toast } from 'react-toastify';

import { serverUrl , headerRequest, optProfileUser } from '../../lib/funApp/general';
import { avatar } from '../../lib/img/img';

import { MDBIcon } from 'mdbreact';
import { Form, Card, FormGroup, Row, Col, Modal } from "react-bootstrap";
import { Button, Dropdown, Form as FormUI, Label, Icon } from 'semantic-ui-react';
import { Grid, TextField, FormControl, Radio, RadioGroup, FormControlLabel, IconButton  } from '@material-ui/core';
import { SpeedDial, SpeedDialIcon, SpeedDialAction } from '@material-ui/lab';
import { PersonAdd as PersonAddIcon, FormatIndentDecrease as FormatIndentDecreaseIcon, 
         Clear as ClearIcon, Search as SearchIcon, LockOpen, Build, Close} from '@material-ui/icons';

import Loading from  '../../containers/loading';
import UserDelete from './userDelete';
import UserEdit from './userEdit';
import UserResetPass from './userResetPass';
import UserStatus from "../users/userStatus"

export default class UserDial extends Component {

  constructor(){
      super()
      this.targetUrl = serverUrl + '/src/users'
      this.targetUrlSrc = serverUrl + '/src/users?name='
      this.optProfileUser = optProfileUser
      this.focus = true
      this.avatarMaleList = this.dataOrganize(avatar.small.male)
      this.avatarFemaleList = this.dataOrganize(avatar.small.female)
      this.optError1 = false
      this.optError2 = false
      this.optError3 = false
      this.state ={
          show1: JSON.parse(sessionStorage.getItem('@md_User')), 
          show2: false, 
          open: false, hidden: false,
          optError1: false, optError2: false,
          optError3: false,
          loading: false,
          srcType: 'name',
          keySrc: '',
          getResult: [],
          avatar: Math.floor(Math.random() * ( 7 - 0 + 1) + 0),
          name: '',
          email: '',
          profiles: ['user'],
          gender: 'M'
      }
  } 

  dataOrganize = (data) => {
    let newData = []
    data.map((data, index) => (
      newData.push({
          key: index,
          value: index,
          image: { avatar: true, src: data },
      })
    ))
    return newData
  }

  
  handleOpen = () => {this.setState({ open: !this.state.open });};
  handleShowAdd = () => {
    this.setState({ show1: !this.state.show1 }); 
    sessionStorage.setItem('@md_User', !this.state.show1)
  };
  handleShowSrc = () => this.setState({ show2: !this.state.show2 });
  
  handleChange = (e, { value }) => this.setState({ profiles: value })
  
  handleChangeRadio = e => {
    this.setState({ srcType: e.target.value, keySrc: '', getResult: [] });
  };
  
  handleChangeGender = event => {
    this.setState({gender: event.target.value});
  };

  handleAvatar = (e, { value }) => this.setState({ avatar: value })

  validateSubmit = () => {
    if( this.state.name.length <= 3 ){

      this.optError1 = true; 
      this.setState({ optError1: true })

      if( this.state.name.length > 0 ){
        toast.error('Nome deve ter mais de 3 Letras!!');
      }else{
        toast.error('Preencha o Nome!!');
      }

    }else{this.optError1 = false; this.setState({ optError1: false })}

    if( this.state.email.length === 0 ){
      this.optError2 = true; 
      this.setState({ optError2: true })
      toast.error('Preencha o Email!!');

    }else{
      if(!this.state.email.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i)){
        this.optError2 = true; 
        this.setState({ optError2: true })
        toast.error('Email Inválidoil!!');
      }else{this.optError2 = false; this.setState({ optError2: false })}
    } 

    if( this.state.profiles.length === 0 ){
      this.optError3 = true;
      this.setState({ optError3: true })
      toast.error('Preencha o Perfil!!');
    }else{this.optError2 = false; this.setState({ optError3: false })}

    if( this.optError1 === false && this.optError2 === false && this.optError3 === false ){
      this.setState({ show1: false, loading: true })
      this.handleSubmit()
    }

  }
  
  handleSubmit() {
    
    let params = {}

    params.name = this.state.name;
    params.email = this.state.email;
    params.profiles = this.state.profiles;
    params.avatar = this.state.avatar;
    params.gender = this.state.gender;

    axios.post(this.targetUrl, params, {"headers" : headerRequest})
      .then(res => {
        if(res.status === 200){
          reactLocalStorage.set('@result', 200); 
          setTimeout(() => {
            return window.location.reload(false);
          }, 2000);
        }
      }).catch(error => {
        if(error.response.data.message.includes('dup key: {')){
          this.setState({ show1: true, loading: false })
          toast.error('Usuário já Cadastrado!!');
        }else{
          this.setState({ show1: true, loading: false })
          toast.error('Erro Inesperado!!');
          console.log('ERROR: ', error.response.status + ' [' + error.response.data.message +']');
        }
      });
    }

    searchKey(value){

      let urlSrc = this.targetUrlSrc + value;
      this.setState({ keySrc: value });
      this.setState({ getResult: [] }) 
    
      if(value.length > 0){
        axios.get( urlSrc, { "headers" : headerRequest } )
        .then(res => {
            if(res.status === 200){
                this.setState({ getResult: res.data.items })
              }
            }).catch(error => {
                toast.error('Erro Inesperado!!');
                console.log('ERROR: ', error.response.status + ' [' + error.response.data.message +']')
            }
        )    
      }
    }

    render(){
        return(
            <div>
              {console.log('focus', this.focus)}
              <div>
                <SpeedDial
                  ariaLabel="SpeedDial tooltip People"
                  hidden={this.state.hidden}
                  icon={<SpeedDialIcon icon={<FormatIndentDecreaseIcon style={{ fontSize: 25 }}/>} openIcon={<ClearIcon  style={{ fontSize: 25 }}/>}/>}
                  onClose={this.handleOpen}
                  onOpen={this.handleOpen}
                  open={this.state.open}
                  direction='left'
                >
                <SpeedDialAction
                  key='CadastroUsuario'
                  icon={<PersonAddIcon />}
                  tooltipTitle='Adicionar'
                  onClick={this.handleShowAdd}
                />
                <SpeedDialAction
                  key='PesquisarUsuario'
                  icon={<SearchIcon />}
                  tooltipTitle='Pesquisar'
                  onClick={this.handleShowSrc}
                />
              </SpeedDial>
            </div>
                <div>
                  <Modal show={this.state.show1} onHide={this.handleShowAdd} centered size="lg">  
                    <Modal.Header closeButton>
                        <Modal.Title><PersonAddIcon style={{ fontSize: 30, marginRight: '5px' }}/>Cadastro de Usuários</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div className="d-none">
                      { this.state.show1 && this.focus === true ? 
                            this.focus = false +
                          setTimeout(
                              function() {
                                  document.getElementById("nameText").focus();
                              },
                              500
                              )
                          :false
                      }</div>
                      <Form>
                        <Card>
                          <Card.Body>
                            <Grid container spacing={3}>    
                                <Grid item xs={6}>
                                  <div>
                                    <img id='imgMain' name='imgMain' src={ this.state.gender === 'M' ? this.avatarMaleList[this.state.avatar].image.src : this.avatarFemaleList[this.state.avatar].image.src } className='m-2' alt='avatar'/>
                                    <Dropdown
                                      simple
                                      options={ this.state.gender === 'M' ? this.avatarMaleList : this.avatarFemaleList }
                                      onChange={this.handleAvatar}
                                    />
                                  </div>
                              </Grid> 
                              <Grid item xs={6} style={{ textAlign: "right", marginTop: '15px' }}>
                                <FormControl component="fieldset">
                                  <RadioGroup aria-label="position" name="position" value={this.state.gender} onChange={this.handleChangeGender} row>
                                    <FormControlLabel
                                      value="M"
                                      control={<Radio color="primary" />}
                                      label="Masculino"
                                      labelPlacement="end"
                                    />
                                    <FormControlLabel
                                      value="F"
                                      control={<Radio color="primary" />}
                                      label="Feminino"
                                      labelPlacement="end"
                                    />
                                  </RadioGroup>
                                </FormControl>
                              </Grid>
                              <Grid item xs={12}>
                                 <FormGroup controlId="name" bssize="large">
                                  <TextField
                                    error={this.state.optError1}
                                    id="nameText"
                                    type="text"
                                    value={this.state.name}
                                    label="Nome *"
                                    onChange={e => this.setState({ name: e.target.value })}
                                    className="col-12"
                                  />
                                </FormGroup>    
                              </Grid>
                              <Grid item xs={12}>
                                <Form.Group controlId="email" bssize="large">                  
                                  <TextField
                                    error={this.state.optError2}
                                    type="text"
                                    value={this.state.email}
                                    label="Email *"
                                    onChange={e => this.setState({ email: e.target.value })}
                                    className="col-12"
                                  />
                                </Form.Group>
                              </Grid>
                              <Grid item xs={12}>
                                <FormUI.Field inline>
                                    <Label pointing='below' basic color={this.state.optError3 ? 'red' : 'blue'}>Perfil *</Label>
                                    <Dropdown
                                      error={this.state.optError3}
                                      options={this.optProfileUser}
                                      multiple
                                      search
                                      selection
                                      fluid
                                      value={this.state.profiles}
                                      onChange={this.handleChange}
                                    />
                                </FormUI.Field>
                              </Grid>
                            </Grid>
                          </Card.Body>
                        </Card>
                      </Form>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button.Group>
                        <Button negative onClick={this.handleShowAdd}>
                          <Icon name='remove' /> Cancelar
                        </Button>
                        <Button.Or text='ou' />
                        <Button positive basic={this.state.loading} loading={this.state.loading} onClick={this.validateSubmit}>
                          <Icon name='checkmark' /> Salvar
                        </Button>
                      </Button.Group>
                    </Modal.Footer>
                  </Modal>
                  <Modal show={this.state.show2} onHide={this.handleShowSrc} centered size="lg">  
                    <Modal.Header closeButton>
                      <Modal.Title><SearchIcon style={{ fontSize: 30, marginRight: '5px' }}/>Pesquisa de Usuários</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div className="d-none">
                      { this.state.show2 ? 
                          setTimeout(
                              function() {
                                  document.getElementById("searchText").focus();
                              },
                              500
                              )
                          :false
                      }</div>
                      <Row>
                        <Col className="col-8">
                          <FormGroup controlId="name" bssize="large" style={{marginBottom: "25px"}}>
                            <TextField
                              id="searchText"
                              type="text"
                              value={this.state.keySrc}
                              label={this.state.srcType === 'name' ? "Nome" : "Email"}
                              onChange={e => this.searchKey(e.target.value)}
                              className="col-12" 
                            />
                          </FormGroup>
                        </Col>
                        <Col className="col-4" style={{ textAlign: "right" }}>
                          <FormControl component="fieldset">
                            <RadioGroup aria-label="position" name="position" value={this.state.srcType} onChange={this.handleChangeRadio} row>
                              <FormControlLabel
                                value="name"
                                control={<Radio color="primary" />}
                                label="Nome"
                                labelPlacement="end"
                              />
                              <FormControlLabel
                                value="email"
                                control={<Radio color="primary" />}
                                label="Email"
                                labelPlacement="end"
                              />
                            </RadioGroup>
                          </FormControl>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Card>
                            <Card.Body>
                              {this.state.getResult.length > 0 ?
                                this.state.getResult.map((user, index)=> (
                                  <UserOptionsSrc user={user} key={index}/>
                                ))
                              :<h4 className="text-center">Sem Resultados</h4>
                              }
                            </Card.Body>
                          </Card>
                        </Col>
                      </Row>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button.Group>
                        <Button negative onClick={this.handleShowSrc}>
                          <Icon name='remove' /> Fechar
                        </Button>
                      </Button.Group>
                    </Modal.Footer>
                  </Modal>
                </div>
                {this.state.loading ? 
                  <Loading main={true} />
                : false}
            </div>
        )
    }
}

class UserOptionsSrc extends Component{

  constructor(props){
    super()
    this.user = props.user
    this.index = props.index
    this.state = {
      houver: false
    }
  }

  toogleHouver = () => {this.setState({ houver: !this.state.houver })};

  profileComp(profiles){

    function srcProfile(value) {
      let teste = ''
      optProfileUser.map((option, i)=>(
        option.value === value ? teste = option.text : false
      ))
      return teste
    }
    return(
      profiles.map((profile, i) =>(
        <span key={i}>
          <LockOpen style={{ color:'#2185d0' }} className="ml-1 mr-1"/> 
          <span>{srcProfile(profile)}</span>
        </span>
    )
   ))
  }

  render(){
    return(
      <Row key={this.index} className="mb-1"style={{borderBottom: "inset", paddingBottom: "5px"}}>
        <Col sm={12} md={6}>
          <Row> 
            <Col>
              {this.user.status === true ? 
                <MDBIcon icon="user-check" size="lg" style={{ color:"green" }}/> 
              : <MDBIcon icon="user-alt-slash" size="lg"  style={{ color:"red" }}/>
              }
              <span style={{ marginLeft: "10px" }}>{this.user.name}</span>
            </Col>
          </Row>
          <Row>
            <Col style={{ marginLeft: '33px' }} >
              {this.user.email}
            </Col>
          </Row>
        </Col>
        {!this.state.houver ? 
          <Col sm={12} md={6}>
              {this.profileComp(this.user.profiles)}
            <IconButton onClick={this.toogleHouver} title="Abrir Opções" style={{ float: 'right', backgroundColor: '#60a4ffbd', color: '#fff' }} >
              {!this.state.houver ? <Build fontSize='medium'/> : <Close fontSize='medium'/> }
            </IconButton>
          </Col>
        :
          <Col sm={12} md={6} className="text-right align-content-center d-flex"  style={{ flexDirection: 'row-reverse'}}>
            <IconButton onClick={this.toogleHouver} title="Fechar Opções" style={{ float: 'right' }} >
              {!this.state.houver ? <Build fontSize='medium'/> : <Close fontSize='medium'/>}
            </IconButton>
              <span className='m-2'></span> 
              <UserDelete user={this.user}/>
              <span className='m-2'></span> 
              {this.user.status === true ? 
                <>
                  <UserEdit user={this.user}/>
                  <span className='m-2'></span> 
                  <UserResetPass user={this.user}/>
                  <span className='m-2'></span>
                </>
              : false } 
              <UserStatus user={this.user} />     
          </Col>
        }     
      </Row>
    )
  }
}