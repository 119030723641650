import React, { Component } from 'react';
import WorkoutsRenderGet from '../components/workout/workouts';
import { RequestInfoGet, serverUrl } from '../lib/funApp/general';

export default class WorkoutsGet extends Component {

  constructor(props){
    super()
    this.targetUrl = serverUrl + '/src/workouts?user=' + props.user  
    this.state = {
      workouts: [],
      links: {}
    }
  }

  componentDidMount() {
    fetch(this.targetUrl ,RequestInfoGet)
    .then(res => res.json())
    .then((data) => {
      this.setState({ links: data._links })
      this.setState({ workouts: data.items.reverse() })
    })
    .catch()
  }

  render() { 
    return (
      <>
      {this.state.workouts[0] !== undefined ?
        <WorkoutsRenderGet workouts={[this.state.workouts,this.state.links]}/>
      : false}
      </>
    )
  } 

}