import React, {Component } from 'react'
import axios from 'axios';

import { reactLocalStorage } from 'reactjs-localstorage';
import { toast } from 'react-toastify';

import { serverUrl , headerRequest } from '../../lib/funApp/general';

import { Modal,} from "react-bootstrap";
import { Fab } from '@material-ui/core';
import { Button, Card, Icon } from 'semantic-ui-react';

import { DeleteForever }from '@material-ui/icons';

import Loading from '../../containers/loading';

export default class AccountDelete extends Component{

  constructor(props){
    super()
    this.account = props.account
    this.targetUrl = serverUrl + '/src/accounts/' + props.account._id
    this.state ={
      show: false,
      loading: false
    }
  };
  
  handleShow = () => this.setState({ show: !this.state.show });
  
  handleSubmit = () => {

    this.setState({ show: false, loading: true })

    axios.delete(this.targetUrl, {"headers" : headerRequest})
      .then(res => {
        if(res.status === 204){
          reactLocalStorage.set('@result', 204); 
          setTimeout(() => {
            return window.location.reload(false);
          }, 2000);
        }
      }).catch(error => {
          this.setState({ show: true, loading: false })
          toast.error('Erro Inesperado!!');
          console.log('ERROR: ', error.response.status + ' [' + error.response.data.message +']');
        }
      );
  }

  render(){
    return(
      <>
      <Fab color="secondary" size="small" onClick={this.handleShow} title="Deletar Conta" aria-label="add">
        <DeleteForever />
      </Fab>
      <Modal size="sm" show={this.state.show} onHide={this.handleShow} centered style={{backgroundColor: 'transparent !important' }}>
        
          <Card style={{ width: '100%' }}>
            <div className='m-3 text-center text-danger'>
              <h5><DeleteForever fontSize="large" className="mr-2" />Deseja Excluir a Conta?</h5>
            </div>
            <Card.Content>
              <Card.Header className='m-2'>
                {this.account.name}
              </Card.Header>
              <Card.Meta className='m-2 text-right'>
                <p><strong>Saldo: </strong> R$ {this.account.balance.toFixed(2)}</p>
              </Card.Meta>
              <Card.Description className='m-4'>
                <p><strong>Nome: </strong> {this.account.name}</p>
                <p><strong>Banco: </strong> {this.account.bank}</p>
                <p><strong>Tipo: </strong> {this.account.type}</p>
              </Card.Description>
            </Card.Content>
            <Card.Content extra className='text-center'>
              <Button.Group>
                <Button negative onClick={this.handleShow}>
                  <Icon name='remove' />Cancelar
                </Button>
                <Button.Or text='ou' />
                <Button positive basic={this.state.loading} loading={this.state.loading} onClick={this.handleSubmit} >
                  <Icon name='checkmark' />Excluir
                </Button>
              </Button.Group>
            </Card.Content>
          </Card>
      </Modal>
      { this.state.loading ? 
        <Loading />
      : false }
     </ > 
    )
  }
}