import React, { Component } from "react";
import Chart from "react-apexcharts";

import { Card } from "react-bootstrap";
import { Container } from 'react-bootstrap';

export default class AvailableColumn extends Component {

    constructor(props){
        super()
        this.dataSeries = []
        this.lastMounths = props.lastMounths
        this.state={
            series: props.series,
            options: {
                chart: {
                    type: 'bar',
                    height: 370,
                    stacked: true,
                    toolbar: {
                    show: true
                    },
                    zoom: {
                    enabled: true
                    }
                },
                responsive: [{
                    breakpoint: 480,
                    options: {
                    legend: {
                        position: 'top',
                        offsetX: -10,
                        offsetY: 0
                    }
                    }
                }],
                plotOptions: {
                    bar: {
                    borderRadius: 8,
                    horizontal: false,
                    },
                },
                xaxis: {
                    type: 'text',
                    categories: this.lastMounths.map((item,i)=>(
                        item.labelReduce
                    ))
                },
                legend: {
                    position: 'right',
                    offsetY: 40
                },
                fill: {
                    opacity: 1
                }
            },
        }
    }

    render() {
        return (
          <>
            <Container>
                <Card>
                    <Card.Body>
                        <div id="chart">
                            <Chart options={this.state.options} series={this.state.series} type="bar" height={380} />
                        </div>
                    </Card.Body>
                </Card>
            </Container>
          </>
        )
    }
}
