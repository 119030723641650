import React, { Component } from 'react';
import ExerciseRenderGet from '../components/exercises/exercises';
import { RequestInfoGet, pageSrc, serverUrl } from '../lib/funApp/general'

let targetUrl = '/src/exercises'

if(pageSrc.includes(targetUrl)){
  targetUrl = serverUrl + pageSrc
}else{
  targetUrl = serverUrl + targetUrl
}

class ExercisesGet extends Component {

  state = {
    exercises: [],
    links: {}
  }

  componentDidMount() {
    fetch(targetUrl ,RequestInfoGet)
    .then(res => res.json())
    .then((data) => {
      this.setState({ links: data._links })
      this.setState({ exercises: data.items })
    })
    .catch()
  }

  render() {
    return (
      this.state.exercises[0] !== undefined ?
        <ExerciseRenderGet exercises={[this.state.exercises,this.state.links]} />
      : false
    )
  }

}

export default ExercisesGet;