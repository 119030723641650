import React from 'react';
import { MDBIcon, MDBContainer } from 'mdbreact';

import { Link } from 'react-router-dom'
import {
        makeStyles, SwipeableDrawer,  List, Divider, ListItem, ListItemIcon,
        ListItemText, AppBar, Toolbar, IconButton, Menu, MenuItem, Collapse
}from '@material-ui/core';
import {
        Menu as MenuIcon, FitnessCenter as FitnessCenterIcon, Home as HomeIcon, MonetizationOn as MonetizationOnIcon,
        RecentActors as RecentActorsIcon, PieChart as PieChartIcon
} from '@material-ui/icons/';
import { UserName } from '../lib/funApp/general';

import { Card } from "react-bootstrap";

const useStyles = makeStyles(theme => ({
  list: { width: 250, },
  fullList: { width: 'auto', },
  nested: { paddingLeft: theme.spacing(4), },
  nested1: { paddingLeft: theme.spacing(7), },
}));

const useStylesBar = makeStyles(theme => ({
  root: { flexGrow: 1, },
  title: { flexGrow: 1, },
}));

export default function SwipeableTemporaryDrawer() {
  const classes = useStyles();
  const classesBar = useStylesBar();
  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [open3, setOpen3] = React.useState(false);
  const [open4, setOpen4] = React.useState(false);
  const [open5, setOpen5] = React.useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [state, setState] = React.useState({ left: false });
  const acc = sessionStorage.getItem("@acc");

  const toggleDrawer = (side, open) => event => {
    if (event && event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) { return; }
    setState({ ...state, [side]: open });
  };

  const handleClose = () => setAnchorEl(null);
  const handleClickMenu = () => setOpen(!open);
  const handleClickMenu2 = () => setOpen2(!open2);
  const handleClickMenu3 = () => setOpen3(!open3);
  const handleClickMenu4 = () => setOpen4(!open4);
  const handleClickMenu5 = () => setOpen5(!open5);
  const handleClick = event => setAnchorEl(event.currentTarget);

  const sideList = side => (
    <div
      className={classes.list}
      role="presentation"
      onKeyDown={toggleDrawer(side, false)}
    >
      <Card style={{margin: '15px', backgroundColor: 'rgba(255, 255, 255, 0.27)', color: "#35bce4"}}>
        <Card.Body className="text-center">
          <MDBIcon fab icon="jedi-order" size="4x" />
        </Card.Body>
      </Card>
    <List
      component="nav"
      aria-labelledby="nested-list-subheader"
      className={classes.root}
      style={{color: "white", zIndex: "100", backgroundColor: "#000000d4"}}
    >
      <Link to='/home'>
      <ListItem button>
        <ListItemIcon>
          <HomeIcon />
        </ListItemIcon>
        <ListItemText primary="Home" />
      </ListItem>
      </Link>

      <Divider />

      {acc.includes('eyJzdWIiOiJtaWNoZWx0dGVsZXNAZ21haWwuY29tIiwiaXNzIjoibWVhdC1hcGkiLCJpYXQiOjE2MjA5NTc4MzB9')
       || acc.includes('eyJzdWIiOiJtaWNoZWx0da212JHG554Z21haWwuY29tIiwiaXNzIjo54SJHGFGkiLCJpYXQiO55asdgGEGETc4sd')
       || acc.includes('eyJzdW25555IiOiJtaWsdgsNoZWsf44544fsxgg0dGVsGSGSAZ21haWwuY29tIiwiaXNzDFGFsvhdC1hcGkiLCJp')
        ?
        <>

      <ListItem button onClick={handleClickMenu5} style={{backgroundColor: 'rgba(53, 188, 228, 0.26)'}}>
        <ListItemIcon>
          {open5 ? <MDBIcon icon="minus" /> : <MDBIcon icon="plus" />}
        </ListItemIcon>
        <ListItemText primary="Fitness" />
      </ListItem>

      <Collapse in={open5} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>

        <Link to='/training'>
          <ListItem button className={classes.nested}>
            <ListItemIcon>
              <PieChartIcon />
            </ListItemIcon>
            <ListItemText primary="DashBoard" />
          </ListItem>
        </Link>

        <Link to='/training'>
          <ListItem button className={classes.nested}>
            <ListItemIcon>
              <FitnessCenterIcon />
            </ListItemIcon>
            <ListItemText primary="Treino" />
          </ListItem>
        </Link>

        </List>
      </Collapse>

      <Divider />
      </>:false}

      {acc.includes('eyJzdWIiOiJtaWNoZWx0dGVsZXNAZ21haWwuY29tIiwiaXNzIjoibWVhdC1hcGkiLCJpYXQiOjE2MjA5NTc4MzB9')
       || acc.includes('eyJzdWIiOiJtaWsdfsfYTYTAJshSdGVsZXNAZ21haWwuY29t54GGH5asd43IiwiaXNzIjoibWd4f4dsJpYXQiOjE')
        ?
        <>
          <ListItem button onClick={handleClickMenu4} style={{backgroundColor: 'rgba(53, 188, 228, 0.26)'}}>
            <ListItemIcon>
              {open4 ? <MDBIcon icon="minus" /> : <MDBIcon icon="plus" />}
            </ListItemIcon>
            <ListItemText primary="Finanças" />
          </ListItem>

          <Collapse in={open4} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>

            <Link to='/financial'>
              <ListItem button className={classes.nested}>
                <ListItemIcon>
                  <PieChartIcon />
                </ListItemIcon>
                <ListItemText primary="DashBoard" />
              </ListItem>
            </Link>

            <Link to='/statement'>
              <ListItem button className={classes.nested}>
                <ListItemIcon>
                  <MonetizationOnIcon />
                </ListItemIcon>
                <ListItemText primary="Extrato" />
              </ListItem>
            </Link>

            </List>
          </Collapse>

          <Divider />
        </>
        : false}

      {acc.includes('eyJzdWIiOiJtaWNoZWx0dGVsZXNAZ21haWwuY29tIiwiaXNzIjoibWVhdC1hcGkiLCJpYXQiOjE2MjA5NTc4MzB9')
       || acc.includes('eyJzdWIiOiJtaWsdfsfYTYTAJshSdGVsZXNAZ21haWwuY29t54GGH5asd43IiwiaXNzIjoibWd4f4dsJpYXQiOjE')
       || acc.includes('eyJzdW25555IiOiJtaWsdgsNoZWsf44544fsxgg0dGVsGSGSAZ21haWwuY29tIiwiaXNzDFGFsvhdC1hcGkiLCJp')
        ?
        <>
      <ListItem button onClick={handleClickMenu} style={{backgroundColor: 'rgba(53, 188, 228, 0.26)'}}>
        <ListItemIcon>
          {open ? <MDBIcon icon="minus" /> : <MDBIcon icon="plus" />}
        </ListItemIcon>
        <ListItemText primary="Cadastros" />
      </ListItem>

      <Collapse in={open} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>

        {acc.includes('eyJzdWIiOiJtaWNoZWx0dGVsZXNAZ21haWwuY29tIiwiaXNzIjoibWVhdC1hcGkiLCJpYXQiOjE2MjA5NTc4MzB9')
          || acc.includes('eyJzdW25555IiOiJtaWsdgsNoZWsf44544fsxgg0dGVsGSGSAZ21haWwuY29tIiwiaXNzDFGFsvhdC1hcGkiLCJp')
          ?
          <>
          <ListItem button onClick={handleClickMenu2} className={classes.nested} style={{backgroundColor: 'rgba(53, 188, 228, 0.26)'}}>
            <ListItemIcon>
              {open2 ? <MDBIcon icon="minus" /> : <MDBIcon icon="plus" />}
            </ListItemIcon>
            <ListItemText primary="Treinos" />
          </ListItem>

          <Collapse in={open2} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>

              <Link to='/workouts'>
                <ListItem button className={classes.nested1}>
                  <ListItemIcon>
                    <MDBIcon icon="dumbbell" />
                  </ListItemIcon>
                  <ListItemText primary="Fichas" />
                </ListItem>
              </Link>

              <Link to='/exercises'>
              <ListItem button className={classes.nested1}>
                <ListItemIcon>
                  <MDBIcon icon="running" />
                </ListItemIcon>
                <ListItemText primary="Exercício" />
              </ListItem>
              </Link>

            </List>
          </Collapse>

          <Divider />
          </>:false}

          {acc.includes('eyJzdWIiOiJtaWNoZWx0dGVsZXNAZ21haWwuY29tIiwiaXNzIjoibWVhdC1hcGkiLCJpYXQiOjE2MjA5NTc4MzB9')
          || acc.includes('eyJzdWIiOiJtaWsdfsfYTYTAJshSdGVsZXNAZ21haWwuY29t54GGH5asd43IiwiaXNzIjoibWd4f4dsJpYXQiOjE')
            ?
            <>

          <ListItem button onClick={handleClickMenu3} className={classes.nested} style={{backgroundColor: 'rgba(53, 188, 228, 0.26)'}}>
            <ListItemIcon>
              {open3 ? <MDBIcon icon="minus" /> : <MDBIcon icon="plus" />}
            </ListItemIcon>
            <ListItemText primary="Financeiro" />
          </ListItem>

          <Collapse in={open3} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>

              <Link to='/accounts'>
                <ListItem button className={classes.nested1}>
                  <ListItemIcon>
                    <MDBIcon icon="cash-register" />
                  </ListItemIcon>
                  <ListItemText primary="Contas" />
                </ListItem>
              </Link>

              <Link to='/categories'>
              <ListItem button className={classes.nested1}>
                <ListItemIcon>
                  <MDBIcon icon="donate" />
                </ListItemIcon>
                <ListItemText primary="Categorias" />
              </ListItem>
              </Link>

            </List>
          </Collapse>

          <Divider />
          </>:false}

          {acc.includes('eyJzdWIiOiJtaWNoZWx0dGVsZXNAZ21haWwuY29tIiwiaXNzIjoibWVhdC1hcGkiLCJpYXQiOjE2MjA5NTc4MzB9')
            || acc.includes('eyJzdWIiOiJtaWsdfsfYTYTAJshSdGVsZXNAZ21haWwuY29t54GGH5asd43IiwiaXNzIjoibWd4f4dsJpYXQiOjE')
            ?
            <>

          <Link to='/peoples'>
            <ListItem button className={classes.nested}>
                <ListItemIcon>
                  <RecentActorsIcon />
                </ListItemIcon>
                <ListItemText primary="Pessoas" />
            </ListItem>
          </Link>

          <Link to='/users'>
          <ListItem button className={classes.nested}>
            <ListItemIcon>
              <MDBIcon icon="users-cog" />
            </ListItemIcon>
            <ListItemText primary="Usuarios" />
          </ListItem>
          </Link>
          </>:false}
        </List>
      </Collapse>
      </>:false}

    </List>
    </div>
  );

  return (
    <MDBContainer style={{ padding: "0px" }}>
      <div className={classesBar.root}>
        <AppBar position="static" style={{ backgroundColor: "transparent"}}>
          <Toolbar style={{ padding: "0px" }}>
            <div style={{ width: "100%", paddingLeft: "10px", paddingRight: "10px" }}>
              <IconButton edge="start"  onClick={toggleDrawer('left', true)} className={classesBar.menuButton} color="inherit" aria-label="menu">
                <MenuIcon style={{ fontSize: 40 }} />
              </IconButton>
              <IconButton edge="end"  onClick={handleClick} color="inherit" style={{float: "right", marginTop: "9px"}}>
                <MDBIcon icon="user-astronaut" size="lg"/>
              </IconButton>
            </div>
          </Toolbar>
        </AppBar>
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={handleClose} disabled={true}><span className="mr-4 ml-4">{UserName}</span></MenuItem>
          <Link to='/user'>
            <MenuItem>
              <div className="w-100"><MDBIcon icon="user-cog"/><span className="float-right">Perfil</span></div>
            </MenuItem>
          </Link>
          <Link to='/logout'>
            <MenuItem>
              <div className="w-100"><MDBIcon icon="sign-out-alt"/><span className="float-right">Sair</span></div>
            </MenuItem>
          </Link>
        </Menu>
        <SwipeableDrawer
          open={state.left}
          onClose={toggleDrawer('left', false)}
          onOpen={toggleDrawer('left', true)}
        >
          {sideList('left')}
        </SwipeableDrawer>
      </div>
    </MDBContainer>
  );
}