import React, { Component } from 'react';
import axios from 'axios';

import { reactLocalStorage } from 'reactjs-localstorage';
import { serverUrl , headerRequest, optMuscleGroup } from '../../lib/funApp/general'

import { toast } from 'react-toastify';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

import { MDBBtn, MDBIcon } from 'mdbreact';
import { Modal, Card, Row , Col } from "react-bootstrap";
import { Stepper, Step, StepLabel, Slide } from '@material-ui/core';
import { Button, Icon, Dropdown, Input, Table, Label } from 'semantic-ui-react'

import Loading from '../../containers/loading';
import ExerciseAdd from './exerciseAdd'
 
// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
};

/**
 * Moves an item from one list to another list.
 */
const move = (source, destination, droppableSource, droppableDestination) => {
    const sourceClone = Array.from(source);
    const destClone = Array.from(destination);
    const [removed] = sourceClone.splice(droppableSource.index, 1);

    destClone.splice(droppableDestination.index, 0, removed);

    const result = {};
    result[droppableSource.droppableId] = sourceClone;
    result[droppableDestination.droppableId] = destClone;

    return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: 'none',
    padding: grid * 1,
    margin: `0 0 ${grid}px 0`,

    // change background colour if dragging
    background: isDragging ? 'lightgreen' : 'white',

    // styles we need to apply on draggables
    ...draggableStyle
});

const getListStyle = isDraggingOver => ({
    background: isDraggingOver ? 'lightblue' : 'lightgrey',
    padding: grid
});

export default class ListExercises extends Component {

constructor(props){
    super()
    this.bodyBuildingPlug = props.bodyBuildingPlug
    this.state = {
        show: JSON.parse(sessionStorage.getItem('@md_Exercise')),
        checked: true,
        activeStep: 0,
        training: JSON.parse(sessionStorage.getItem('@List_Exercise')) !== null ? JSON.parse(sessionStorage.getItem('@List_Exercise')).itens : [],
        listItens: [],
        letter: JSON.parse(sessionStorage.getItem('@List_Exercise')) !== null ? JSON.parse(sessionStorage.getItem('@List_Exercise')).letter : ''
    }
  }

  componentDidMount() {
    if(this.state.training.length > 0){
        let bodyB = []
        if(this.props.bodyBuildingPlug.training.length > 0 ){
            this.props.bodyBuildingPlug.training.map((exercise, k1) =>(
                bodyB.push({
                    _id: exercise.exercise._id,
                    name: exercise.exercise.name,
                    repeat: exercise.repeat,
                    series: exercise.series,
                    weight: exercise.weight
                })
            ))
        }
    }
  }

  childReturn = (childData) => {
    this.setState({training: childData.itens, letter: childData.letter})
    let jsonData = JSON.stringify(childData);
    sessionStorage.setItem('@List_Exercise', jsonData)
  }

  handleShow = () => {
    let bodyB = []
    if(this.props.bodyBuildingPlug.training.length > 0 ){
        this.props.bodyBuildingPlug.training.map((exercise, k1) =>(
            bodyB.push({
                _id: exercise.exercise._id,
                name: exercise.exercise.name,
                repeat: exercise.repeat,
                series: exercise.series,
                weight: exercise.weight,
                muscleGroup: exercise.muscleGroup,
            })
        ))
    }
    this.setState((prevState) => ({
        show: !prevState.show,
        training: bodyB,
        letter: this.props.bodyBuildingPlug.letter,
        activeStep: 0,
        checked: true
    }))
    if(!this.state.show){
        let edit = {itens: bodyB, letter:  this.props.bodyBuildingPlug.letter}   
        let jsonData = JSON.stringify(edit);
        sessionStorage.setItem('@List_Exercise', jsonData)
    }else{
        sessionStorage.removeItem('@List_Exercise')
    }
  };
  handleNext = () => this.setState((prevState) => ({ activeStep: prevState.activeStep + 1, checked: !prevState.checked }));
  handleBack = () => this.setState((prevState) => ({ activeStep: prevState.activeStep - 1, checked: !prevState.checked }));

  handleSubmit = () => {

    this.setState({ show: false, loading: true })

    let newList = []
    this.state.training.map((exercise, k1) =>(
      newList.push({
        order: exercise.order,
        exercise: exercise._id,
        repeat: exercise.repeat,
        series: exercise.series,
        weight: exercise.weight ? exercise.weight : 0
      })
    ))

    let params = { 'training': newList, 'letter': this.state.letter }
    let targetUrl =  serverUrl + '/src/bodybuildingplugs/' + this.bodyBuildingPlug._id

    axios.patch(targetUrl, params, {"headers" : headerRequest})
      .then(res => {
        if(res.status === 200){
          reactLocalStorage.set('@result', 207);
          sessionStorage.removeItem('@md_Exercise');
          sessionStorage.removeItem('@List_Exercise');
          setTimeout(() => {
            return window.location.reload(false);
          }, 2000);
        }
      }).catch(error => {
          this.setState({ show: true, loading: false })
          toast.error('Erro Inesperado!!');
          console.log('ERROR: ', error.response.status + ' [' + error.response.data.message +']');
        }
      );
  }

  render(){
    return (
        <>
            <MDBBtn outline color="primary" onClick={this.handleShow} title="Exercícios">
                <MDBIcon icon="dumbbell" size="lg" style={{ margin: "auto" }}/>
            </MDBBtn>
            <div className="some-modal-container">
            <Modal show={this.state.show} onHide={this.handleShow} centered size="lg">
                <Modal.Header style={{padding: '0px'}}>
                    <Modal.Title style={{width: '100%'}} >
                        <Stepper activeStep={this.state.activeStep} style={{width: '100%'}}>
                        <Step key={0}>
                            <StepLabel>Exercícios</StepLabel>
                        </Step>
                        <Step key={1}>
                            <StepLabel>Execução</StepLabel>
                        </Step>
                        </Stepper>
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div style={{display: 'flex'}}>
                        <Slide direction="right" timeout={{enter: 600, exit: 600}} in={this.state.checked} mountOnEnter unmountOnExit>
                            <Exercise
                                parentCallback={this.childReturn}
                                bodyBuildingPlug={this.bodyBuildingPlug}
                                itens={this.state.training}
                                letter={this.state.letter}/>
                        </Slide>
                        <Slide direction="left" timeout={{enter: 600, exit: 600}} in={!this.state.checked} mountOnEnter unmountOnExit>
                            <Execution
                                parentCallback={this.childReturn}
                                itens={this.state.training}
                                letter={this.state.letter}
                            />
                        </Slide>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button.Group>
                    {this.state.activeStep === 0 ?
                        <Button negative onClick={this.handleShow}><Icon name='remove'/> Cancelar</Button>
                    :
                        <Button color='blue' onClick={this.handleBack}><Icon name='angle double left'/> Anterior</Button>
                    }
                    <Button.Or text='ou' />
                    {this.state.activeStep === 1 ?
                        <Button positive onClick={this.handleSubmit}><Icon name='checkmark'/> Salvar</Button>
                    :
                        <Button color='blue' disabled={this.state.training.length === 0 && this.state.letter.length === 0 } onClick={this.handleNext}>Próximo <Icon name='angle double right'/></Button>
                    }
                    </Button.Group>
                </Modal.Footer>
            </Modal>
            { this.state.loading ?
                <Loading />
            : false }
            </div>
        </>
    );
  }
}
class Exercise extends Component {

    constructor(props){
        super()
        this.exerciseGroup = []
        this.bodyBuildingPlug = props.bodyBuildingPlug
        this.training = props.itens
        this.letter = props.letter
        this.optMuscleGroup = optMuscleGroup
        this.bodySelect = JSON.parse(sessionStorage.getItem('@bodySelect'))
        this.state={
            items: [],
            groupExercises: [],
            muscleGroup : "",
            selected: this.training === [] ? props.bodyBuildingPlug.training :  this.training,
            letter: this.letter === '' ? props.bodyBuildingPlug.letter : this.letter,
        }

    }
    componentDidMount() {
        this.getExercises()
    }

    getExercises = () => {
        let targetUrl = serverUrl + '/src/exercises'

        axios.get(targetUrl, {"headers" : headerRequest})
        .then(res => {
            if(res.status === 200){
                if(res.data){
                    this.exerciseGroup = res.data.items.reduce(function (r, a) {
                        r[a.muscleGroup] = r[a.muscleGroup] || [];
                        r[a.muscleGroup].push(a);
                        return r;
                    }, Object.create(null))
                }
            }
        }).catch(error => {
            this.setState({ show: true, loading: false })
            toast.error('Erro Inesperado!!');
            console.log('ERROR: ', error.response.status + ' [' + error.response.data.message +']');
        });

    }

    handleChange = (e, { value }) => this.filterMuscle(value)

    handleLetter = (e) => {
        let letter = e.target.value.toUpperCase()
        this.setState({ letter: letter })
        this.props.parentCallback({itens: this.state.selected, letter: letter})
    }

    filterMuscle (value) {

        this.getExercises()
        let muscleActive = []
        let bodyB = this.state.selected

        if(this.exerciseGroup !== undefined){
            muscleActive = this.exerciseGroup[value]
        }
        if(muscleActive !== undefined){

            if(muscleActive.length > 0 && bodyB.length > 0){
                for( var i=muscleActive.length - 1; i>=0; i--){
                    for( var j=0; j<bodyB.length; j++){
                        if(muscleActive[i] && muscleActive[i]._id === bodyB[j]._id){
                            muscleActive.splice(i, 1);
                        }
                    }
                }
            }
            this.setState({ items: muscleActive,  muscleGroup: value })
        }else{
            this.setState({ items: [],  muscleGroup: value })
        }
    }

    idList = {
        droppable: 'items',
        droppable2: 'selected'
    };

    getList = id => this.state[this.idList[id]];

    onDragEnd = result => {
        const { source, destination } = result;

        // dropped outside the list
        if (!destination) {
            return;
        }

        if (source.droppableId === destination.droppableId) {
            const items = reorder(
                this.getList(source.droppableId),
                source.index,
                destination.index
                );

                let state = { items };

                if (source.droppableId === 'droppable2') {
                    state = { selected: items };
                }

                this.props.parentCallback({itens: items, letter: this.state.letter})

            this.setState(state);
        } else {
            const result = move(
                this.getList(source.droppableId),
                this.getList(destination.droppableId),
                source,
                destination
                );

                this.setState({
                    items: result.droppable,
                    selected: result.droppable2
                });
                this.props.parentCallback({itens: result.droppable2, letter: this.state.letter})
            }
            console.log('result',result)
        };

        render(){
            return(
                <Card style={{width: '100%'}}>
                <Card.Body> 
                    <Row style={{marginBottom: "10px"}}>
                        <Col  className="col-6 text-left">
                            <ExerciseAdd />
                        </Col>
                        <Col className="col-6 text-center">
                            <Input
                                label={{ tag: true, content: 'Ficha' }}
                                labelPosition='right'
                                value={this.state.letter}
                                onChange={this.handleLetter}
                                className="col-6"
                            />
                        </Col>
                    </Row>
                    <Row style={{marginBottom: "10px"}}>
                        <Col>
                            <Dropdown
                                options={this.optMuscleGroup}
                                search
                                fluid
                                selection
                                value={this.state.muscleGroup}
                                onChange={this.handleChange}
                                className="col-12"
                                placeholder="Grupo Muscular"
                                />
                        </Col>
                    </Row>
                    <div style={{display: "flex",justifyContent: "space-between"}}>
                        <DragDropContext onDragEnd={this.onDragEnd} >
                        <Droppable droppableId="droppable">
                            {(provided, snapshot) => (
                                <div
                                    className="col-6 mr-2"
                                    ref={provided.innerRef}
                                    style={getListStyle(snapshot.isDraggingOver)}>
                                    {this.state.items ? this.state.items.map((item, index) => (
                                        <Draggable
                                            key={item._id}
                                            draggableId={item._id}
                                            index={index}>
                                            {(provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    style={getItemStyle(
                                                        snapshot.isDragging,
                                                        provided.draggableProps.style
                                                    )}>
                                                    {item.name}
                                                </div>
                                            )}
                                        </Draggable>
                                    )): false}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                        <Droppable droppableId="droppable2">
                            {(provided, snapshot) => (
                                <div
                                    className="col-6"
                                    ref={provided.innerRef}
                                    style={getListStyle(snapshot.isDraggingOver)}>
                                    {this.state.selected ? this.state.selected.map((item, index) => (
                                        <Draggable
                                            key={item._id}
                                            draggableId={item._id}
                                            index={index}>
                                            {(provided, snapshot) => (
                                                <div
                                                    ref={provided.innerRef}
                                                    {...provided.draggableProps}
                                                    {...provided.dragHandleProps}
                                                    style={getItemStyle(
                                                        snapshot.isDragging,
                                                        provided.draggableProps.style
                                                    )}>
                                                    {item.name}
                                                </div>
                                            )}
                                        </Draggable>
                                    )):false}
                                    {provided.placeholder}
                                </div>
                            )}
                        </Droppable>
                    </DragDropContext>
                </div>
                </Card.Body>
            </Card>
        )
    }
}

class Execution extends Component{

    constructor(props){
        super()
        this.exercises = props.itens
        this.state ={
            training: [],
        }
    }

    componentDidMount = () => {
        let count = 0
        const newList = this.props.itens.map( item => {
            count++
            return {
                order: count,
                _id: item._id,
                name: item.name,
                corporation: item.corporation,
                muscleGroup: item.muscleGroup,
                series: item.series ? item.series : 3,
                repeat: item.repeat ? item.repeat : '10',
                weight: item.weight ? item.weight : 0
            }
        });
        this.setState({ training: newList })
        this.props.parentCallback({itens: newList, letter: this.props.letter})
    }

    updateFieldChanged = index => e => {
        let newArr = [...this.state.training]; // copying the old datas array
        newArr[index][e.target.name] = e.target.name === 'repeat' ? e.target.value : parseFloat(e.target.value); // replace e.target.value with whatever you want to change it to
        this.setState({ training: newArr }); // ??
        this.props.parentCallback({itens: newArr, letter: this.props.letter})
    }

    render(){
        return(
            <>
                <Card style={{width: '100%'}}>
                    <Card.Body>
                        <Table compact celled definition>
                            <Table.Header className='text-center'>
                                <Table.Row>
                                    <Table.HeaderCell />
                                    <Table.HeaderCell>Série</Table.HeaderCell>
                                    <Table.HeaderCell>Repetição</Table.HeaderCell>
                                    <Table.HeaderCell>Carga</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {this.state.training.length > 0 ?
                                    this.state.training.map((item, index)=>(
                                        <Table.Row key={index}>
                                            <Table.Cell>
                                                <Label color='blue' ribbon>{item.order}</Label>
                                                <span>{item.name}</span>
                                            </Table.Cell>
                                            <Table.Cell className='text-center'>
                                                <Input
                                                    id={`series${index}`}
                                                    name="series"
                                                    type="number"
                                                    transparent
                                                    fluid
                                                    value={item.series}
                                                    onChange={this.updateFieldChanged(index)}
                                                />
                                            </Table.Cell>
                                            <Table.Cell className='text-center'>
                                                <Input
                                                    id={`repeat${index}`}
                                                    name="repeat"
                                                    transparent
                                                    fluid
                                                    value={item.repeat}
                                                    onChange={this.updateFieldChanged(index)}
                                                />
                                            </Table.Cell>
                                            <Table.Cell className='text-center'>
                                                <Input
                                                    id={`weight${index}`}
                                                    name="weight"
                                                    type="number"
                                                    transparent
                                                    fluid
                                                    value={item.weight}
                                                    onChange={this.updateFieldChanged(index)}
                                                />
                                            </Table.Cell>
                                        </Table.Row>
                                    ))
                                : false}
                            </Table.Body>
                        </Table>
                    </Card.Body>
                </Card>

            </>
        )
    }
}