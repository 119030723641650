import React, { Component } from 'react';
import axios from 'axios';

import { Redirect } from 'react-router-dom';
import { token, serverUrl } from '../lib/funApp/general';
import { toast, ToastContainer } from 'react-toastify';

import { MDBIcon, MDBBtn } from 'mdbreact';
import { Menu, Segment, Sidebar } from 'semantic-ui-react';
import { Container, Button as ButtonRB, FormGroup, FormControl, FormLabel, InputGroup, Row , Col} from "react-bootstrap";
import { Card } from "react-bootstrap";

import Loading from './loading';

import "./login.css";

import Particles from 'react-particles-js';

export default class Login extends Component {

  constructor(){
    super()
    this.targetUrl = serverUrl + '/src/users/authenticate'
    this.state = {
      visible: false,
      email: '',
      password: '',
      seePass: false,
      isLoading: false
    }
  }

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({isLoading: true})
    let email = this.state.email
    let password = this.state.password

    axios.post(this.targetUrl, { email, password })
      .then(res => {
        if(res.data.status){
          sessionStorage.setItem('@token',res.data.accessToken);
          sessionStorage.setItem('@corp',res.data.corporation);
          delete res.data.accessToken
          delete res.data.corporation
          sessionStorage.setItem('@acc',res.data.acc);
          delete res.data.acc
          let jsonUser = JSON.stringify(res.data);
              sessionStorage.setItem('@user',jsonUser);
              setTimeout(() => {
                return window.location.reload(false);
              }, 2000);
        }else{
          toast.error('Dados Incorretos!!')
          this.setState({ isLoading: false})
        }
      }).catch(error => {
          toast.error('Dados Incorretos!!')
          this.setState({ isLoading: false})
        }
      );
  }

  validateForm = () => {
    return this.state.email.length > 0 && this.state.password.length > 0;
  }

  render(){
    if(token){
      return <Redirect exact to='/home' refresh="true"/>
    }else{
      return(
        <>
        <Particles
              style={{position: "absolute"}}
              canvasClassName="exampleCanvas"
              params={{
                particles: {
                  number: {
                    value: 80,
                    density: {
                      enable: true,
                      value_area: 400,
                    }
                  },
                  color: {
                    value: "#ffffff"
                  },

                  line_linked: {
                    enable: true,
                    distance: 120,
                    color: "#35bce4",
                    width: 1
                  },
                },
                interactivity: {
                  events: {
                      onhover: {
                          enable: true,
                          mode: "repulse"
                      },
                      onclick: {
                        enable: true,
                      },
                  }
                },


              }}
            />
        <Sidebar.Pushable as={Segment}>
          <ToastContainer style={{ fontWeight: "bold" }}/>
          <Sidebar
            as={Menu}
            animation='push'
            icon='labeled'
            inverted
            onHide={e => this.setState({visible: false})}
            vertical
            direction='right'
            visible={this.state.visible}
            width='wide'
          >
            <div>
            <Container id='ContLogin'>
              <Row>
                <Col className="text-center">
                <Card style={{margin: '15px', backgroundColor: 'rgba(255, 255, 255, 0.27)', color: "#35bce4"}}>
                  <Card.Body className="text-center">
                    <MDBIcon fab icon="jedi-order" size="4x" />
                  </Card.Body>
                </Card>
                </Col>
              </Row>
              <Row>
                <Col><div id="return" className="w-100 font-weight-bold text-wrap mt-3 mb-3"></div></Col>
              </Row>
              <Row>
                <Col>
                  <form onSubmit={this.handleSubmit}>
                  <FormGroup controlId="email" bssize="large" className='text-left'>
                    <FormLabel className="LabelLogin"><strong>Email</strong></FormLabel>
                    <FormControl
                      autoFocus
                      type="email"
                      value={this.state.email}
                      onChange={e => this.setState({email: e.target.value})}
                    />
                  </FormGroup>
                  <FormGroup controlId="password" bssize="large" className='text-left'>
                    <FormLabel className="LabelLogin"><strong>Senha</strong></FormLabel>
                    <InputGroup>
                      <FormControl
                        value={this.state.password}
                        onChange={e => this.setState({password: e.target.value})}
                        type={this.state.seePass ? "text" : "password" }
                      />
                      <InputGroup.Prepend onClick={e => this.setState({seePass: !this.state.seePass})}>
                        <InputGroup.Text id="inputGroupPrepend"><MDBIcon far icon={this.state.seePass ? "eye" : "eye-slash"}/></InputGroup.Text>
                      </InputGroup.Prepend>
                    </InputGroup>
                  </FormGroup>
                  <ButtonRB
                    bssize="large"
                    disabled={!this.validateForm()}
                    type="submit"
                    id="btnLogin"
                    >
                    {this.state.isLoading ?
                    <div className="spinner-border fast" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                    :  <strong>login</strong> }
                  </ButtonRB>
                </form>
              </Col>
            </Row>
          </Container>
          </div>
        </Sidebar>

        <Sidebar.Pusher dimmed={this.state.visible}>
          <div className="Login">
            <MDBBtn
              style={{ borderRadius: '20px' }}
              gradient="blue"
              onClick={e => this.setState({ visible: !this.state.visible})}
              className="float-right m-3">
              <strong>Entrar</strong>
              <MDBIcon icon="check" className="ml-2" size="lg" />
            </MDBBtn>
          </div>
        </Sidebar.Pusher>
      </Sidebar.Pushable>
      {this.state.isLoading ?
        <Loading main={true} />
        : false}
      </>
      )
    }
  }
}