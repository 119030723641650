import React, { Component } from 'react'
import ReactDOM from 'react-dom';
import axios from 'axios';
import moment from 'moment'
import DateFnsUtils from '@date-io/date-fns';
import CurrencyFormat from 'react-currency-format';
import { toast, ToastContainer } from 'react-toastify';
import { reactLocalStorage } from 'reactjs-localstorage';
import { RandomHash } from 'random-hash';
import { randomBytes } from 'crypto';

import { serverUrl , headerRequest, categoriesFilter, UserId } from '../../lib/funApp/general'
import '../categories/categories.css'

import { Modal, Card } from "react-bootstrap";
import { Grid, Select, InputLabel, MenuItem, FormControl, TextField } from '@material-ui/core';
import Autocomplete, {createFilterOptions} from "@material-ui/lab/Autocomplete";
import { Button, Dropdown, Label, Icon, Table, Input } from 'semantic-ui-react';
import { MDBIcon } from 'mdbreact';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

import { MDBContainer, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';

import Loading from  '../../containers/loading';

const filter = createFilterOptions();

const generateHash = new RandomHash({
  length: 18,
  charset: 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789-_',
  rng: randomBytes
});

const targetUrl = serverUrl + '/src/records'

export default class RecordAdd extends Component{

  constructor(){
    super()
    this.state ={
      show: false,
      showCat: false,
      color: 'grey',
      icon: 'plane-arrival',
      activeStep: 0,
      checked: true,
      dataAccount: [],
      dataPeople: [],
      expenses: [],
      incomes: [],
      tpCat: 'S',
      type: '',
      date: sessionStorage.getItem('@dateRecord') ? sessionStorage.getItem('@dateRecord') : moment()._d,
      account: sessionStorage.getItem('@accountStatement') ? sessionStorage.getItem('@accountStatement') : '',
      accountOrigin: '',
      number: '',
      value: '',
      history: '',
      person: '',
      category: [],
      total: 0,
      teste: 0
    }
  }

  setInputFocus() {
    ReactDOM.findDOMNode(this.myInputRef).focus();
  }

  componentDidMount(){

    const peopleFilter = function(data){
      let result = []
        data.map((item, index)=>(
          result.push({ key: item._id, value: item._id, text:(index + 1) + ' - ' + item.name})
      ))
      return result
    }

    const reqAccounts   = axios.get((serverUrl + '/src/accounts'  ), {"headers" : headerRequest})
    const reqPeople     = axios.get((serverUrl + '/src/peoples'   ), {"headers" : headerRequest})
    const reqCategories = axios.get((serverUrl + '/src/categories'), {"headers" : headerRequest})

    axios.all([reqAccounts,reqPeople, reqCategories])
    .then(axios.spread((...responses) => {
      this.setState({
        dataAccount: responses[0].data.items,
        dataPeople: peopleFilter(responses[1].data.items),
        expenses: categoriesFilter({categories: responses[2].data.items},'D'),
        incomes: categoriesFilter({categories: responses[2].data.items},'R')
      })
    })).catch(errors => {
      console.log(...errors)
   });

  }

  handleShow = () => {this.setState(prevstate => ({ show: !prevstate.show }))}
  handleShowCat = () => { this.setState(prevstate => ({ showCat: !prevstate.showCat })) }
  handleBack = () => {this.setState((prevState) => ({ activeStep: prevState.activeStep - 1, checked: !prevState.checked }))};
  handleNext = () => {this.setState((prevState) => ({ activeStep: prevState.activeStep + 1, checked: !prevState.checked }))};

  changeAccount = (value) => {
    this.setState({ account: value })
    sessionStorage.setItem('@accountStatement', value);
  }
  changeDate    = (value) => {
    this.setState({ date: value })
    sessionStorage.setItem('@dateRecord', value);
  }
  changeType = (type, color, icon) => { this.setState({ type: type, color: color, icon: icon, category: [], }) }
  changeNumber  = (value) => { this.setState({ number: value }) }
  changeValue   = (value) => { this.setState({ value: value }) }
  changeHistory = (value) => { this.setState({ history: value }) }
  changeAccountOrigin = (value) => { this.setState({ accountOrigin: value }) }

  changTeste = (value) => {

    this.setState({ teste: value })

  }

  changeTpCat   = (value) => {
    if(value === 'S'){
      this.setState({ category: []})
    }
    this.setState({ tpCat: value })
  }

  changePerson  = (value) => {
    if (value && value.freeSolo) {
      this.setState({
        person: { key: '000', value: '000', text: value.inputValue}
      })
      toast.info('Pessoa será Cadastrada ao Gravar o Lançamento!!')
      return;
    }
    this.setState({ person:value })
  }

  changeCategory = (id, name) => {

    const found = this.state.category.find(element => element.id === id);

    if(found === undefined){
      this.setState((prevState) => ({
        category: [...prevState.category, {id: id, history:'', name: name, value: 0}],
      }))

    }else{

      const newArray = this.state.category.filter(function( obj ) {
        return obj.id !== id;
      });

      this.setState({ category: newArray})
    }

    if(this.state.tpCat === 'S'){
      this.handleShowCat()
    }

  }

  showCategories = (item, index) => {

    const found = this.state.category.find(element => element.id === item.id);
    const display = found === undefined ? {display: 'none'} : {display: 'contents'}

    let comp =  <li className='mb-2' style={{cursor: 'pointer'}} key={index}>
                  <Label color={found === undefined ? this.state.color : 'green'} basic pointing='left' disabled={true} onClick={e => this.changeCategory(item.id, item.name)}>
                    <span id='labelInterna'>{item.name}</span>
                  </Label>
                    <i data-test="fa" className="far fa-check-circle text-success" id={'catCheck'+item.id} name='catCheck' style={display}></i>
                </li>

    if(item.child.length > 0){
      let childs =
        <ul className='tree' key={`ul${index}`}>
          {item.child.map((item, index)=>(
            this.showCategories(item, index)
          ))}
        </ul>
      return [comp, childs]
    }
    return comp
  }

  totalValueCat = (newArr) => {

    let total = 0;
    newArr.map((item, i)=>(
      total = total + item.value
    ))
    this.setState({total: total})
  }

  updateFieldChanged = (index, event)=> {

    if(event.name === 'valueCat'){
      if(event.value >= 0){
        let newArr = [...this.state.category];
        newArr[index]['value'] = parseFloat(event.value);
        this.setState({ category: newArr });
        this.totalValueCat(newArr)
      }
    }else{
      if(event.value.length > 0){
        let newArr = [...this.state.category];
        newArr[index]['history'] = event.value.trim();
        this.setState({ category: newArr });
      }
    }
  }

  saveCat = () => {
    this.setState((prevState)=>({value: prevState.total}))
    this.handleShowCat()
  }

  validateSubmit = () => {

    let isOK = true

    if(this.state.type  !== 'T'){

      if(this.state.person === ''){
        toast.error('Informe a Pessoa!!')
        isOK = false
        setTimeout(
          function() {
            document.getElementById("combo-box-person").focus();
        },
        500
        )
      }
      if(!this.state.category.length > 0){
        toast.error('Informe a Categoria!!')
        isOK = false
        setTimeout(
          function() {
            document.getElementById("BtnCat").style.cssText = 'background-color: white !important'
          },
          500
        )
        setTimeout(
          function() {
            document.getElementById("BtnCat").style.cssText = ''
          },
          1000
          )
          setTimeout(
            function() {
              document.getElementById("BtnCat").style.cssText = 'background-color: white !important'
            },
            1500
            )
            setTimeout(
              function() {
                document.getElementById("BtnCat").style.cssText = ''
              },
          2000
          )
        }
      }else{
        if(this.state.accountOrigin === ''
          && this.state.account !== this.state.accountOrigin){
          toast.error('Informe a Conta de Origem!!')
          isOK = false
          setTimeout(
            function() {
              document.getElementById("origin-select").focus();
          },
          500
          )
        }
        if(this.state.accountOrigin === ''
            && this.state.account !== this.state.accountOrigin){
            toast.error('Informe o Historico!!')
          isOK = false
          setTimeout(
            function() {
              document.getElementById("textHistory").focus();
          },
          600
          )
        }
      }

      if(isOK){
        this.onSubmit()
      }
  }

  savePerson = async (name) => {

    let peopleInsert = {}
    peopleInsert.name = name

    await axios.post((serverUrl + '/src/peoples'), peopleInsert, {"headers" : headerRequest})
    .then(res => {
      if(res.status === 200){
        let result =  [{ key: res.data._id, value: res.data._id, text:'00 - ' + res.data.name}, ...this.state.dataPeople]
        this.setState({ dataPeople: result, person: {key: res.data._id, name: res.data.name} })
        toast.success('Pessoa Cadastrada!!')
      }
    }).catch(error => {
      console.log(error)
    })
  }


  onSubmit = async () => {

    this.setState({ loading: true })

    let params = {}

    params.type = this.state.type
    params.date = this.state.date
    params.account = this.state.account
    params.value = this.state.type === 'D' ? this.state.value *-1 : this.state.value
    if(this.state.history){ params.history = this.state.history}

    if (this.state.type === "T"){

      let dateNow = new Date()
      params.number = generateHash() + '|' + moment(dateNow).format('YYYYMMDDhmmss') + '|' +  UserId + '|' + this.state.account

    }else{

      if(this.state.number){ params.number = this.state.number }
      if(this.state.person.key === '000'){

        await this.savePerson(this.state.person.text);
        params.person = this.state.person.key

      }else{
        params.person = this.state.person.key
      }

      if(this.state.tpCat === 'S'){
        params.category = [{
          order: 1,
          category: this.state.category[0].id,
          history: this.state.history,
          value: this.state.value
        }]
      }else{
        params.category = []
        this.state.category.map((item, i)=>(
          params.category.push({order:(i+1), category: item.id, history: item.history, value: item.value})
        ))
      }
    }
    await axios.post(targetUrl, params, {"headers" : headerRequest})
      .then(async res => {
        if(res.status === 200){

          if(this.state.type === "T"){

            params.account = this.state.accountOrigin
            params.value = this.state.value * -1
            params.counterpart = res.data._id
            let newTargetUrl = targetUrl+'/'+ res.data._id

            await axios.post(targetUrl, params, {"headers" : headerRequest})
              .then(async res => {
                if(res.status === 200){

                  let newParams = {}
                  newParams.counterpart = res.data._id

                  await axios.patch(newTargetUrl, newParams, {"headers" : headerRequest})
                  .then(res => {
                    if(res.status === 200){
                      reactLocalStorage.set('@result', 200);
                      window.location.reload(false)
                    }
                  }).catch(error => {
                    console.log(error)
                    }
                  );
              }}
              ).catch(error => {
                  console.log(error)
              }
            );
          }else{
            reactLocalStorage.set('@result', 200);
            window.location.reload(false)
          }
        }
      }).catch(error => {
          console.log(error)
        }
      );
    }

  render(){
    return(
      <>
       <ToastContainer style={{ fontWeight: "bold" }}/>
        <Button circular
          positive
          icon='plus'
          onClick={this.handleShow}
          className="float-right m-2"
          title="Cadastrar Conta"
        />
        <Modal show={this.state.show} onHide={this.handleShow} centered size="lg">
          <Modal.Header style={{padding: '5px'}}>
              <Button.Group color={this.state.color} style={{width: '100%'}}>
                <Dropdown
                  text={this.state.type === 'D' ? 'Despesa' : this.state.type === 'R' ? 'Receita' : this.state.type === 'T' ? 'Transferência' : 'Escolha o Tipo...'}
                  icon={this.state.type === 'D' ? 'minus' : this.state.type === 'R' ? 'plus' : this.state.type === 'T' ? 'random' : 'share'}
                  labeled
                  button
                  className='icon'
                  fluid
                  >
                  <Dropdown.Menu>
                    <Dropdown.Header content='Tipo Lançamento' />
                    <Dropdown.Divider />
                    <Dropdown.Item className='text-danger' onClick={e => this.changeType('D','red','minus-square')} >
                      <MDBIcon icon="minus-square" className='mr-2'/>Despesa
                    </Dropdown.Item>
                    <Dropdown.Item className='text-primary' onClick={e => this.changeType('R','blue','plus-square')} >
                      <MDBIcon icon="plus-square" className='mr-2'/>Receita
                    </Dropdown.Item>
                    <Dropdown.Item className='text-success' onClick={e => this.changeType('T','green','random')} >
                      <MDBIcon icon="random" className='mr-2'/>Transferência
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </Button.Group>
          </Modal.Header>
          <Modal.Body style={{padding: '7px'}}>
            <Card>
              <Card.Body>
                <Grid
                  container
                  spacing={3}
                  direction="row"
                  justify="center"
                  alignItems="center"
                >
                  <Grid item xs={12}>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        margin="normal"
                        id="date-picker-Ini"
                        label="Data"
                        value={this.state.date}
                        onChange={e => this.changeDate(e)}
                        format="dd/MM/yyyy"
                        KeyboardButtonProps={{
                            'aria-label': 'Alterar Data',
                        }}
                        style={{marginTop:"0px", float:"right",width: "150px"}}
                      />
                    </MuiPickersUtilsProvider>
                  </Grid>
                  <Grid item xs={12} md={6}>

                    {this.state.type === 'T' ?
                      <FormControl className={"col-12"}>
                        <InputLabel id="select-origin">Conta Origem</InputLabel>
                        <Select
                          labelId="select-origin"
                          id="origin-select"
                          value={this.state.accountOrigin}
                          onChange={e => this.changeAccountOrigin(e.target.value)}
                          className={"col-12"}
                        >
                          {this.state.dataAccount !== undefined ?
                            this.state.dataAccount.map((item, index)=>(
                            <MenuItem key={index} value={item._id}>
                              <div className="w-100">
                                <span>{item.name}</span>
                                <CurrencyFormat
                                  value={item.balance}
                                  displayType={'text'}
                                  thousandSeparator="."
                                  decimalSeparator=","
                                  fixedDecimalScale={true}
                                  decimalScale={2}
                                  className="float-right"
                                />
                              </div>
                            </MenuItem>
                          )): false}
                        </Select>
                      </FormControl>
                      :
                      <Autocomplete
                        id="combo-box-person"
                        options={this.state.dataPeople}
                        getOptionLabel={(option) => option.text}
                        style={{ width: '100%' }}
                        renderInput={(params) => <TextField {...params} label="Pessoa"/>}
                        freeSolo
                        onChange={(e, value) => { this.changePerson(value) }}
                        filterOptions={(options, params) => {
                          const filtered = filter(options, params);
                          if (params.inputValue !== "") {
                            filtered.push({
                              freeSolo: true,
                              inputValue: params.inputValue,
                              text: `Adic. "${params.inputValue}"`
                            });
                          }
                          return filtered;
                        }}
                        ref={c => (this.myInputRef = c)}
                      /> }
                  </Grid>
                  <Grid item xs={12} md={6}>
                    <FormControl className={"col-12"}>
                      <InputLabel id="select-destiny">Conta Destino</InputLabel>
                      <Select
                        labelId="select-destiny"
                        id="destiny-select"
                        value={this.state.account}
                        onChange={e => this.changeAccount(e.target.value)}
                        className={"col-12"}
                      >
                        {this.state.dataAccount !== undefined ?
                          this.state.dataAccount.map((item, index)=>(
                          <MenuItem key={index} value={item._id}>
                            <div className="w-100">
                              <span>{item.name}</span>
                              <CurrencyFormat
                                value={item.balance}
                                displayType={'text'}
                                thousandSeparator="."
                                decimalSeparator=","
                                fixedDecimalScale={true}
                                decimalScale={2}
                                className="float-right"
                              />
                            </div>
                          </MenuItem>
                        )): false}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      autoFocus
                      type="text"
                      value={this.state.number}
                      label="Número"
                      className={"col-12"}
                      onChange={e => this.changeNumber(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      autoFocus
                      type="number"
                      value={this.state.value}
                      label="Valor"
                      className={"col-12"}
                      onChange={e => this.changeValue(e.target.value)}
                      disabled={this.state.category.length > 1 ? true : false}  
                      />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      autoFocus
                      id='textHistory'
                      type="text"
                      value={this.state.category.length > 1 ? 'Diversos' : this.state.history}
                      label="Historico"
                      className={"col-12"}
                      onChange={e => this.changeHistory(e.target.value)}
                      disabled={this.state.category.length > 1 ? true : false}
                    />
                  </Grid>
                  {this.state.value > 0 ?
                    <Grid item xs={12}>
                      {this.state.type !== 'T' && this.state.type !== '' ?
                        <>
                        <Button as='div' labelPosition='right' onClick={this.handleShowCat} >
                          <Button primary color={this.state.color} id='BtnCat'>
                            <Icon name='sitemap' />
                          </Button>
                          <Label as='a' basic pointing='left' color={this.state.color}>
                            {this.state.tpCat === 'M' && this.state.category.length > 1 ?
                              'Diversas'
                            : this.state.category[0] !== undefined ? this.state.category[0].name : 'Escolha a Categoria...'
                            }
                          </Label>
                        </Button>
                        </>
                      : false }
                    </Grid>
                  : false}
                </Grid>
              </Card.Body>
            </Card>


          </Modal.Body>
          {this.state.type !== ''
            && this.state.account !== ''
              && this.state.value > 0
                && moment(this.state.date).isValid() ?

                    <Modal.Footer>
                      <Button.Group size='small'>
                        <Button negative onClick={this.handleShow}>Cancelar</Button>
                        <Button.Or text='ou' />
                        <Button positive onClick={this.validateSubmit}>Gravar</Button>
                      </Button.Group>
                    </Modal.Footer>

            : false}

    <MDBContainer>
      <MDBModal isOpen={this.state.showCat} toggle={this.showCat} centered size='md' >
        <MDBModalHeader toggle={this.showCat}>
          <div style={{width: '100%'}}>
            {this.state.checked ?
              <Dropdown  text={this.state.tpCat === 'S' ? 'Simples' : 'Multiplo'} simple item className='mr-2 float-right' style={{fontSize: 'large'}}>
                <Dropdown.Menu>
                  <Dropdown.Item text='Simples' onClick={e => this.changeTpCat('S')} />
                  <Dropdown.Item text='Multiplo' onClick={e => this.changeTpCat('M')} />
                </Dropdown.Menu>
              </Dropdown>
            : <div className="float-right">Valores</div>}
          </div>
        </MDBModalHeader>
        <MDBModalBody>
        <div style={{display: 'flex'}}>
               {this.state.activeStep === 0 ?

                  <div style={{width: '100%'}}>
                  {this.state.type === 'D' ?
                    <div className="mb-2 text-danger">
                      <MDBIcon icon="plane-arrival" size="lg"/> <strong>Despesas</strong>
                    </div>
                  :
                    <div className="mb-2 text-primary">
                      <MDBIcon icon="plane-departure" size="lg"/> <strong>Receitas</strong>
                    </div>
                  }
                  {this.state.type === 'D' ?
                      <div className="cltDanger">
                      <ul className='tree'>
                        {this.state.expenses.length > 0 ?
                          this.state.expenses.map((item, i1)=>(
                            this.showCategories(item,i1)
                          ))
                          : false}
                      </ul>
                    </div>
                  :
                    <div className="cltPrimary">
                      <ul className='tree'>
                        {this.state.incomes.length > 0 ?
                          this.state.incomes.map((item, i1)=>(
                            this.showCategories(item,i1)
                            ))
                            : false}
                      </ul>
                    </div>
                  }
                  </div>
                :
                <div style={{width: '100%'}}>
                      <Button as='div' labelPosition='right' style={{width: '100%'}}>
                        <Button basic color='blue'>
                          <Icon name='fork' />
                          <CurrencyFormat
                              value={this.state.value}
                              displayType={'text'}
                              thousandSeparator="."
                              decimalSeparator=","
                              fixedDecimalScale={true}
                              decimalScale={2}
                              className="float-right"
                              prefix="Valor: "
                            />
                        </Button>
                        <Label as='a' basic
                          color={
                            this.state.total < this.state.value ? 'red': this.state.total > this.state.value ? 'green': 'blue'
                          } pointing='left'>
                          <CurrencyFormat
                              value={this.state.total}
                              displayType={'text'}
                              thousandSeparator="."
                              decimalSeparator=","
                              fixedDecimalScale={true}
                              decimalScale={2}
                              className="float-right"
                              prefix="Total: "
                            />
                        </Label>
                      </Button>
                        <Table compact celled definition>
                            <Table.Header className='text-center'>
                                <Table.Row>
                                    <Table.HeaderCell />
                                    <Table.HeaderCell>Historico</Table.HeaderCell>
                                    <Table.HeaderCell>Valor</Table.HeaderCell>
                                </Table.Row>
                            </Table.Header>
                            <Table.Body>
                                {this.state.category.length > 0 ?
                                    this.state.category.map((item, index)=>(
                                        <Table.Row key={index}>
                                            <Table.Cell>
                                                <Label color='blue' ribbon>{index+1}</Label>
                                                <span>{item.name}</span>
                                            </Table.Cell>
                                            <Table.Cell className='text-center' style={{minWidth: "100px"}}>
                                              <Input
                                                    id={`histCat${index}`}
                                                    name="histCat"
                                                    type="text"
                                                    defaultValue={item.history}
                                                    transparent
                                                    fluid
                                                    onChange={event =>this.updateFieldChanged(index,event.target)}
                                                />
                                            </Table.Cell>
                                            <Table.Cell className='text-center' style={{minWidth: "100px"}}>
                                              <Input
                                                    id={`valueCat${index}`}
                                                    name="valueCat"
                                                    type="number"
                                                    defaultValue={item.value}
                                                    transparent
                                                    fluid
                                                    onChange={event =>this.updateFieldChanged(index,event.target)}
                                                />
                                            </Table.Cell>
                                        </Table.Row>
                                    ))
                                : false}
                            </Table.Body>
                        </Table>
                    </div>
                      }
                  </div>
        </MDBModalBody>
          <MDBModalFooter>
            {this.state.tpCat ===  'M' ?
                  <Button.Group>
                  {this.state.activeStep === 0 ?
                      <Button negative onClick={this.handleShowCat}><Icon name='remove'/> Cancelar</Button>
                  :
                      <Button color='blue' onClick={this.handleBack}><Icon name='angle double left'/> Anterior</Button>
                  }
                  <Button.Or text='ou' />
                  {this.state.activeStep === 1 ?
                      <Button positive disabled={this.state.total > 0 ? false : true} onClick={this.saveCat}><Icon name='checkmark'/> Salvar</Button>
                  :
                      <Button color='blue' disabled={this.state.category.length > 1 ? false : true} onClick={this.handleNext}>Próximo <Icon name='angle double right'/></Button>
                  }
                  </Button.Group>
            : <Button negative onClick={this.handleShowCat}><Icon name='remove'/> Fechar</Button> }
            </MDBModalFooter>
      </MDBModal>
    </MDBContainer>

          </Modal>
          {this.state.loading ?
              <Loading main={true} />
          : false}
      </>
    )
  }
}

/*
import axios from 'axios';
import moment from 'moment'
import Parse from 'html-react-parser';
import '../categories/categories.css'

import { serverUrl , headerRequest, categoriesFilter, UserId } from '../../lib/funApp/general'

import { Form, FormGroup, Row , Col, Modal, Card, Container } from "react-bootstrap";
import { MDBIcon, MDBBtn } from 'mdbreact';
import { reactLocalStorage } from 'reactjs-localstorage';
import { Dialog,DialogActions, DialogContent, DialogTitle, Slide } from '@material-ui/core';
import { RandomHash } from 'random-hash';
import { randomBytes } from 'crypto';
import { withStyles } from '@material-ui/core/styles';
import { green } from '@material-ui/core/colors';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';

import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import DateFnsUtils from '@date-io/date-fns';
import CurrencyFormat from 'react-currency-format';



import PeoplaAdd from '../peoples/peopleAdd';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const targetUrl = serverUrl + '/src/records'
const targetUrl1 = serverUrl + '/src/categories'
const targetUrl2 = serverUrl + '/src/peoples'
const targetUrl3 = serverUrl + '/src/accounts'

const GreenRadio = withStyles({
  root: {
    color: green[400],
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
})(props => <Radio color="default" {...props} />);

const generateHash = new RandomHash({
  length: 18,
  charset: 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789-_',
  rng: randomBytes
});

function RecordAdd() {

    const [show, setShow] = useState(false);
    const [open, setOpen] = useState(false);
    const [color, setColor] = useState("");
    const [isLoading, setLoading] = useState(false);

    const [catData, setCatData] = useState([]);
    const [peoData, setPeoData] = useState([]);
    const [accData, setAccData] = useState([]);
    const [peoRender, setPeoRender] = useState([]);

    const [expenses, setExpenses] = useState({});
    const [incomes, setIncomes] = useState({});
    const [selectItens, setSelectItens] = useState(expenses);

    const [selectedDate, setSelectedDate] = useState(Date.now);
    const [account, setAccount] = useState(sessionStorage.getItem('@accountStatement') ? sessionStorage.getItem('@accountStatement') : '');
    const [accountOrigin, setAccountOrigin] = useState('');
    const [history, setHistory] = useState("");
    const [value, setValue] = useState("");
    const [number, setNumber] = useState("");
    const [type, setType] = useState("");
    const [categories, setCategories] = useState([]);

    const handleClickOpen = () => {
      setOpen(true)
      setCategories([])
    };

    const handleClickClose = () => setOpen(false);
    const handleClose = () => setShow(false);
    const handleDateChange = date => {setSelectedDate(date)};
    const handleChange = event => { sessionStorage.setItem('@accountStatement',event.target.value); setAccount(event.target.value)};
    const handleChangeOrigin = event => {setAccountOrigin(event.target.value)};
    const handleChangeRadio = event => {
      setType(event.target.value);
      validateType (event.target.value)
    };
    const handleShow = () => {
      axios.get(targetUrl1, {"headers" : headerRequest})
        .then(res => {
          if(res.status === 200){
            setCatData(res.data);
            setExpenses(categoriesFilter({categories: res.data.items},'D'));
            setIncomes(categoriesFilter({categories: res.data.items},'R'));
          }
        }).catch(error => {
            console.log(error.response)
        });
        axios.get(targetUrl2, {"headers" : headerRequest})
        .then(res => {
          if(res.status === 200){
            let params1 = []
            res.data.items.map((item, index)=>(
              params1.push({ value: (index + 1) + ' - ' + item.name})
            ))
            setPeoRender(params1)
            setPeoData(res.data);
          }
        }).catch(error => {
            console.log(error.response)
        });
        axios.get(targetUrl3, {"headers" : headerRequest})
        .then(res => {
          if(res.status === 200){
            setAccData(res.data);
          }
        }).catch(error => {
            console.log(error.response)
        });
        setShow(true);
    }

    function validateType (value){
      if(value === 'D'){
        setSelectItens(expenses)
        setColor("danger")
      }
      if(value === 'R'){
        setSelectItens(incomes)
        setColor("primary")
      }
      if(value === 'T'){
        setSelectItens(incomes)
        setColor("success")
      }
    }

    function validateForm() {
      let people = document.getElementById('combo-box') !== null ? document.getElementById('combo-box').value : ''
      let valid1 = type.length > 0 && account.length > 0 && value.length > 0
      let valid2 = categories.length > 0 && people.length > 0
      let valid3 = true
      let valid4 = true
     //     && selectedDate.length > 0
      if(type === "T"){
        valid2 = true
        valid3 = accountOrigin.length > 0
        valid4 = accountOrigin !== account
      }

      return valid1 && valid2 && valid3 && valid4
    }

    function handleSubmit(event) {
      event.preventDefault();
      setLoading(true)

      let params = {}

      if(type){ params.type = type }
      if(selectedDate){ params.date = selectedDate }
      if(account){ params.account = account }
      if(value){ params.value = type === 'D' ? value *-1 : value }
      if(history){ params.history = history }

      if (type === "T"){
        let dateNow = new Date()
        params.number = generateHash() + '|' + moment(dateNow).format('YYYYMMDDhmmss') + '|' +  UserId
      }else{
        let person = getPeopleId()
        if(person){ params.person = person }
        if(number){ params.number = number }
        if(categories){
          let temp = {}
          if(categories.length === 1){
            temp = [{order: 1, category: categories[0]._id, history: history, value: value}]
          }
          params.category = temp
        }
      }

      axios.post(targetUrl, params, {"headers" : headerRequest})
        .then(res => {
          if(res.status === 200){
<MDBIcon icon={type === "D"? "plane-arrival" : type === "R"? "plane-departure" : type === "T"? "exchange-alt" : "plane" } className="mr-2" />Lançamento { type === "D"? "de Despesas" : type === "R"? "de Receitas" : type === "T"? "de Transferência" : "" }
            if(type === "T"){

              params.account = accountOrigin
              params.value = value * -1

              axios.post(targetUrl, params, {"headers" : headerRequest})
                .then(res => {
                  if(res.status === 200){
                    reactLocalStorage.set('@result', 200);
                    window.location.reload(false)
                  }
                }).catch(error => {
                    document.getElementById("return").innerHTML = error.response.status + ' [' + error.response.data.message +']'
                    document.getElementById("return").classList.add("badge", "badge-danger")
                    document.getElementById("return").classList.add("mt-3", "mb-3")
                  }
              );

            }else{
              reactLocalStorage.set('@result', 200);
              window.location.reload(false)
            }
          }
        }).catch(error => {
            document.getElementById("return").innerHTML = error.response.status + ' [' + error.response.data.message +']'
            document.getElementById("return").classList.add("badge", "badge-danger")
            document.getElementById("return").classList.add("mt-3", "mb-3")
          }
      );

    }

    let categoriesString = JSON.stringify(selectItens)
    categoriesString = categoriesString.replace(/\[]/g, "")
                  .replace(/\[/g, "<ul class='tree'>")
                  .replace(/\]/g, "</ul>")
                  .replace(/\{/g, "")
                  .replace(/\}/g, "")
                  .replace(/"/g, "")
                  .replace(/id:/g, '<li><label><input type="radio" class="option-input radio" name="rads" value="')
                  .replace(/,child:/g, '</label></li>')
                  .replace(/,/g, "")
                  .replace(/name:/g, '">')

    function handleSubmit2(event){
      event.preventDefault();
      let radVal = document.mainForm.rads.value;
      if(radVal.length > 0 && catData.items.length > 0){
        catData.items.map((item, index)=>(
            radVal === item._id ?
              delete item._links ?
                setCategories([...categories, item])
              : false
            : false
        ))
      }
      handleClickClose()
    }
    function getPeopleId(){
      let var1 = document.getElementById('combo-box').value
      let var2 = var1.split('-')
      let var3 = parseInt(var2) -1
      return peoData.items[`${var3}`]._id
    }

    return (
      <>
      <Button circular
        positive
        icon='plus'
        onClick={handleShow}
        className="float-right m-2"
        title="Cadastrar Conta"
      />
      <Modal show={show} onHide={handleClose} centered size="lg">

          <Modal.Header closeButton>
          <Modal.Title className={'text-'+color}>
            <MDBIcon icon={type === "D"? "plane-arrival" : type === "R"? "plane-departure" : type === "T"? "exchange-alt" : "plane" } className="mr-2" />Lançamento { type === "D"? "de Despesas" : type === "R"? "de Receitas" : type === "T"? "de Transferência" : "" }
          </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Card>
                <Card.Body>
                  <Row>
                    <Col><div id="return" className="w-100 font-weight-bold text-wrap"></div></Col>
                  </Row>
                  <Row className="justify-content-center">
                    <Col className="col-12">

                        <Grid container spacing={3}>

                            <Grid item xs={12} md={8}>
                                <FormControl component="fieldset">
                                    <RadioGroup aria-label="position" name="position" value={type} onChange={handleChangeRadio} row>
                                        <FormControlLabel
                                        value="D"
                                        control={<Radio />}
                                        label="Despesa"
                                        className="text-danger"
                                        />
                                        <FormControlLabel
                                        value="R"
                                        control={<Radio color="primary" />}
                                        label="Receita"
                                        className="text-primary"
                                        />
                                        <FormControlLabel
                                        value="T"
                                        control={<GreenRadio />}
                                        label="Transferência"
                                        className="text-success"
                                        />
                                    </RadioGroup>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} md={4}>
                              <Form.Group controlId="dateStart">
                                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                                    <KeyboardDatePicker
                                    margin="normal"
                                    id="date-picker-dialog"
                                    label="Data"
                                    format="dd/MM/yyyy"
                                    value={selectedDate}
                                    onChange={handleDateChange}
                                    KeyboardButtonProps={{
                                        'aria-label': 'Alterar Data',
                                    }}
                                    style={{marginTop:"0px", float:"right",width: "150px"}}
                                    disabled={!type.length > 0}
                                    />
                                </MuiPickersUtilsProvider>
                              </Form.Group>
                            </Grid>

                            {type !== "T" ?
                              <Grid item xs={12} md={6} style={{display: "flex"}}>
                                <Autocomplete
                                  id="combo-box"
                                  options={peoRender}
                                  getOptionLabel={option => option.value}
                                  disabled={!type.length > 0}
                                  renderInput={params => (
                                    <TextField {...params} label="Pessoa..." fullWidth />
                                  )}
                                  style={{flex: "auto"}}
                                />
                                {type.length > 0 ?
                                  <PeoplaAdd props={'RcAdd'} />
                                :false}
                              </Grid>
                            :
                              <Grid item xs={12} md={6}>
                                <FormControl className={"col-12"}>
                                  <InputLabel id="select-origin">Conta Origem</InputLabel>
                                  <Select
                                    labelId="select-origin"
                                    id="origin-select"
                                    value={accountOrigin}
                                    onChange={handleChangeOrigin}
                                    className={"col-12"}
                                    disabled={!type.length > 0}
                                  >
                                    {accData.items !== undefined ?
                                      accData.items.map((item, index)=>(
                                      <MenuItem key={index} value={item._id}>
                                        <div className="w-100">
                                          <span>{item.name}</span>
                                          <CurrencyFormat
                                              value={item.balance}
                                              displayType={'text'}
                                              thousandSeparator="."
                                              decimalSeparator=","
                                              fixedDecimalScale={true}
                                              decimalScale={2}
                                              className="float-right"
                                          />
                                        </div>
                                      </MenuItem>
                                    )): false}
                                  </Select>
                                </FormControl>
                              </Grid>
                            }
                            <Grid item xs={12} md={6}>
                              <FormControl className={"col-12"}>
                                <InputLabel id="select-destiny">Conta Destino</InputLabel>
                                <Select
                                  labelId="select-destiny"
                                  id="destiny-select"
                                  value={account}
                                  onChange={handleChange}
                                  className={"col-12"}
                                  disabled={!type.length > 0}
                                >
                                  {accData.items !== undefined ?
                                    accData.items.map((item, index)=>(
                                    <MenuItem key={index} value={item._id}>
                                      <div className="w-100">
                                        <span>{item.name}</span>
                                        <CurrencyFormat
                                              value={item.balance}
                                              displayType={'text'}
                                              thousandSeparator="."
                                              decimalSeparator=","
                                              fixedDecimalScale={true}
                                              decimalScale={2}
                                              className="float-right"
                                          />
                                      </div>
                                    </MenuItem>
                                  )): false}
                                </Select>
                              </FormControl>
                            </Grid>

                            <Grid item xs={6}>
                              <FormGroup controlId="name" bssize="large" style={{marginBottom: "00px"}}>
                                  <TextField
                                  autoFocus
                                  type="text"
                                  value={number}
                                  label="Número"
                                  className={"col-12"}
                                  onChange={e => setNumber(e.target.value)}
                                  disabled={!type.length > 0}
                                  />
                              </FormGroup>
                            </Grid>

                            <Grid item xs={6}>
                              <FormGroup controlId="name" bssize="large" style={{marginBottom: "0px"}}>
                                  <TextField
                                  autoFocus
                                  type="number"
                                  value={value}
                                  label="Valor"
                                  className={"col-12"}
                                  onChange={e => setValue(e.target.value)}
                                  disabled={!type.length > 0}
                                  />
                              </FormGroup>
                            </Grid>

                            <Grid item xs={12}>
                              <FormGroup controlId="name" bssize="large" style={{marginBottom: "25px"}}>
                                  <TextField
                                  autoFocus
                                  type="text"
                                  value={history}
                                  label="Historico"
                                  className={"col-12"}
                                  onChange={e => setHistory(e.target.value)}
                                  disabled={!type.length > 0}
                                  />
                              </FormGroup>
                            </Grid>

                            <Grid item xs={12}>
                              <span>
                              <MDBBtn outline color={color} onClick={handleClickOpen} title="Escolha..." disabled={!type.length > 0 || type === "T"} >
                                <strong> Categoria </strong>
                              </MDBBtn>
                                <span className="ml-2"><MDBIcon icon="angle-double-right" /> {categories.length > 1 ? 'Multiplas Categorias' : categories.length === 1 ? categories[0].name : ''}</span>
                              </span>
                            </Grid>

                        </Grid>
                    </Col>
                  </Row>
                </Card.Body>
            </Card>

            <Dialog
              open={open}
              TransitionComponent={Transition}
              keepMounted
              onClose={handleClickClose}
              aria-labelledby="alert-dialog-slide-title"
              aria-describedby="alert-dialog-slide-description"
            >
              <DialogTitle id="alert-dialog-slide-title">{"Escolha a Categoria"}</DialogTitle>
              <DialogContent>

                  <Form onSubmit={handleSubmit2} method="get" name="mainForm">
                    <Container>
                      <Row>
                        <Col>
                        {type === 'D' ?
                          <div className="mb-2 text-danger">
                            <MDBIcon icon="plane-arrival" size="lg"/> <strong>Despesas</strong>
                          </div>
                        :
                          <div className="mb-2 text-primary">
                            <MDBIcon icon="plane-departure" size="lg"/> <strong>Receitas</strong>
                          </div>
                        }
                          <div className="clt">
                            { Parse(categoriesString) }
                          </div>
                        </Col>
                      </Row>
                    </Container>
                  </Form>

              </DialogContent>
              <DialogActions>
                <MDBBtn outline color="danger" onClick={handleClickClose} className="float-left" title="Cancelar">
                  <strong>Cancelar</strong>
                </MDBBtn>
                <MDBBtn outline color="success" onClick={handleSubmit2} className="float-right" title="Escolher  ">
                  <strong>Escolher</strong>
                </MDBBtn>
              </DialogActions>
            </Dialog>
          </Modal.Body>
          <Modal.Footer>
            <MDBBtn color="danger" onClick={handleClose} className="float-right" title="Cancelar">
              <MDBIcon icon="window-close" size="2x"/>
            </MDBBtn>
            <MDBBtn color="success" disabled={!validateForm()} onClick={handleSubmit} className="float-right" title="Salvar" >
              {isLoading ?
                <div className="spinner-border fast" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
              : <MDBIcon icon="save" size="2x"/> }
            </MDBBtn>
          </Modal.Footer>

      </Modal>
     </ >
  );

  }

  export default RecordAdd

  */