import React, { Component } from 'react';

import { Container, Row , Col } from "react-bootstrap";

import { NotificationPeople } from '../../containers/Notification';
import { optCategoriesPeople } from '../../lib/funApp/general'

import { MDBIcon } from 'mdbreact';
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import { ExpandMore, EmojiPeople }from '@material-ui/icons';
import { Form as FormUI, Label } from 'semantic-ui-react';

import PeopleStatus from './peopleStatus'
import PeopleDelete from './peopleDelete'
import PeopleEdit from './peopleEdit'
import PeopleDial from './peopleDial'

export default class PeoplesRenderGet extends Component {

  constructor(peoples){
    super()
    this.peopleData = peoples.peoples[0]
    this.peopleLinks = peoples.peoples[1]
    this.propsCat = []
    this.status = []
    this.state ={
      expanded: sessionStorage.getItem('@accordPeoples') ? sessionStorage.getItem('@accordPeoples') : false,
      open: false,
      hidden: false,
    }
  }

  handleVisibility = () => {
    this.setState({ hidden: prevHidden => !prevHidden});
  };

  handleOpen = () => {
    this.setState((prevState) => ({ open: true }));
  };

  handleChange(panel){
    if(this.state.expanded !== panel){
      this.setState({expanded :  panel })
      sessionStorage.setItem('@accordPeoples', panel)
    }else{
      this.setState({expanded :  false })
      sessionStorage.removeItem('@accordPeoples')
    }
  };

  catConcat = (category) => {
    let newValue = [ ...this.propsCat, { key: category, text: category, value: category }]
    this.propsCat = newValue
  }
  
  render(){
    return (
      <>
      <Container>
        <Row className="TitleContainer">
          <Col style={{ padding: "10px"}}>
            <h4><MDBIcon icon="users" className="mr-2 ml-4" />Pessoas</h4>
          </Col>
          <Col>
            <PeopleDial/>
          </Col>
        </Row>
      </Container>
      <Container className="MainContainer">
        <NotificationPeople/> 
        <Row>
          <Col>
            {this.peopleData.map((people, k1) => (
              <div className="w-100" key={k1}>
                {this.propsCat = []}
                  <Accordion expanded={this.state.expanded === `panel${k1}`} onChange={e=> this.handleChange(`panel${k1}`)} className='ExpansionPainelData'>
                    <AccordionSummary
                      expandIcon={<ExpandMore />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                    >
                      <Container>
                        <Row>
                          <Col xs={12} md={6}>
                            {people.status === true ? 
                              <MDBIcon icon="user-check" size="lg" style={{ color:"green" }}/> 
                            : <MDBIcon icon="user-alt-slash" size="lg"  style={{ color:"red" }}/>
                            }
                            <span style={{ marginLeft: "10px" }}>{people.name}</span>
                          </Col>
                          <Col xs={12} md={6}>
                            {people.type === 'J' ? 
                              'CNPJ: ' + ( people.cnpj ? people.cnpj : '00.000.000/0000-00' )
                              :'CPF: ' + ( people.cpf ? people.cpf : '000.000.000-00' )
                            }
                          </Col>
                        </Row>
                      </Container>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Container>
                        <Row className="mb-1" style={{ lineHeight: "30px"}}>                
                            <Col xs={12} sm={12} md={6} large={6} xl={6}>
                              <FormUI.Field inline>
                                <Label tag pointing='right' style={{ marginLeft: '0px' }} color={'blue'}>Categorias</Label>
                                <span className="Rotulo">
                                  {people.category.map((category, i) => (
                                    <span key={i}>
                                      <EmojiPeople className="ml-1 ml-2" style={{ color:'#2185d0'}}/>{category} 
                                      {this.catConcat(category)}
                                    </span>
                                  ))}
                                </span>
                              </FormUI.Field>
                            </Col>
                        </Row>    
                        <Row className="mb-1" style={{ lineHeight: "30px"}}>     
                            <Col xs={12} sm={12} md={6} large={6} xl={6}>
                                <strong><span className="Rotulo">Cidade: </span></strong><span className="Rotulo">{people.city}</span>
                            </Col>
                            <Col xs={12} sm={12} md={6} large={6} xl={6}>
                                <strong><span className="Rotulo">Email: </span></strong><span className="Rotulo">{people.email}</span>
                            </Col>
                        </Row>
                        <Row className="mb-1" style={{ lineHeight: "30px"}}>     
                            <Col xs={12} sm={12} md={6} large={6} xl={6}>
                                <strong><span className="Rotulo">Celular: </span></strong><span className="Rotulo">{people.cel}</span>
                            </Col>
                            <Col xs={12} sm={12} md={6} large={6} xl={6}>
                                <strong><span className="Rotulo">Telefone: </span></strong><span className="Rotulo">{people.fone}</span>
                            </Col>
                        </Row>
                        <Row className="mb-1" style={{ lineHeight: "30px"}}>     
                            <Col xs={12} sm={12} md={6} large={6} xl={6}>
                                <strong><span className="Rotulo">Obs: </span></strong><span className="Rotulo">{people.obs}</span>
                            </Col>
                            <Col xs={12} sm={12} md={6} large={6} xl={6}>
                                <strong><span className="Rotulo">Usuário: </span></strong><span className="Rotulo">{people.user}</span>
                            </Col>
                        </Row>
                        <Row>
                          <Col style={{ height: "25px !important", marginTop: "5px" }}>
                            <strong style={{ fontSize: "16px", fontWeight: "600", verticalAlign: "super" }}><span className="Rotulo">Ativo: </span></strong>
                            <PeopleStatus people={people} />                            
                          </Col>
                          <Col className="text-right">
                            <PeopleEdit people={people} category={[...optCategoriesPeople, ...this.propsCat]}/>
                            <span className='m-2'></span>
                            <PeopleDelete people={people}/>
                          </Col>
                        </Row>
                      </Container>
                    </AccordionDetails>
                </Accordion>
              </div>
            ))}
          </Col>
        </Row>
      </Container>
      </>
    )
  }
}

