import React , { Component } from 'react';
import ReactDOM from 'react-dom';
import { Route, Switch, Redirect, BrowserRouter as Router } from 'react-router-dom'
import * as serviceWorker from './serviceWorker';

import BodybuildingplugActive from './components/bodybuildingplugs/bodybuildingplugsActive';
import Users from './app/users';
import WorkoutUsers from './app/workoutUsers';
import Exercises from './app/exercises';
import Peoples from './app/peoples'
import WorkoutsActive from './app/workoutsActive'
import Accounts from './app/accounts'
import Categories from './app/categories'
import UserActive from './components/users/userActive'

import Financial from './components/financial/financial'
import Statement from "./components/financial/bankStatement";

import Home from './containers/home'
import Login from './containers/login'
import Logout from './containers/logout'
//import NavbarPage from './containers/menu'
import SwipeableTemporaryDrawer from './containers/drawerMenu'

import { token } from './lib/funApp/general'

import 'semantic-ui-css/semantic.min.css';
import './index.css';
import '@fortawesome/fontawesome-free/css/all.min.css';
import 'bootstrap-css-only/css/bootstrap.min.css';
import 'mdbreact/dist/css/mdb.css';
import 'react-toastify/dist/ReactToastify.min.css';
import Particles from 'react-particles-js';

class ProtectedRoute extends Component {
  render() {
    const { exact, path, component: Component, ...props } = this.props

    return (
      <Route
        {...props}
        render={props => (
          token ?
          <div id="stage">
            <Particles
              style={{position: "absolute"}}
              canvasClassName="exampleCanvas"
              params={{
                particles: {
                  number: {
                    value: 60,
                    density: {
                      enable: true,
                      value_area: 300,
                    }
                  },
                  color: {
                    value: "#ffffff"
                  },

                  line_linked: {
                    enable: true,
                    distance: 90,
                    color: "#35bce4",
                    width: 1
                  },
                },
                interactivity: {
                  events: {
                      onhover: {
                          enable: true,
                          mode: "repulse"
                      },
                      onclick: {
                        enable: true,
                      },
                  }
                },


              }}
            />
            <SwipeableTemporaryDrawer />
            <Component {...props}/>
          </div>:
            <Redirect to='/' />
        )}
      />
    )
  }
}

const routing = (
    <Router>
      <Switch>
          <Route exact path="/" exactly component={Login}/>

          <ProtectedRoute path="/home" component={Home}  key="emptyK2"/>
          <ProtectedRoute path="/logout" component={Logout}  key="emptyK3"/>

          <ProtectedRoute path="/users" component={Users}  key="emptyK4"/>
          <ProtectedRoute path="/training" component={WorkoutsActive}  key="emptyK5"/>
          <ProtectedRoute path="/workouts" component={WorkoutUsers}  key="emptyK6"/>
          <ProtectedRoute path="/exercises" component={Exercises} key="emptyK7y"/>
          <ProtectedRoute path="/peoples" component={Peoples} key="emptyK8y"/>
          <ProtectedRoute path="/active" component={BodybuildingplugActive} key="emptyK9y"/>
          <ProtectedRoute path="/accounts" component={Accounts} key="emptyK10y"/>
          <ProtectedRoute path="/categories" component={Categories} key="emptyK11y"/>
          <ProtectedRoute path="/financial" component={Financial} key="emptyK12y"/>
          <ProtectedRoute path="/user" component={UserActive} key="emptyK13y"/>
          <ProtectedRoute path="/statement" component={Statement} key="emptyK14y"/>

      </Switch>
    </Router>
  )

  ReactDOM.render(routing, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
