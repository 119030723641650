import {reactLocalStorage} from 'reactjs-localstorage';
import moment from 'moment'
import 'moment/locale/pt-br';

/* Funcoes comuns entre as paginas */

// Server
const serverUrl = process.env.NODE_ENV === 'development' ? 'http://localhost:5000' : 'https://mtt-app.com.br/api'

// Trata itens do usuario
let UserId = ''
let UserData = ''
let UserName = ''
const jsonUserData = sessionStorage.getItem("@user")
const token = sessionStorage.getItem("@token")
const corporation = sessionStorage.getItem("@corp")

if(jsonUserData){
   UserData = JSON.parse(jsonUserData)
   UserName = UserData.name
   UserId = UserData.id
}

// Paginas
const pageSrc = reactLocalStorage.get('@pagSrc') ? reactLocalStorage.get('@pagSrc') : ''

// Headers
let myHeaders = new Headers()
   myHeaders.append("Content-Type", "application/json")
   myHeaders.append("Authorization", "Bearer "+ token)
   myHeaders.append("corporation", corporation)

let headerRequest = {
   'Authorization': 'Bearer '+ token,
   'Content-Type': 'application/json',
   'corporation': corporation
}


// Request Info
const RequestInfoGet = {
   method: 'GET',
   headers: myHeaders
 }
const RequestInfoPost = {
    method: 'POST',
    headers: myHeaders
 }

 function sortByKey(array, key) {
   return array.sort(function(a, b) {
       var x = a[key]; var y = b[key];
       return ((x < y) ? -1 : ((x > y) ? 1 : 0));
   });
}

 function categoriesFilter (props, value){
   if(props.categories !== undefined){
      let categories = []
      props.categories.map((category, index) => (
         category.type === value ?
            categories.push({
               id: category._id,
               name: category.name,
               parent: category.parent !== null ? category.parent : undefined,
               child: []
            })
            : false
      ))
      let deleteCatArray = []
      categories.map((category, index) => (
            categories.map((category1, index) => (
               category.id === category1.parent ?
                  delete(category1.parent)+
                  category.child.push(category1) +
                  deleteCatArray.push(category1.id)
               :false
            ))
      ))

      deleteCatArray.map((item, index)=>(
         categories = categories.filter(function( obj ) {
            return obj.id !== item;
         })
      ))
      return categories
   }
 }

 const optCategoriesPeople = [
   { key: 'Aluno', text: 'Aluno', value: 'Aluno' },
   { key: 'Cliente', text: 'Cliente', value: 'Cliente' },
   { key: 'Empregador', text: 'Empregador', value: 'Empregador' },
   { key: 'Financeira', text: 'Financeira', value: 'Financeira' },
   { key: 'Fornecedor', text: 'Fornecedor', value: 'Fornecedor' },
   { key: 'Serviços', text: 'Serviços', value: 'Serviços' },
   { key: 'Outros', text: 'Outros', value: 'Outros' },
 ]

 const optProfileUser = [
   { key: 'admin', text: 'Administrador', value: 'admin' },
   { key: 'student', text: 'Aluno', value: 'student' },
   { key: 'user', text: 'Usuario', value: 'user' },
   { key: 'trainer', text: 'Instrutor', value: 'trainer' },
 ]

 const optTypeAccount = [
   { key: 'Carteira', text: 'Carteira', value: 'Carteira' },
   { key: 'Cartão Alimentação', text: 'Cartão Alimentação', value: 'Cartão Alimentação' },
   { key: 'Cartão Crédito', text: 'Cartão Crédito', value: 'Cartão Crédito' },
   { key: 'Cartão Refeição', text: 'Cartão Refeição', value: 'Cartão Refeição' },
   { key: 'Conta Corrente', text: 'Conta Corrente', value: 'Conta Corrente' },
   { key: 'Conta Poupança', text: 'Conta Poupança', value: 'Conta Poupança' },
   { key: 'Cofre', text: 'Cofre', value: 'Cofre' },
 ]

 const optMuscleGroup = [
   { key: 'Abdomem', text: 'Abdomem', value: 'Abdomem' },
   { key: 'Aeróbico', text: 'Aeróbico', value: 'Aeróbico' },
   { key: 'Alongamento', text: 'Alongamento', value: 'Alongamento' },
   { key: 'Aquecimento', text: 'Aquecimento', value: 'Aquecimento' },
   { key: 'Antebraço', text: 'Antebraço', value: 'Antebraço' },
   { key: 'Bíceps', text: 'Bíceps', value: 'Bíceps' },
   { key: 'Costas', text: 'Costas', value: 'Costas' },
   { key: 'Glúteos', text: 'Glúteos', value: 'Glúteos' },
   { key: 'Ombro', text: 'Ombro', value: 'Ombro' },
   { key: 'Peito', text: 'Peito', value: 'Peito' },
   { key: 'Pernas', text: 'Pernas', value: 'Pernas' },
   { key: 'Tríceps', text: 'Tríceps', value: 'Tríceps' },
   { key: 'Trapézio', text: 'Trapézio', value: 'Trapézio' },
 ]

 function lastMounths(num){

   let newArray =  []

    for(let i=0; i<num; i++){

      newArray.push({
         labelReduce: firstLetterUpcase(moment().locale('pt-br').subtract(i, "month").format('MMM')),
         label: firstLetterUpcase(moment().locale('pt-br').subtract(i, "month").format('MMMM')),
         number: parseInt(moment().locale('pt-br').subtract(i, "month").format('M')),
         year: parseInt(moment().locale('pt-br').subtract(i, "month").format('YYYY')),
      })
    }

   return newArray
 }

 function LastDaysMonths(num){
   const months = []
   const dateStart = moment().subtract(num, 'month')
   const dateEnd = moment()
   while (dateEnd.diff(dateStart, 'months') >= 0) {
    months.push(dateStart.startOf('month').format('YYYY-MM-DD'))
    dateStart.add(1, 'month')
   }
   return months
  }

 function firstLetterUpcase(str){

   const newStr = str.charAt(0).toUpperCase() + str.slice(1)

   return newStr;
 }

 const noStatistic = ["5dc0b7b8292aa77df1f16ae3","5e6bbcbbc0edd565242038d3","5e680c0bc0edd5652420384d"]

 export {
      RequestInfoGet,
      RequestInfoPost,
      token,
      UserName,
      UserId,
      pageSrc,
      serverUrl,
      headerRequest,
      categoriesFilter,
      sortByKey,
      corporation,
      optCategoriesPeople,
      optProfileUser,
      optTypeAccount,
      optMuscleGroup,
      lastMounths,
      firstLetterUpcase,
      noStatistic,
      LastDaysMonths,
   }
