import React, {Component} from 'react'
import axios from 'axios';  

import { reactLocalStorage } from 'reactjs-localstorage';
import { toast } from 'react-toastify';

import { serverUrl , headerRequest, optMuscleGroup } from '../../lib/funApp/general'

import { Form, Card, FormGroup, Modal } from "react-bootstrap";
import { Button, Dropdown, Form as FormUI, Label, Icon } from 'semantic-ui-react';
import { Grid, TextField } from '@material-ui/core';
import { AddToPhotos } from '@material-ui/icons';

import Loading from  '../../containers/loading';

export default class ExerciseDial extends Component{

    constructor(){
        super()      
        this.targetUrl = serverUrl + '/src/exercises'
        this.targetUrlSrc = serverUrl + '/src/exercises?name='
        this.optError1 = false
        this.optError2 = false
        this.optMuscleGroup = optMuscleGroup  
        this.state ={
            show1: false, 
            show2: false, 
            open: false, hidden: false,
            optError1: false, optError2: false,
            loading: false,
            openDialog: false,
            name: "",
            muscleGroup: "",
            keySrc: '',
            getResult: []
        }
    }

    handleOpen = () => this.setState({ open: !this.state.open });
    handleShowAdd = () => {
      this.setState({ show1: !this.state.show1 }); 
      sessionStorage.setItem('@md_Exercise', !this.state.show1)
    };

    /* Form */
    handleChange = (e, { value }) => this.setState({ muscleGroup: value })

    validateSubmit = () => {
        if( this.state.name.length <= 3 ){
  
          this.optError1 = true; 
          this.setState({ optError1: true });
  
          if( this.state.name.length > 0 ){
            toast.error('Nome deve ter mais de 3 Letras!!');
          }else{
            toast.error('Preencha o Nome!!');
          }
        }else{this.optError1 = false; this.setState({ optError1: false })}

        if( this.state.muscleGroup.length < 1 ){
  
            this.optError2 = true; 
            this.setState({ optError2: true });
            toast.error('Informe o Grupo Muscular!!');            

          }else{this.optError2 = false; this.setState({ optError2: false })}

        if( this.optError1 === false && this.optError2 === false ){
            
            this.setState({ show1: false, loading: true });
            this.handleSubmit();
        }
    }

    handleSubmit = (e) => {
        
        let params = {};
  
        params.name = this.state.name;
        params.muscleGroup = this.state.muscleGroup;

        axios.post(this.targetUrl, params, {"headers" : headerRequest})
          .then(res => {
            if(res.status === 200){
              reactLocalStorage.set('@result', 200); 
              setTimeout(() => {
                return window.location.reload(false);
              }, 2000);
            }
          }).catch(error => {
            if(error.response.data.message.includes('dup key: {')){
              this.setState({ show1: true, loading: false })
              toast.error('Exercício já Cadastrado!!');
            }else{
              this.setState({ show1: true, loading: false })
              toast.error('Erro Inesperado!!');
              console.log('ERROR: ', error.response.status + ' [' + error.response.data.message +']');
            }
        });
    }

    render(){
        return(
            <div>
              <div>
              <Button 
                positive
                onClick={this.handleShowAdd}>
                <AddToPhotos style={{marginRight: "5px"}}/>
                Exercício
              </Button>
            </div>
            <div>
                <Modal show={this.state.show1} onHide={this.handleShowAdd} centered>  
                    <Modal.Header closeButton>
                        <Modal.Title><AddToPhotos style={{ fontSize: 30, marginRight: '5px' }}/>Cadastro de Exercicios</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                      <div className="d-none">
                      </div>
                      <Form>
                        <Card>
                          <Card.Body>
                            <Grid container spacing={3}>   
                              <Grid item xs={12}>
                                <FormGroup controlId="name" bssize="large">
                                  <TextField
                                    error={this.state.optError1}
                                    id="nameText"
                                    type="text"
                                    value={this.state.name}
                                    label="Nome *"
                                    onChange={e => this.setState({ name: e.target.value})}
                                    className="col-12"
                                  />
                                </FormGroup>
                              </Grid>
                              <Grid item xs={12} >
                                <FormUI.Field inline>
                                  <Label pointing='below' basic color={this.state.optError2 ? 'red' : 'blue'}>Grupo Muscular</Label>
                                  <Dropdown
                                    error={this.state.optError2}
                                    options={this.optMuscleGroup}
                                    search
                                    fluid
                                    selection
                                    value={this.state.muscleGroup}
                                    onChange={this.handleChange}
                                  />
                                </FormUI.Field>
                              </Grid>
                            </Grid>
                          </Card.Body>
                        </Card>
                      </Form>
                    </Modal.Body>
                    <Modal.Footer>
                      <Button.Group>
                        <Button negative onClick={this.handleShowAdd}>
                          <Icon name='remove' /> Cancelar
                        </Button>
                        <Button.Or text='ou' />
                        <Button positive onClick={this.validateSubmit}>
                          <Icon name='checkmark' /> Salvar
                        </Button>
                      </Button.Group>
                    </Modal.Footer>
                </Modal>
            </div>
            {this.state.loading ? 
              <Loading main={true} />
            : false}
          </div>
        )
    }
}
