import React, {Component } from 'react'
import axios from 'axios';

import { reactLocalStorage } from 'reactjs-localstorage';
import { toast } from 'react-toastify';

import { serverUrl , headerRequest, optProfileUser } from '../../lib/funApp/general';

import { MDBIcon } from 'mdbreact';
import { Modal, Card } from "react-bootstrap";
import { Button, Icon } from 'semantic-ui-react';
import { Fab } from '@material-ui/core';
import { SyncProblem, LockOpen } from '@material-ui/icons';

import Loading from '../../containers/loading';
export default class UserResetPass extends Component{

  constructor(props){
    super()
    this.user = props.user
    this.password  =  { password: "123456"}
    this.targetUrl = serverUrl + '/src/users/' + props.user._id
    this.state ={
      show: false,
      loading: false
    }
  }
  
  handleShow = () => this.setState({ show: !this.state.show });
  
  handleSubmit = () => {

    this.setState({ show: false, loading: true })

    axios.patch(this.targetUrl, this.password, {"headers" : headerRequest})
      .then(res => {
        if(res.status === 200){
          reactLocalStorage.set('@result', 203); 
          setTimeout(() => {
            return window.location.reload(false);
          }, 2000);
        }
      }).catch(error => {
          this.setState({ show: true, loading: false })
          toast.error('Erro Inesperado!!');
          console.log('ERROR: ', error.response.status + ' [' + error.response.data.message +']');
        }
      );
  }

  profileComp(profiles){

    function srcProfile(value) {
      let teste = ''
      optProfileUser.map((option, i)=>(
        option.value === value ? teste = option.text : false
      ))
      return teste
    }
    return(
      profiles.map((profile, i) =>(
        <span key={i}>
          <LockOpen style={{ color:'#2185d0' }} className="ml-1 mr-1"/> 
          <span>{srcProfile(profile)}</span>
        </span>
    )
   ))
  }

  render(){
    return(
      <>
      <Fab color="primary" size="small" onClick={this.handleShow} title="Resetar Senha" aria-label="add">
        <SyncProblem />
      </Fab>
      <Modal show={this.state.show} onHide={this.handleShow} centered>
        <Modal.Header closeButton>
          <Modal.Title><SyncProblem fontSize="large" className="mr-2" />Deseja Resetar a Senha?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Card>
            <Card.Header>
              <MDBIcon icon="angle-double-right" /><strong className="ml-2">Senha padrão: </strong>123456
            </Card.Header>
            <Card.Body>
              <p><strong>Nome: </strong> {this.user.name}</p>
              <p><strong>Email: </strong> {this.user.email}</p>
              <p><strong>Perfil: </strong> {this.profileComp(this.user.profiles)}</p>
            </Card.Body> 
          </Card>
        </Modal.Body>
        <Modal.Footer>
          <Button.Group>
            <Button negative onClick={this.handleShow}>
              <Icon name='remove' />Cancelar
            </Button>
            <Button.Or text='ou' />
            <Button positive basic={this.state.loading} loading={this.state.loading} onClick={this.handleSubmit} >
              <Icon name='checkmark' /> Resetar
            </Button>
          </Button.Group>
        </Modal.Footer>
      </Modal>
      { this.state.loading ? 
        <Loading />
      : false }
     </ > 
    )
  }
}