import React, {Component } from 'react'
import axios from 'axios';
import DateFnsUtils from '@date-io/date-fns';
import ptLocale from "date-fns/locale/pt-BR";
import moment from 'moment';

import { reactLocalStorage } from 'reactjs-localstorage';
import { toast } from 'react-toastify';

import { serverUrl , headerRequest } from '../../lib/funApp/general';

import { MDBIcon, MDBBtn } from 'mdbreact';
import { Modal,} from "react-bootstrap";
import { TextField } from '@material-ui/core';
import { Button, Card, Icon, Label } from 'semantic-ui-react';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

import Loading from '../../containers/loading';

export default class BodybuildingplugAdd extends Component{

  constructor(props){
    super()
    this.workout = props.workout
    this.targetUrl = serverUrl + '/src/bodybuildingplugs'
    this.targetUrl1 = serverUrl + '/src/workouts/' + props.workout._id
    this.userID = sessionStorage.getItem('@workoutUser') ? JSON.parse(sessionStorage.getItem('@workoutUser')) : ''
    this.optError1 = false
    this.optError2 = false
    this.state ={
      show: false,
      loading: false,
      optError1: false, optError2: false,
      selectedDate: moment(props.workout.dateStart),
      letter: 'A',
    }
  };

  handleShow = () => this.setState({ show: !this.state.show });

  validateSubmit = () => {
    if( this.state.letter.length < 1 ){

      this.optError1 = true;
      this.setState({ optError1: true })
      toast.error('Preencha a Letra!!');

    }else{this.optError1 = false; this.setState({ optError1: false })}

    if(!moment(this.state.selectedDate, 'MM/DD/YYYY',true).isValid()){

      this.optError2 = true;
      this.setState({ optError2: true })
      toast.error('Data Inválida!!');

    }else{this.optError2 = false; this.setState({ optError2: false })}

    if( this.optError1 === false && this.optError2 === false ){
      this.setState({ show: false, loading: true })
      this.handleSubmit()
    }

  }

  handleSubmit = () => {

    this.setState({ show: false, loading: true })

    let params = {}
    let params1 = {}


    params.user = this.userID
    params.dateStart = moment(this.state.selectedDate).format('YYYY-MM-DD') + 'T12:59-0500'
    params.letter = this.state.letter.toUpperCase()
    params1.bodyBuildingPlugs = this.workout.bodyBuildingPlugs

    axios.post(this.targetUrl, params, {"headers" : headerRequest})
      .then(res => {
        if(res.status === 200){
          params1.bodyBuildingPlugs.push(res.data._id)
          axios.patch(this.targetUrl1, params1, {"headers" : headerRequest})
          .then(res1 => {
            if(res1.status === 200){
              reactLocalStorage.set('@result', 202);
              setTimeout(() => {
                return window.location.reload(false);
              }, 2000);
            }
          }).catch(error => {
            this.setState({ show: true, loading: false })
            toast.error('Erro Inesperado!!');
            console.log('ERROR: ', error.response.status + ' [' + error.response.data.message +']');
          });
        }
      }).catch(error => {
        this.setState({ show: true, loading: false })
        toast.error('Erro Inesperado!!');
        console.log('ERROR: ', error.response.status + ' [' + error.response.data.message +']');
      });
  }

  render(){
    return(
      <>
      <MDBBtn outline color="success" onClick={this.handleShow} title="Cadastrar Ficha">
        <MDBIcon icon="notes-medical" size="lg"/>
      </MDBBtn>
      <Modal size="sm" show={this.state.show} onHide={this.handleShow} centered style={{backgroundColor: 'transparent !important' }}>
        <Card style={{ width: '100%' }}>
          <div className='m-3 text-center'>
            <h5><MDBIcon icon="notes-medical" className="mr-2" />Cadastrar Ficha</h5>
          </div>
          <Card.Content>
            <Card.Header className='m-2'>
              <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ptLocale} id='dateForm'>
                <KeyboardDatePicker
                  error={this.state.optError2}
                  allowKeyboardControl
                  disablePast={true}
                  invalidDateMessage=''
                  autoOk
                  variant="inline"
                  inputVariant="outlined"
                  InputAdornmentProps={{ position: "start" }}
                  id="date-picker-dialog"
                  label="Data de Inicio"
                  format="dd/MM/yyyy"
                  value={this.state.selectedDate}
                  onChange={date => this.setState({ selectedDate: date })}
                  KeyboardButtonProps={{
                      'aria-label': 'Alterar Data',
                  }}
                />
              </MuiPickersUtilsProvider>
            </Card.Header>
            <Card.Description className='m-4 d-flex'>
              <Label as='a' basic color='blue' pointing='right'>Letra: </Label>
              <TextField
                error={this.state.optError1}
                autoFocus
                type="text"
                value={this.state.letter}
                onChange={e => this.setState({ letter: e.target.value})}
                className="ml-3"
                />
            </Card.Description>
          </Card.Content>
          <Card.Content extra className='text-center'>
            <Button.Group>
              <Button negative onClick={this.handleShow}>
                <Icon name='remove' />Cancelar
              </Button>
              <Button.Or text='ou' />
              <Button positive basic={this.state.loading} loading={this.state.loading} onClick={this.validateSubmit} >
                <Icon name='checkmark' />Cadastrar
              </Button>
            </Button.Group>
          </Card.Content>
        </Card>
      </Modal>
      { this.state.loading ?
        <Loading />
      : false }
     </ >
    )
  }
}