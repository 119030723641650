import {Component} from 'react';

class LogoutPage extends Component {

  render() { 
    sessionStorage.removeItem('@user')
    sessionStorage.removeItem('@token')
    sessionStorage.removeItem('@corp')
    sessionStorage.removeItem('@acc')
    return window.location.reload(false)
  }

}

export default LogoutPage;