import React, { Component } from "react";
import moment from 'moment'
import axios from 'axios';

import { serverUrl , headerRequest} from '../../lib/funApp/general'

import { MDBIcon } from 'mdbreact';
import { Row, Col, Modal, Card } from 'react-bootstrap';
import { Button, Message, Icon, Popup } from 'semantic-ui-react'
import { Grid } from "@material-ui/core";

import CurrencyFormat from 'react-currency-format';
import { reactLocalStorage } from 'reactjs-localstorage';
import { toast } from 'react-toastify';

import Loading from '../../containers/loading';

export default class RecordDelete extends Component{

  constructor(props){
    super()
    this.categories = []
    this.state = {
        show: false,
        loading: false,
    }
  }

  componentDidMount() {
    // Categories
    const targetUrlCategory = serverUrl + '/src/categories'
    axios.get(targetUrlCategory, {"headers" : headerRequest})
        .then(res => {
            if(res.status === 200){
                this.categories = res.data.items
            }
        }).catch(error => {
            console.log(error.response)
    });
  }

  handleSubmit = () => {
    this.setState({loading: true});

    let targetUrl = serverUrl + '/src/records/'+ this.props.item._id
    let counterpart;
    let targetUrlT;

    if(this.props.item.type === 'T' && this.props.item.counterpart !== undefined){
      counterpart = this.props.item.counterpart._id
      targetUrlT  = serverUrl + '/src/records/'+ this.props.item.counterpart._id
    }

    axios.delete(targetUrl, {"headers" : headerRequest})
      .then(res => {
        console.log('res.status',res.status)
        if(res.status === 204){

          if(counterpart){
            axios.delete(targetUrlT, {"headers" : headerRequest})
              .then(res => {
                console.log('res.status',res.status)
                if(res.status === 204){
                  reactLocalStorage.set('@result', 204);
                  window.location.reload(true);
                }

              }).catch(error => {
                this.setState({ show: true, loading: false })
                toast.error('Erro Inesperado!!');
                console.log('ERROR: ', error.response.status + ' [' + error.response.data.message +']');
                }
              );

          }else{
            reactLocalStorage.set('@result', 204);
            window.location.reload(true);
          }
        }
      }).catch(error => {
        this.setState({ show: true, loading: false })
        toast.error('Erro Inesperado!!');
        console.log('ERROR: ', error.response.status + ' [' + error.response.data.message +']');
        }
      );
  }

  handleShowSave = () => {this.setState( prevState => { return{ show: !prevState.show }})};

  getCategory = (id) => {
    const found = this.categories.find(element => element._id === id);
    if(found !== undefined){
        return found.name
    }
  }

  render(){
    return(
        <>
          <Button.Group>
            <Popup content='Editar o Lançamento' style={{color: 'orange'}} trigger={
              <Button color='orange'><MDBIcon icon="pencil-alt" title='Editar'/></Button>
            } />
            <Button.Or text='ou' />
            <Popup content='Apagar o Lançamento' style={{color: 'red'}} trigger={
              <Button color='red' onClick={this.handleShowSave}><MDBIcon icon="trash-alt"  title='Apagar'/></Button>
            } />
          </Button.Group>
          <Modal show={this.state.show} onHide={e=>this.handleShowSave()} centered>
            <Modal.Header closeButton>
              <Modal.Title className='red-text'><MDBIcon icon="trash-alt" className="mr-2" />
                Excluir Registro
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Row>
                <Col><div id="return" className="w-100 font-weight-bold text-wrap"></div></Col>
              </Row>
              <Row className="justify-content-center">
                <Col>
                  <Message icon style={{marginBottom: '0px'}}>
                    <Icon name='cog' loading className='red-text' />
                    <Message.Content>
                      <Message.Header>Deseja Excluir o Registro?</Message.Header>
                    </Message.Content>
                  </Message>
                  <Card>
                    <Card.Body>
                      <Grid
                        container
                        spacing={3}
                        direction="row"
                        justify="center"
                        alignItems="center"
                      >
                        <Grid item xs={12} md={6}>
                          <strong>Data:</strong> {moment(this.props.item.date).format('DD/MM/YYYY')}
                        </Grid>
                        <Grid item xs={12} md={6}>
                          <strong>Valor:</strong>
                          <CurrencyFormat
                            value={this.props.item.value}
                            displayType={'text'}
                            thousandSeparator="."
                            decimalSeparator=","
                            fixedDecimalScale={true}
                            decimalScale={2}
                            className="ml-3 float-right"
                            prefix="R$ "
                          />
                        </Grid>
                      </Grid>
                      {this.props.item.person !== undefined ?
                        <Grid item xs={12} className='mt-3'>
                            <strong>Pessoa:</strong> { this.props.item.person.name }
                        </Grid>
                      : false
                      }
                      {this.props.item.history !== undefined ?
                        <Grid item xs={12} className='mt-3'>
                            <strong>Histórico:</strong> {this.props.item.history}
                        </Grid>
                      : false
                      }
                      <Grid item md={12} className='mt-3'>
                          <strong>Conta
                            {this.props.item.value < 0 ? ' (Saída): ':' (Entrada): '}
                          </strong> {this.props.item.account.name}
                      </Grid>
                      {this.props.item.type === 'T' ?
                        this.props.item.counterpart !== undefined ?
                        <Grid item md={12} className='mt-3'>
                          <strong>Contra Partida
                            {this.props.item.counterpart.value < 0 ? ' (Saída): ':'  (Entrada):'}
                          </strong> {this.props.item.counterpart.account.name}
                        </Grid>
                        : false :
                        <Grid item md={12} className='mt-3'>
                            <strong>Categoria: </strong>
                            {this.props.item.category.length > 1 ? 'Diversas' : this.props.item.category[0].category !== undefined ? this.getCategory(this.props.item.category[0].category) : ''}
                        </Grid>
                      }
                    </Card.Body>
                  </Card>
                </Col>
              </Row>
              </Modal.Body>
              <Modal.Footer>
                <Row className="justify-content-md-center">
                  <Col>
                    <Button.Group>
                      <Button negative onClick={this.handleShowSave} >
                        <strong style={{fontWeight: '700'}} >Cancelar</strong>
                      </Button>
                      <Button.Or text='ou' size='large'/>
                      <Button positive onClick={this.handleSubmit}>
                        <strong style={{fontWeight: '700'}} >Excluir</strong>
                      </Button>
                    </Button.Group>
                  </Col>
                </Row>
              </Modal.Footer>
            </Modal>
            { this.state.loading ?
                <Loading />
            : false }
          </>
    )}
}