import React, {Component } from 'react'
import axios from 'axios';
import Moment from 'react-moment';

import { reactLocalStorage } from 'reactjs-localstorage';
import { toast } from 'react-toastify';

import { serverUrl , headerRequest } from '../../lib/funApp/general';

import { Modal,} from "react-bootstrap";
import { MDBBtn, MDBIcon } from 'mdbreact';
import { Button, Card, Icon, Label } from 'semantic-ui-react';

import { DeleteForever }from '@material-ui/icons';

import Loading from '../../containers/loading';

export default class BodyBuildingPlugDelete extends Component{

  constructor(props){
    super()
    this.bodyBuildingPlug = props.bodyBuildingPlug
    this.targetUrl = serverUrl + '/src/bodybuildingplugs/' + props.bodyBuildingPlug._id
    this.state ={
      show: false,
      loading: false
    }
    this.muscleBody = []
  };

  handleShow = () => this.setState({ show: !this.state.show });

  handleSubmit = () => {

    this.setState({ show: false, loading: true })

    axios.delete(this.targetUrl, {"headers" : headerRequest})
      .then(res => {
        if(res.status === 204){
          reactLocalStorage.set('@result', 205);
          setTimeout(() => {
            return window.location.reload(false);
          }, 2000);
        }
      }).catch(error => {
          this.setState({ show: true, loading: false })
          toast.error('Erro Inesperado!!');
          console.log('ERROR: ', error.response.status + ' [' + error.response.data.message +']');
        }
      );
  }

  concatMuscleBody(muscle, k2){
    this.muscleBody.push(muscle)
  }

  filterMuscle(arr){
    let arrNew = arr.filter((este, i) => arr.indexOf(este) === i).sort();
    return arrNew
  }

  render(){
    return(
      <>
      <MDBBtn outline color="danger" onClick={this.handleShow} title="Deletar Ficha" aria-label="add">
        <DeleteForever />
      </MDBBtn>
      <Modal size="sm" show={this.state.show} onHide={this.handleShow} centered style={{backgroundColor: 'transparent !important' }}>
        <Card style={{ width: '100%' }}>
          <div className='m-3 text-center text-danger'>
            <h5><DeleteForever fontSize="large" className="mr-2" />Deseja Excluir a Ficha?</h5>
          </div>
          <Card.Content>
          <Card.Meta className='m-2 text-center'>
              <span className='date'>
                <strong>Data de Inicio: </strong>
                <Moment format="DD/MM/YYYY" style={{marginLeft: "5px"}}>
                  <span className="Rotulo">{this.bodyBuildingPlug.dateStart}</span>
                </Moment>
              </span>
            </Card.Meta>
            <Card.Description className='m-4 text-center'>
              <Label as='a' basic color='blue' pointing='right'>Letra: </Label>
                <span className='ml-2' style={{ fontSize: 'mediun' }}>{this.bodyBuildingPlug.letter}</span>
                <div className="text-center mt-4" style={{borderBottom: "1px solid rgba(0,0,0,.125)", backgroundColor: "#d8d8d838" }}>
                  {this.bodyBuildingPlug ?
                      this.bodyBuildingPlug.training.map((training, index) => (
                        this.concatMuscleBody(training.exercise.muscleGroup)
                      ))
                  :false}
                  {this.muscleBody ?
                    <strong>
                      <MDBIcon icon="heartbeat"  className="red-text"  size="lg" />
                      {this.filterMuscle(this.muscleBody).map((muscle, index)=>(
                        ' ' +  muscle)) + "" }
                    </strong>
                  :false }
                </div>
            </Card.Description>
          </Card.Content>
          <Card.Content extra className='text-center'>
            <Button.Group>
              <Button negative onClick={this.handleShow}>
                <Icon name='remove' />Cancelar
              </Button>
              <Button.Or text='ou' />
              <Button positive basic={this.state.loading} loading={this.state.loading} onClick={this.handleSubmit} >
                <Icon name='checkmark' />Excluir
              </Button>
            </Button.Group>
          </Card.Content>
        </Card>
      </Modal>
      { this.state.loading ?
        <Loading />
      : false }
     </ >
    )
  }
}