import React, { Component } from 'react';
import WorkoutsRenderActive from '../components/workout/workoutActive'
import { UserId, RequestInfoGet, serverUrl } from '../lib/funApp/general'

let targetUrl = serverUrl + '/src/workouts?user='+UserId

class WorkoutsGetActive extends Component {

  state = {
    workouts: [],
    links: {}
  }

  componentDidMount() {
    fetch(targetUrl ,RequestInfoGet)
    .then(res => res.json())
    .then((data) => {
      this.setState({ links: data._links })
      this.setState({ workouts: data.items })
    })
    .catch()
  }

  render() { 
    return (
      this.state.workouts[0] !== undefined ?
        <WorkoutsRenderActive workouts={[this.state.workouts,this.state.links]} />
      : false
    )
  } 

}

export default WorkoutsGetActive;