import React, {Component} from 'react'
import axios from 'axios';

import { toast } from 'react-toastify';
import { reactLocalStorage } from 'reactjs-localstorage';

import { UserId, serverUrl , headerRequest } from '../../lib/funApp/general';
import { avatar } from '../../lib/img/img';

import { MDBIcon } from 'mdbreact';
import { Container, Row, Col, Card, FormGroup } from "react-bootstrap";
import { TextField, Input, InputLabel, InputAdornment, FormControl } from '@material-ui/core';
import { Container as ContainerSUI, Button, Item, Icon } from 'semantic-ui-react';
import { NotificationUser } from '../../containers/Notification';

export default class UsersActive extends Component {

  constructor(){
    super()
    this.targetUrl = serverUrl + '/src/users/' + UserId
    this.avatarMaleList = this.dataOrganize(avatar.small.male)
    this.avatarFemaleList = this.dataOrganize(avatar.small.female)
    this.state ={
          user: {},
          name: "",
          email: "",
          pass1:"",
          pass2:"",
          seePass1: true,
          seePass2: true,
          gender: "",
          avatar: "",
    }
    this.dataAccounts =
        axios.get(this.targetUrl, {"headers" : headerRequest})
        .then(res => {
        if(res.status === 200){
            this.setState({ 
              user: res.data,
              name: res.data.name,  
              email: res.data.email,
              gender: res.data.gender,
              avatar: res.data.avatar
            })
        }
        }).catch(error => {
          toast.error('Erro Inesperado!!');
          console.log('ERROR: ', error.response.status + ' [' + error.response.data.message +']');
        });
  }

  dataOrganize = (data) => {
    let newData = []
    data.map((data, index) => (
      newData.push({
          key: index,
          value: index,
          image: { avatar: true, src: data },
      })
    ))
    return newData
  }

  validateForm(){
    let passVerify = true

    if(this.state.pass1.length > 0 || this.state.pass2.length > 0){
      if(this.state.pass1 !== this.state.pass2){
          passVerify = false
      }
    }
    
    return this.state.name.length > 3  && this.state.email.length > 0 && passVerify;
  }

  handleSubmit() {
    let params = {}

    if(this.state.name){ params.name = this.state.name }
    if(this.state.email){ params.email = this.state.email }
    if(this.state.pass1.length > 0){ params.password = this.state.pass1 }

    axios.patch(this.targetUrl, params, {"headers" : headerRequest})
      .then(res => {
        if(res.status === 200){
          reactLocalStorage.set('@result', 201); 
          window.location.reload(false)
        }
      }).catch(error => {
        if(error.response.data.message.includes('dup key: {')){
          this.setState({ show1: true, loading: false })
          toast.error('Usuário já Cadastrado!!');
        }else{
          this.setState({ show1: true, loading: false })
          toast.error('Erro Inesperado!!');
          console.log('ERROR: ', error.response.status + ' [' + error.response.data.message +']');
        }
      });
  }

  render(){
    return(
      <>
      <Container>
        <Row className="TitleContainer">
          <Col style={{ padding: "10px"}}>
            <h4><MDBIcon icon="users-cog" className="mr-2 ml-4" />Dados do Usuário</h4>
          </Col>
        </Row> 
      </Container>
      <Container className="MainContainer">
        <NotificationUser />       
        {this.state.user !== {} ? 
        <Row>
          <Col>
            <div id="return" className="w-100 font-weight-bold text-wrap"></div>
              <Card className="m-2" style={{ backgroundColor: '#ffffffe8' }}>
                <Card.Body>

                    <ContainerSUI>
                      <Item.Group divided>
                        <Item>
                          <Item.Image src={ this.state.gender === 'F' ? avatar.large.female[this.state.avatar] : avatar.large.male[this.state.avatar ? this.state.avatar : 0]} />
                          <Item.Content>
                            <Item.Meta>
                              <FormGroup controlId="name" bssize="large" style={{marginBottom: "25px", marginTop: "15px"}}>
                                <TextField
                                    autoFocus
                                    type="text"
                                    value={this.state.name}
                                    label="Nome"
                                    onChange={e => this.setState({ name: e.target.value})}
                                    className="col-12"
                                />
                              </FormGroup>
                              <FormGroup controlId="email" bssize="large" style={{marginBottom: "25px"}}>                  
                                <TextField
                                    autoFocus
                                    type="text"
                                    value={this.state.email}
                                    label="Email"
                                    onChange={e => this.setState({ email: e.target.value})}
                                    className="col-12"
                                  />
                              </FormGroup>
                            </Item.Meta>
                            <Item.Description>
                              <Row>
                                <Col>
                                  <FormControl style={{marginBottom: "25px"}} className='col-12'>   
                                    <InputLabel htmlFor="input-with-icon-adornment1">Nova Senha</InputLabel>
                                    <Input
                                      id="input-with-icon-adornment1"
                                      type={this.state.seePass1 ? "text" : "password" }
                                      value={this.state.pass1}
                                      onChange={e => this.setState({ pass1: e.target.value})}
                                      endAdornment={
                                        <InputAdornment position="end" onClick={e => this.setState({ seePass1: !this.state.seePass1})}>
                                          <MDBIcon far icon={this.state.seePass1 ? "eye" : "eye-slash" }/>
                                        </InputAdornment>
                                      }
                                    />
                                  </FormControl>
                                </Col>
                                <Col>
                                  <FormControl style={{marginBottom: "25px"}} className='col-12'>   
                                    <InputLabel htmlFor="input-with-icon-adornment2">Confirmação</InputLabel>
                                    <Input
                                      id="input-with-icon-adornment2"
                                      type={this.state.seePass2 ? "text" : "password" }
                                      value={this.state.pass2}
                                      onChange={e => this.setState({ pass2: e.target.value})}
                                      endAdornment={
                                        <InputAdornment position="end" onClick={e => this.setState({ seePass2: !this.state.seePass2})}>
                                          <MDBIcon far icon={this.state.seePass2 ? "eye" : "eye-slash" }/>
                                        </InputAdornment>
                                      }
                                    />
                                  </FormControl>
                                </Col>
                              </Row>
                            </Item.Description>
                            <Item.Extra>
                              <Button positive disabled={!this.validateForm()} onClick={e => this.handleSubmit()} className="float-right" title="Salvar">
                              <Icon name='checkmark' /> Salvar
                              </Button>
                            </Item.Extra>
                          </Item.Content>
                        </Item>
                        </Item.Group>
                        </ContainerSUI>

                    </Card.Body>
                </Card>
            </Col>
        </Row>
        :false }
      </Container>
      </>
    )
  }
}