import React, {Component} from 'react'

import { UserName, optProfileUser } from '../../lib/funApp/general'
import { avatar } from '../../lib/img/img';

import { Container, Row , Col, Media } from "react-bootstrap";
import { MDBIcon } from 'mdbreact';
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import { ExpandMore as ExpandMoreIcon, LockOpen }from '@material-ui/icons';
import { Image } from 'semantic-ui-react';

import { NotificationUser } from '../../containers/Notification';
import UserDelete from './userDelete'
import UserEdit from './userEdit'
import UserResetPass from './userResetPass'
import UserStatus from "../users/userStatus"
import UserDial from './userDial';

class UsersRenderGet extends Component {

  constructor(users){
    super()
    this.usersData = users.users[0]
    this.usersLinks = users.users[1]
    this.state ={
      expanded: sessionStorage.getItem('@accordUsers') ? sessionStorage.getItem('@accordUsers') : false
    }
  }

  handleChange (panel){
    if(this.state.expanded !== panel){
      this.setState({expanded :  panel })
      sessionStorage.setItem('@accordUsers', panel)
    }else{
      this.setState({expanded :  false })
      sessionStorage.removeItem('@accordUsers')
    }
  };

  profileComp(profiles){

    function srcProfile(value) {
      let strProfile = ''
      optProfileUser.map((option, i)=>(
        option.value === value ? strProfile = option.text : false
      ))
      return strProfile
    }
    return(
      profiles.map((profile, i) =>(
        <span key={i}>
          <LockOpen style={{ color:'#2185d0' }} className="ml-1 mr-1"/> 
          <span>{srcProfile(profile)}</span>
        </span>
    )
   ))
  }

  render(){
    return(
      <>
      <Container>
        <Row className="TitleContainer">
          <Col style={{ padding: "10px"}}>
            <h4><MDBIcon icon="users-cog" className="mr-2 ml-4" />Usuários</h4>
          </Col>
          <Col>
              <UserDial />
          </Col>
        </Row> 
      </Container>
      <Container className="MainContainer">
        <NotificationUser /> 
        <Row>
          <Col>
          {this.usersData.map((user, k1) => (
            user.email !== "admin@email.com" ?
            <Accordion expanded={this.state.expanded === `panel${k1}`} onChange={e=> this.handleChange(`panel${k1}`)} className='ExpansionPainelData' key={k1}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1bh-content"
                id="panel1bh-header"
                style={{backgroundColor: "#d3d3d37a" }}
              >
                <Container>
                  <Row>
                    <Col>
                    {user.status === true ? 
                      <MDBIcon icon="user-check" size="lg" style={{ color:"green" }}/> 
                    : <MDBIcon icon="user-alt-slash" size="lg" color="red" style={{ color:"red" }}/>
                    }
                    <span style={{ marginLeft: "10px" }}>{user.name}</span>
                    </Col>
                  </Row>
                </Container>
              </AccordionSummary>
              <AccordionDetails style={{ padding: '0px'}}>
                <Container style={{ padding: '0px'}}>
                <ul className="list-unstyled">
                  <Media as="li">
                    <Image className="align-self-center mr-3" 
                      src={ user.gender === 'F' ? avatar.small.female[user.avatar] : avatar.small.male[user.avatar ? user.avatar : 0]}/>
                    <Media.Body>
                    <Row className="mb-2" style={{ lineHeight: "50px"}}>
                    <Col xs={11} sm={12} md={6} large={6} xl={6}>
                      <strong><span className="Rotulo">Email: </span></strong><span className="Rotulo">{user.email}</span>
                    </Col>
                    <Col xs={12} sm={12} md={6} large={6} xl={6}>
                      <span className="Rotulo"><strong>Perfil: </strong></span>
                      <span className="Rotulo">{this.profileComp(user.profiles)} </span>
                    </Col>
                  </Row>
                  {user.name !==  UserName ?
                  <Row className="mb-2" style={{ lineHeight: "30px"}}>                    
                    <Col style={{ height: "25px !important", marginTop: "5px" }}>
                      <strong style={{ fontSize: "16px", fontWeight: "600", verticalAlign: "super" }}><span className="Rotulo">Ativo: </span></strong>
                      <UserStatus user={user} />                                   
                    </Col>
                    <Col></Col>
                    <Col xs={12} md={4} className="text-right">
                      {user.status === true ?
                        <>
                          <UserResetPass user={user}/>
                          <span className='m-2'></span>
                          <UserEdit user={user} />
                          <span className='m-2'></span>
                        </>
                        :false}
                          <UserDelete user={user} />
                      </Col>
                    </Row> 
                  : false}
                    </Media.Body>
                  </Media>
                  </ul>
                </Container>
              </AccordionDetails>
            </Accordion>
            :false
            ))}
          </Col>
        </Row>  
      </Container>
      </>
    )
  }
}

export default UsersRenderGet